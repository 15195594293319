import { useEffect } from "react";
import { Avatar, Box } from "@mui/material";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { DataGridTabel } from "../DataGridTabel/DataGridTabel";
import { ColumnText } from "../DataGridTabel/ColumnText";
import { ColumnButton } from "../DataGridTabel/ColumnButton";
import { HeaderGridTabel } from "../DataGridTabel/HeaderGridTabel";
import HomeWorkIcon from "@mui/icons-material/HomeWork";
import ModalInfo from "./Modal/ModalInfo";
import {
  dataClickItem,
  methodRequestStatus,
  openFilterState,
  updateDataState,
} from "../../recoil/global";
import { openModalDelete, openModalInfo } from "../../recoil/modal";
import ModalDelete from "./Modal/ModalDelete";
import { useState } from "react";
import { dataTabelState } from "../../recoil/Tabel";
import Filters from "./Filters";
import {
  selectedStatusState,
  selectedCategoryState,
} from "../../recoil/select";
import { GetSubCategoryData } from "../../services/api/subCategory";

export const Tabel = () => {
  const [data, setData] = useRecoilState(dataTabelState);
  const [createdAfter, setCreatedAfter] = useState(null);
  const [createdBefor, setCreatedBefor] = useState(null);

  const selectedStatus = useRecoilValue(selectedStatusState);
  const state = useRecoilValue(openFilterState);
  const updateData = useRecoilValue(updateDataState);
  const selectedCategory = useRecoilValue(selectedCategoryState);
  const filterOpen = useRecoilValue(openFilterState);

  const setDataItem = useSetRecoilState(dataClickItem);
  const setOpenModal = useSetRecoilState(openModalInfo);
  const setOpenDelete = useSetRecoilState(openModalDelete);
  const setMethodRequest = useSetRecoilState(methodRequestStatus);

  useEffect(() => {
    setDataItem(null);
  }, [state]);

  useEffect(() => {
    console.log("selectedCategory", createdBefor);
  }, [createdBefor]);

  const handelEdit = (data) => {
    setOpenModal(true);
    setDataItem(data);
    setMethodRequest("edit");
  };

  const handelDelete = (data) => {
    setOpenDelete(true);
    setDataItem(data);
    setMethodRequest("delete");
  };

  const columns = [
    {
      field: "name",
      headerName: "Name",
      headerClassName: "grid--header",
      flex: 1,
      renderCell: (params) => {
        return (
          <Box
            display={"flex"}
            alignItems={"center"}
            sx={{
              overflowX: "auto",
              scrollbarWidth: "none",
            }}
            gap={2}
          >
            <Avatar
              sx={{
                borderRadius: 0,
              }}
              sizes="small"
              alt="Image"
              src={params.row?.image_file}
            />
            <ColumnText
              text={params.row?.name ? params.row?.name : "N/A"}
              variant="subtitle1"
              fontWeight="bold"
            />
            {/* <ColumnText text={params.row?.job_title?.name_ar} name_ar="caption" color={"gray"} /> */}
          </Box>
        );
      },
    },
    {
      field: "name_ar",
      headerName: "Name AR",
      headerClassName: "grid--header",
      flex: 1,
      renderCell: (params) => {
        return (
          <Box
            display={"flex"}
            alignItems={"center"}
            sx={{
              overflowX: "auto",
              scrollbarWidth: "none",
            }}
            gap={2}
          >
            <ColumnText
              text={params.row?.name ? params.row?.name_ar : "N/A"}
              variant="subtitle1"
              fontWeight="bold"
            />
          </Box>
        );
      },
    },
    {
      field: "sub_category_slider_items",
      headerName: "Slider",
      headerClassName: "grid--header",
      flex: 1,
      renderCell: (params) => {
        return (
          <Box
            display={"flex"}
            alignItems={"center"}
            sx={{
              overflowX: "auto",
              scrollbarWidth: "none",
            }}
            gap={2}
          >
            <ColumnText
              text={
                params.row?.sub_category_slider_items?.length > 0
                  ? params.row?.sub_category_slider_items?.length
                  : "N/A"
              }
              variant="subtitle1"
              fontWeight="bold"
            />
          </Box>
        );
      },
    },
    {
      field: "sub_categories",
      headerName: "Sub Categories",
      headerClassName: "grid--header",
      flex: 1,
      renderCell: (params) => {
        return (
          <Box
            display={"flex"}
            alignItems={"center"}
            sx={{
              overflowX: "auto",
              scrollbarWidth: "none",
            }}
            gap={2}
          >
            <ColumnText
              text={params.row?.category ? params.row?.category : "N/A"}
              variant="subtitle1"
              fontWeight="bold"
            />
          </Box>
        );
      },
    },
    {
      field: "operations",
      headerName: "Operations",
      flex: 1,
      headerClassName: "grid--header",
      renderCell: (params) => {
        return (
          <Box
            display={"flex"}
            alignItems={"center"}
            sx={{
              overflowX: "auto",
              scrollbarWidth: "none",
            }}
          >
            <ColumnButton
              onClick={() => handelEdit(params.row)}
              type={"edit"}
            />
             <Box
              sx={{
                width: "1px",
                height: "20px",
                backgroundColor: "#f0f0f0",
                margin: "0 10px",
              }}
            ></Box>
            <ColumnButton
              onClick={() => handelDelete(params.row)}
              type={"delete"}
            />
          </Box>
        );
      },
    },
  ];

  const fetchData = () => {
    setData((old) => ({
      ...old,
      loading: true,
      data: [],
    }));
    GetSubCategoryData(
      data.filters.skip,
      data.filters.take,
      data.filters.search,
      selectedCategory,
      createdAfter,
      createdBefor
    )
      .then((res) => {
        console.log("Dtest", res);
        setData((old) => ({
          ...old,
          data: res.results,
          total: res?.count,
          loading: false,
        }));
      })
      .catch(() => {
        setData({ ...data, loading: false });
      });
  };

  let filters = data.filters.page || data.filters.take || data.filters.skip;

  useEffect(() => {
    fetchData();
  }, [updateData, createdAfter, createdBefor]);

  useEffect(() => {
    filterOpen.right && fetchData();
  }, [filters, selectedCategory]);

  return (
    <>
      <Box>
        <HeaderGridTabel
          title="Sub Categores"
          icon={
            <HomeWorkIcon
              size={25}
              className="color-primary"
              color={"#08001e"}
            />
          }
          btn={"Add Sub Category"}
          fetchData={fetchData}
          isFilter={true}
        />
        <Box mt={2}>
          <DataGridTabel
            columns={columns}
            data={data}
            setData={setData}
            padding={true}
          />
        </Box>
        {/* Modal */}
        <ModalInfo />
        <ModalDelete />
        {/* Fillter */}
        <Filters
          createdAfter={createdAfter}
          setCreatedAfter={setCreatedAfter}
          createdBefor={createdBefor}
          setCreatedBefor={setCreatedBefor}
          fetchData={fetchData}
        />
      </Box>
    </>
  );
};

export default Tabel;
