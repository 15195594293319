import { useEffect, useRef } from "react";
import { Avatar, Box } from "@mui/material";
import {
  useRecoilState,
  useRecoilValue,
  useResetRecoilState,
  useSetRecoilState,
} from "recoil";
import { DataGridTabel } from "../DataGridTabel/DataGridTabel";
import { ColumnText } from "../DataGridTabel/ColumnText";
import { ColumnButton } from "../DataGridTabel/ColumnButton";
import { HeaderGridTabel } from "../DataGridTabel/HeaderGridTabel";
import HomeWorkIcon from "@mui/icons-material/HomeWork";
import ModalInfo from "./Modal/ModalInfo";
import {
  dataClickItem,
  methodRequestStatus,
  openFilterState,
  updateDataState,
} from "../../recoil/global";
import {
  confirmModalState,
  openModalDelete,
  openModalInfo,
} from "../../recoil/modal";
import ModalDelete from "./Modal/ModalDelete";
import { useState } from "react";
import { dataTabelState } from "../../recoil/Tabel";
import Filters from "./Filters";
import {
  selectedStatusState,
  selectedNotificationLevelState,
} from "../../recoil/select";
import { GetNotificationsData } from "../../services/api/notifications";
import { max, min } from "moment";
import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment";


import * as XLSX from "xlsx";
import jsPDF from "jspdf";
import "jspdf-autotable";
import copy from "clipboard-copy";
import { useReactToPrint } from "react-to-print";
import { toast } from "react-toastify";

export const Tabel = () => {
  const [data, setData] = useRecoilState(dataTabelState);
  const [createdAfter, setCreatedAfter] = useState(null);
  const [createdBefor, setCreatedBefor] = useState(null);

  const selectedStatus = useRecoilValue(selectedStatusState);
  const state = useRecoilValue(openFilterState);
  const updateData = useRecoilValue(updateDataState);
  const notificationLevel = useRecoilValue(selectedNotificationLevelState);

  const resetConfirm = useResetRecoilState(confirmModalState);

  const setDataItem = useSetRecoilState(dataClickItem);
  const setOpenModal = useSetRecoilState(openModalInfo);
  const setOpenDelete = useSetRecoilState(openModalDelete);
  const setMethodRequest = useSetRecoilState(methodRequestStatus);

  const navigate = useNavigate();
  const contentRef = useRef();

  useEffect(() => {
    setDataItem(null);
  }, [state]);

  const handelEdit = (data) => {
    setOpenModal(true);
    setDataItem(data);
    setMethodRequest("remove-footer");
  };

  const handelDelete = (data) => {
    setOpenDelete(true);
    setDataItem(data);
    setMethodRequest("delete");
  };

  const handlePrint = useReactToPrint({
    contentRef,
  });

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const columns = [
    {
      field: "id",
      headerName: "id",
      headerClassName: "grid--header",
      flex: 1,
      maxWidth: 50,
      renderCell: (params) => {
        return (
          <Box
            display={"flex"}
            alignItems={"center"}
            width={"100%"}
            sx={{
              overflowX: "auto",
              scrollbarWidth: "none",
              cursor: "pointer",
              height: "40px",
            }}
            onClick={() =>
              navigate(`/OrderDetailes?id=${params?.row?.related_object?.id}`)
            }
            gap={2}
          >
            <ColumnText
              text={
                params.row?.related_object?.id
                  ? params.row?.related_object?.id
                  : "N/A"
              }
              variant="subtitle1"
              fontWeight="bold"
            />
            {/* <ColumnText text={params.row?.job_title?.name_ar} name_ar="caption" color={"gray"} /> */}
          </Box>
        );
      },
    },
    {
      field: "Customer Name",
      headerName: "Customer Name",
      headerClassName: "grid--header",
      flex: 1,
      minWidth: 150,

      renderCell: (params) => {
        return (
          <Box
            display={"flex"}
            alignItems={"center"}
            sx={{
              overflowX: "auto",
              scrollbarWidth: "none",
              cursor: "pointer",
              height: "40px",
              width: "100%",
            }}
            onClick={() =>
              navigate(`/OrderDetailes?id=${params?.row?.related_object?.id}`)
            }
            gap={2}
          >
            <ColumnText
              text={
                params.row?.related_object?.created_by?.username
                  ? params.row?.related_object?.created_by?.username
                  : "N/A"
              }
              variant="subtitle1"
              fontWeight="bold"
            />
          </Box>
        );
      },
    },
    {
      field: "Email",
      headerName: "Email",
      headerClassName: "grid--header",
      flex: 1,
      minWidth: 300,

      renderCell: (params) => {
        return (
          <Box
            display={"flex"}
            alignItems={"center"}
            sx={{
              overflowX: "auto",
              scrollbarWidth: "none",
              cursor: "pointer",
              height: "40px",
              width: "100%",
            }}
            onClick={() =>
              navigate(`/OrderDetailes?id=${params?.row?.related_object?.id}`)
            }
            gap={2}
          >
            <ColumnText
              text={
                params.row?.related_object?.created_by?.email
                  ? params.row?.related_object?.created_by?.email
                  : "N/A"
              }
              variant="subtitle1"
              fontWeight="bold"
            />
          </Box>
        );
      },
    },
    {
      field: "Status",
      headerName: "Status",
      headerClassName: "grid--header",
      flex: 1,
      renderCell: (params) => {
        return (
          <Box
            display={"flex"}
            alignItems={"center"}
            sx={{
              overflowX: "auto",
              scrollbarWidth: "none",
              cursor: "pointer",
              height: "40px",
              width: "100%",
            }}
            onClick={() =>
              navigate(`/OrderDetailes?id=${params?.row?.related_object?.id}`)
            }
            gap={2}
          >
            <ColumnText
              text={
                params.row?.related_object?.status
                  ? params.row?.related_object?.status
                  : "N/A"
              }
              sx={{
                background:
                  params.row?.related_object?.status === "pending"
                    ? "linear-gradient(310deg, #f7971e, #ffd200) !important"
                    : params.row?.related_object?.status === "approved"
                    ? "linear-gradient( 310deg,#17ad37,#98ec2d) !important"
                    : params.row?.related_object?.status === "canceled"
                    ? "linear-gradient(310deg, #ee0979, #ff6a00) !important"
                    : params.row?.related_object?.status === "rejected"
                    ? "linear-gradient(310deg, #c11414 , #fe2712) !important"
                    : "linear-gradient(310deg, #e03228 , #fd5085) !important",
                borderRadius: "999px",
                color:
                  params.row?.related_object?.status === "pending"
                    ? "black"
                    : "white",
                px: 1,
              }}
              variant="subtitle1"
              fontWeight="bold"
            />
          </Box>
        );
      },
    },
    {
      field: "products",
      headerName: "Product",
      headerClassName: "grid--header",
      flex: 1,
      minWidth: 300,
      renderCell: (params) => {
        return (
          <Box
            display={"flex"}
            alignItems={"center"}
            sx={{
              overflowX: "auto",
              scrollbarWidth: "none",
              cursor: "pointer",
              width: "100%",

              height: "40px",
            }}
            gap={2}
            onClick={() => {
              if (params.row?.related_object?.order_lines?.length > 0) {
                navigate(
                  `/Product?id=${params?.row?.related_object?.order_lines[0].product}`
                );
              }
            }}
          >
            <ColumnText
              text={
                params.row?.related_object?.order_lines?.length > 0
                  ? params.row?.related_object?.order_lines[0].product_name
                  : "N/A"
              }
              variant="subtitle1"
              fontWeight="bold"
            />
          </Box>
        );
      },
    },
    {
      field: "Date",
      headerName: "Date",
      headerClassName: "grid--header",
      flex: 1,
      renderCell: (params) => {
        return (
          <Box
            display={"flex"}
            alignItems={"center"}
            sx={{
              overflowX: "auto",
              scrollbarWidth: "none",
              cursor: "pointer",
              height: "40px",
              width: "100%",
            }}
            onClick={() =>
              navigate(`/OrderDetailes?id=${params?.row?.related_object?.id}`)
            }
            gap={2}
          >
            <ColumnText
              text={
                params.row?.created ? moment(params.row?.created).format("MMM D YYYY, h:mm a") : "N/A"
              }
              variant="subtitle1"
              fontWeight="bold"
            />
          </Box>
        );
      },
    },
    {
      field: "total_products",
      headerName: "Quantity",
      headerClassName: "grid--header",
      flex: 1,
      renderCell: (params) => {
        return (
          <Box
            display={"flex"}
            alignItems={"center"}
            sx={{
              overflowX: "auto",
              scrollbarWidth: "none",
              cursor: "pointer",
              height: "40px",
              width: "100%",
            }}
            onClick={() =>
              navigate(`/OrderDetailes?id=${params?.row?.related_object?.id}`)
            }
            gap={2}
          >
            <ColumnText
              text={
                params.row?.related_object?.total_products
                  ? params.row?.related_object?.total_products
                  : "N/A"
              }
              variant="subtitle1"
              fontWeight="bold"
            />
          </Box>
        );
      },
    },

    // {
    //   field: "operations",
    //   headerName: "Operations",
    //   flex: 1,
    //   headerClassName: "grid--header",
    //   renderCell: (params) => {
    //     return (
    //       <Box
    //         display={"flex"}
    //         alignItems={"center"}
    //         sx={{
    //           overflowX: "auto",
    //           scrollbarWidth: "none",
    //         }}
    //         gap={2}
    //       >
    //         <ColumnButton
    //           onClick={() => handelEdit(params.row)}
    //           type={"show"}
    //         />
    //         <ColumnButton
    //           onClick={() => handelDelete(params.row)}
    //           type={"delete"}
    //         />
    //       </Box>
    //     );
    //   },
    // },
  ];

  const fetchData = () => {
    setData((old) => ({
      ...old,
      loading: true,
      data: [],
    }));
    GetNotificationsData(
      data.filters.skip,
      data.filters.take,
      data.filters.search,
      notificationLevel,
      createdAfter,
      createdBefor,
      "orders.Order"
    )
      .then((res) => {
        console.log("Dtest", res);
        setData((old) => ({
          ...old,
          data: res.results,
          total: res?.count,
          loading: false,
        }));
      })
      .catch(() => {
        setData({ ...data, loading: false });
      });
  };

  let filters = data.filters.page || data.filters.take || data.filters.skip;

  useEffect(() => {
    fetchData();
  }, [filters, updateData, notificationLevel, createdBefor, createdAfter]);

  const exportToExcel = () => {
    const newData = data.data.map((item) => {
      return {
        OrderID: item?.related_object_id,
        CustomerName: item?.related_object?.created_by?.username,
        Email: item?.related_object?.created_by?.email,
        Status: item?.related_object?.status,
        Product:
          item?.related_object?.order_lines.length > 0
            ? item?.related_object?.order_lines[0].product_name
            : "N/A",
        Date: item?.created ? item?.created.split("T")[0] : "N/A",
        Quantity: item?.related_object?.total_products,
      };
    });
    const ws = XLSX.utils.json_to_sheet(newData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Data");
    XLSX.writeFile(
      wb,
      `${new Date().toISOString().split("T")[0]}/order-notifications.xlsx`
    );
  };

  const exportToPDF = () => {
    const newData = data.data.map((item) => {
      return {
        OrderID: item?.related_object_id,
        CustomerName: item?.related_object?.created_by?.username,
        Email: item?.related_object?.created_by?.email,
        Status: item?.related_object?.status,
        Product:
          item?.related_object?.order_lines.length > 0
            ? item?.related_object?.order_lines[0].product_name
            : "N/A",
        Date: item?.created ? item?.created.split("T")[0] : "N/A",
        Quantity: item?.related_object?.total_products,
      };
    });

    const doc = new jsPDF();

    // Add title
    doc.text(
      "                       Original Software | Admin Dashboard Template",
      14,
      10
    );

    // Define table data
    const tableData = [
      [
        "OrderID",
        "Customer Name",
        "Email",
        "Status",
        "Product",
        "Date",
        "Quantity",
      ],
      ...newData.map((item) => [
        item.OrderID,
        item.CustomerName,
        item.Email,
        item.Status,
        item.Product,
        item.Date,
        item.Quantity,
      ]),
    ];

    // Generate table
    doc.autoTable({
      head: [tableData[0]], // Table headers
      body: tableData.slice(1), // Table rows
      startY: 20, // Position below title
      styles: {
        fontSize: 10, // Default font size for table
        cellPadding: 0, // Cell padding
        halign: "center", // Text alignment
        valign: "middle", // Vertical alignment
      },
      headStyles: {
        fontSize: 11, // Font size for the header
        minCellHeight: 11, // Custom header row height
        halign: "center", // Center-align header text
        fillColor: [45, 65, 84], // Light gray background color for headers
      },
      columnStyles: {
        0: { cellWidth: 16, minCellHeight: 10 }, // OrderID column width
        1: { cellWidth: 35 }, // Customer Name column width
        2: { cellWidth: 35 }, // Email column width
        3: { cellWidth: 20 }, // Status column width
        4: { cellWidth: 42 }, // Product column width
        5: { cellWidth: 24 }, // Date column width
        6: { cellWidth: 16 }, // Quantity column width
      },
    });

    // Save the PDF
    doc.save(
      `${new Date().toISOString().split("T")[0]}/order-notifications.pdf`
    );
  };

  const copyToClipboard = () => {
    const newData = data.data.map((item) => {
      return {
        OrderID: item?.related_object_id,
        CustomerName: item?.related_object?.created_by?.username,
        Email: item?.related_object?.created_by?.email,
        Status: item?.related_object?.status,
        Product:
          item?.related_object?.order_lines.length > 0
            ? item?.related_object?.order_lines[0].product_name
            : "N/A",
        Date: item?.created ? item?.created.split("T")[0] : "N/A",
        Quantity: item?.related_object?.total_products,
      };
    });
    copy(JSON.stringify(newData));
    toast.success("Data copied to clipboard");
  };

  return (
    <>
      <Box>
        <HeaderGridTabel
          title="Order Notifications"
          icon={
            <HomeWorkIcon
              size={25}
              className="color-primary"
              color={"#08001e"}
            />
          }
          // btn={"Add Notifications"}
          fetchData={fetchData}
          isFilter={true}
        />
        <Box mt={2}>
          <DataGridTabel
            columns={columns}
            data={data}
            setData={setData}
            small={true}
            height={"60vh"}
            exports={true}
            exportToExcel={exportToExcel}
            exportToPDF={exportToPDF}
            print={handlePrint}
            copy={copyToClipboard}
          />
        </Box>
        <div style={{ display: "none" }}>
          <div ref={contentRef}>
            <h2 style={{ textAlign: "center", width: "100%" }}>
              Original Software | Admin Dashboard Template
            </h2>
            <table
              style={{
                borderCollapse: "collapse",
                width: "100%",
                textAlign: "center",
              }}
            >
              <thead>
                <tr style={{ backgroundColor: "#ccc" }}>
                  <th style={{ border: "1px solid #000", padding: "7px" }}>
                    OrderID
                  </th>
                  <th style={{ border: "1px solid #000", padding: "7px" }}>
                    Customer Name
                  </th>
                  <th style={{ border: "1px solid #000", padding: "7px" }}>
                    Email
                  </th>
                  <th style={{ border: "1px solid #000", padding: "7px" }}>
                    Status
                  </th>
                  <th style={{ border: "1px solid #000", padding: "7px" }}>
                    Product
                  </th>
                  <th style={{ border: "1px solid #000", padding: "7px" }}>
                    Date
                  </th>
                  <th style={{ border: "1px solid #000", padding: "7px" }}>
                    Quantity
                  </th>
                </tr>
              </thead>
              <tbody>
                {data?.data?.length > 0 &&
                  data?.data
                    ?.map((item) => {
                      return {
                        OrderID: item?.related_object_id,
                        CustomerName:
                          item?.related_object?.created_by?.username,
                        Email: item?.related_object?.created_by?.email,
                        Status: item?.related_object?.status,
                        Product:
                          item?.related_object?.order_lines?.length > 0
                            ? item?.related_object?.order_lines[0]?.product_name
                            : "N/A",
                        Date: item?.created
                          ? item?.created.split("T")[0]
                          : "N/A",
                        Quantity: item?.related_object?.total_products,
                      };
                    })
                    .map((row) => (
                      <tr key={row.OrderID}>
                        <td
                          style={{
                            border: "1px solid #000",
                            padding: "7px",
                            maxWidth: "50px",
                          }}
                        >
                          {row.OrderID}
                        </td>
                        <td
                          style={{
                            border: "1px solid #000",
                            padding: "7px",
                            maxWidth: "150px",
                          }}
                        >
                          {row?.CustomerName?.match(/.{1,15}/g).map(
                            (item, index) => (
                              <div key={index} title={item}>
                                {item}
                              </div>
                            )
                          )}
                        </td>
                        <td
                          style={{
                            border: "1px solid #000",
                            padding: "7px",
                            maxWidth: "200px",
                          }}
                        >
                          {row?.Email?.match(/.{1,15}/g).map((item, index) => (
                            <div key={index} title={item}>
                              {item}
                            </div>
                          ))}
                        </td>
                        <td
                          style={{
                            border: "1px solid #000",
                            padding: "7px",
                            maxWidth: "100px",
                          }}
                        >
                          {row.Status}
                        </td>
                        <td
                          style={{
                            border: "1px solid #000",
                            padding: "7px",
                            maxWidth: "300px",
                          }}
                        >
                          {row.Product}
                        </td>
                        <td
                          style={{
                            border: "1px solid #000",
                            padding: "7px",
                            maxWidth: "100px",
                          }}
                        >
                          {row.Date}
                        </td>
                        <td
                          style={{
                            border: "1px solid #000",
                            padding: "7px",
                            maxWidth: "100px",
                          }}
                        >
                          {row.Quantity}
                        </td>
                      </tr>
                    ))}
              </tbody>
            </table>
          </div>
        </div>
        {/* Modal */}
        <ModalInfo />
        <ModalDelete />
        {/* Fillter */}
        <Filters
          createdBefor={createdBefor}
          setCreatedAfter={setCreatedAfter}
          createdAfter={createdAfter}
          setCreatedBefor={setCreatedBefor}
          fetchData={fetchData}
        />
      </Box>
    </>
  );
};

export default Tabel;
