import { useEffect } from "react";
import { Avatar, Box, Link } from "@mui/material";
import {
  useRecoilState,
  useRecoilValue,
  useResetRecoilState,
  useSetRecoilState,
} from "recoil";
import { DataGridTabel } from "../DataGridTabel/DataGridTabel";
import { ColumnText } from "../DataGridTabel/ColumnText";
import { ColumnButton } from "../DataGridTabel/ColumnButton";
import { HeaderGridTabel } from "../DataGridTabel/HeaderGridTabel";
import HomeWorkIcon from "@mui/icons-material/HomeWork";
import ModalInfo from "./Modal/ModalInfo";
import {
  dataClickItem,
  locationState,
  methodRequestStatus,
  openFilterState,
  updateDataState,
} from "../../recoil/global";
import {
  confirmModalState,
  openModalDelete,
  openModalInfo,
  openModalShow,
} from "../../recoil/modal";
import ModalDelete from "./Modal/ModalDelete";
import { useState } from "react";
import { dataTabelState } from "../../recoil/Tabel";
import Filters from "./Filters";
import {
  selectedCreatedFromDateState,
  selectedCreatedToDateState,
  selectedIsViewState,
  selectedPaymentMethodState,
  selectedPaymentStatus,
  selectedStatusState,
} from "../../recoil/select";
import { GetOrderData } from "../../services/api/order";
import ModalShow from "./Modal/ModalShow";
import { CheckBox } from "@mui/icons-material";
import moment from "moment";
import { useLocation, useNavigate } from "react-router-dom";
import { color } from "@mui/system";

export const Tabel = () => {
  const [data, setData] = useRecoilState(dataTabelState);
  const [emailSearch, setEmailSearch] = useState(null);
  const [phoneSearch, setPhoneSearch] = useState(null);

  const [selectedStatus, setSelectedStatus] =
    useRecoilState(selectedStatusState);
  const [updateData, setUpdateData] = useRecoilState(updateDataState);
  const state = useRecoilValue(openFilterState);
  const selectedPaymentMethod = useRecoilValue(selectedPaymentMethodState);
  const paymentStatus = useRecoilValue(selectedPaymentStatus);
  const selectedCreatedFromDate = useRecoilValue(selectedCreatedFromDateState);
  const selectedCreatedToDate = useRecoilValue(selectedCreatedToDateState);
  const isView = useRecoilValue(selectedIsViewState);
  const locationValue = useRecoilValue(locationState);

  const setDataItem = useSetRecoilState(dataClickItem);
  const setOpenModal = useSetRecoilState(openModalInfo);
  const setOpenShowModal = useSetRecoilState(openModalShow);
  const setOpenDelete = useSetRecoilState(openModalDelete);
  const setMethodRequest = useSetRecoilState(methodRequestStatus);

  const navigate = useNavigate();
  const location = useLocation();

  function formatDate(inputDate) {
    let formattedDate = `${inputDate.split("T")[0]}`;
    return formattedDate;
  }
  function formatHours(inputDate) {
    let date = new Date(inputDate);
    let hours = date.getHours();
    let minutes = String(date.getMinutes()).padStart(2, "0");
    let period = hours >= 12 ? "PM" : "AM";
    hours = hours % 12;
    hours = hours ? hours : 12;
    let formattedDate = `${hours}:${minutes} ${period}`;
    return formattedDate;
  }

  // replace _ with space and capitalize first letter of each word

  useEffect(() => {
    setDataItem(null);
  }, [state]);
  const handelShow = (data) => {
    setOpenShowModal(true);
    setDataItem(data);
    setMethodRequest("show");
  };
  const handelPaid = (data) => {
    setOpenModal(true);
    setDataItem(data);
    setMethodRequest("paid");
  };
  const handelApproved = (data) => {
    setOpenModal(true);
    setDataItem(data);
    setMethodRequest("approved");
  };
  const handelRejected = (data) => {
    setOpenModal(true);
    setDataItem(data);
    setMethodRequest("rejected");
  };
  const handelReturn = (data) => {
    setOpenModal(true);
    setDataItem(data);
    setMethodRequest("return");
  };
  const handelDelete = (data) => {
    setOpenDelete(true);
    setDataItem(data);
    setMethodRequest("delete");
  };

  const columns = [
    {
      field: "order_number",
      headerName: "Number",
      headerClassName: "grid--header",
      minWidth: 70,
      renderCell: (params) => {
        return (
          <Box
            display={"flex"}
            alignItems={"center"}
            gap={2}
            sx={{
              textDecoration: "none",
              cursor: "pointer",
              overflowX: "auto",
              scrollbarWidth: "none",
              height: "65px ",
              width: "100%",
            }}
            color={"#fff"}
            onClick={() => navigate(`/OrderDetailes?id=${params.row.id}`)}
          >
            <ColumnText
              text={params.row?.order_number ? params.row?.order_number : "N/A"}
              variant="subtitle1"
              sx={{
                fontWeight: "400 !important",
              }}
            />
          </Box>
        );
      },
    },
    {
      field: "id",
      headerName: "Order Name",
      headerClassName: "grid--header",

      minWidth: 340,
      renderCell: (params) => {
        return (
          <Box
            display={"flex"}
            alignItems={"center"}
            gap={2}
            sx={{
              textDecoration: "none",
              cursor: "pointer",
              overflowX: "auto",
              scrollbarWidth: "none",
              height: "65px ",
              width: "100%",
            }}
            color={"#fff"}
            onClick={() => navigate(`/OrderDetailes?id=${params.row.id}`)}
          >
            <Avatar
              sx={{
                borderRadius: 9999999,
              }}
              sizes="small"
              alt="Image"
              src={
                params?.row?.order_lines?.length > 0
                  ? params?.row?.order_lines[0]?.first_product_image
                  : "N/A"
              }
            />

            <ColumnText
              text={
                params?.row?.order_lines?.length > 0
                  ? params.row?.order_lines[0]?.product_name
                    ? params.row?.order_lines[0]?.product_name
                    : "N/A"
                  : "N/A"
              }
              sx={{
                fontWeight: "400 !important",
              }}
              variant="subtitle1"
            />
          </Box>
        );
      },
    },
    {
      field: "email",
      headerName: "Email",
      headerClassName: "grid--header",

      minWidth: 340,
      renderCell: (params) => {
        return (
          <Box
            display={"flex"}
            alignItems={"center"}
            gap={2}
            sx={{
              textDecoration: "none",
              cursor: "pointer",
              overflowX: "auto",
              scrollbarWidth: "none",
              height: "65px ",
              width: "100%",
            }}
            color={"#fff"}
            onClick={() => navigate(`/OrderDetailes?id=${params.row.id}`)}
          >
            <ColumnText
              text={
                params.row?.created_by?.email
                  ? params.row?.created_by?.email
                  : "N/A"
              }
              variant="subtitle1"
              sx={{
                fontWeight: "400 !important",
              }}
            />
          </Box>
        );
      },
    },
    {
      field: "total_products",
      headerName: "Qauntity",
      headerClassName: "grid--header",

      minWidth: 100,
      renderCell: (params) => {
        return (
          <Box
            display={"flex"}
            alignItems={"center"}
            gap={2}
            sx={{
              textDecoration: "none",
              cursor: "pointer",
              overflowX: "auto",
              scrollbarWidth: "none",
              height: "65px ",
              width: "100%",
            }}
            color={"#fff"}
            onClick={() => navigate(`/OrderDetailes?id=${params.row.id}`)}
          >
            <ColumnText
              text={
                params.row?.order_lines?.length > 0
                  ? params.row.order_lines.reduce(
                      (total, line) => total + (line.quantity || 0),
                      0
                    )
                  : "N/A"
              }
              variant="subtitle1"
              sx={{
                fontWeight: "400 !important",
              }}
            />
          </Box>
        );
      },
    },
    {
      field: "payment_method",
      headerName: "Payment Method",
      headerClassName: "grid--header",

      minWidth: 200,
      renderCell: (params) => {
        return (
          <Box
            display={"flex"}
            alignItems={"center"}
            gap={2}
            sx={{
              textDecoration: "none",
              cursor: "pointer",
              overflowX: "auto",
              scrollbarWidth: "none",
              height: "65px ",
              width: "100%",
            }}
            color={"#fff"}
            onClick={() => navigate(`/OrderDetailes?id=${params.row.id}`)}
          >
            <ColumnText
              text={
                params.row?.payment_method
                  ? params.row.payment_method
                      .replace(/_/g, " ")
                      .replace(
                        /\w\S*/g,
                        (txt) =>
                          txt.charAt(0).toUpperCase() +
                          txt.slice(1).toLowerCase()
                      )
                  : "N/A"
              }
              variant="subtitle1"
              sx={{
                fontWeight: "400 !important",
              }}
            />
          </Box>
        );
      },
    },
    {
      field: "status",
      headerName: "Status",
      headerClassName: "grid--header",

      minWidth: 150,
      renderCell: (params) => {
        return (
          <Box
            display={"flex"}
            alignItems={"center"}
            gap={2}
            sx={{
              textDecoration: "none",
              cursor: "pointer",
              overflowX: "auto",
              scrollbarWidth: "none",
              height: "65px ",
              width: "100%",
            }}
            color={"#fff"}
            onClick={() => navigate(`/OrderDetailes?id=${params.row.id}`)}
          >
            <ColumnText
              text={
                params.row?.status
                  ? params.row.status
                      .replace(/_/g, " ")
                      .replace(
                        /\w\S*/g,
                        (txt) =>
                          txt.charAt(0).toUpperCase() +
                          txt.slice(1).toLowerCase()
                      )
                  : "N/A"
              }
              variant="subtitle1"
              sx={{
                fontWeight: "400 !important",
                color:
                  params.row?.status === "pending"
                    ? "#ffc107 !important"
                    : params.row?.status === "approved"
                    ? "#12a27a !important"
                    : params.row?.status === "rejected"
                    ? "red !important"
                    : params.row?.status === "returned"
                    ? "#fc185a !important"
                    : "red !important",
              }}
            />
          </Box>
        );
      },
    },
    {
      field: "created_at",
      headerName: "Date",
      headerClassName: "grid--header",

      minWidth: 250,
      renderCell: (params) => {
        return (
          <Box
            display={"flex"}
            alignItems={"center"}
            gap={2}
            sx={{
              textDecoration: "none",
              cursor: "pointer",
              overflowX: "auto",
              scrollbarWidth: "none",
              height: "65px ",
              width: "100%",
            }}
            color={"#fff"}
            onClick={() => navigate(`/OrderDetailes?id=${params.row.id}`)}
          >
            <ColumnText
              text={
                params.row?.created
                  ? moment(new Date(params.row.created)).format("MMM D YYYY, h:mm a")
                  : "N/A"
              }
              sx={{
                fontWeight: "400 !important",
              }}
              variant="subtitle1"
            />
          </Box>
        );
      },
    },
  ];

  const fetchData = () => {
    setData((old) => ({
      ...old,
      loading: true,
      data: [],
    }));
    GetOrderData(
      data.filters.skip,
      data.filters.take,
      data.filters.search,
      data.filters.order_number,
      selectedStatus,
      selectedPaymentMethod,
      paymentStatus,
      selectedCreatedFromDate,
      selectedCreatedToDate,
      isView
    )
      .then((res) => {
        setData((old) => ({
          ...old,
          data: res.results,
          total: res?.count,
          loading: false,
        }));
      })
      .catch((err) => {
        console.log(err);
        setData({ ...data, data: [], loading: false });
      });
  };

  let filters = data.filters.page || data.filters.take || data.filters.skip;

  useEffect(() => {
    fetchData();
  }, [filters, updateData]);

  return (
    <>
      <Box
        sx={{
          m: 0,
        }}
      >
        <HeaderGridTabel
          title="Orders"
          icon={
            <HomeWorkIcon
              size={25}
              className="color-primary"
              color={"#ffffff"}
            />
          }
          btn={"Add Order"}
          types={[
            {
              name: "all",
              count: 10,
              color: "#0d6efd",
              function: () => {
                setSelectedStatus("");
                setUpdateData(Math.random());
              },
            },
            {
              name: "approved",
              count: 10,
              color: "#27ae60",
              function: () => {
                setSelectedStatus("approved");
                setUpdateData(Math.random());
              },
            },
            {
              name: "pending",
              count: 10,
              color: "#ffff00",
              function: () => {
                setSelectedStatus("pending");
                setUpdateData(Math.random());
              },
            },
            {
              name: "rejected",
              count: 10,
              color: "#ff0000",
              function: () => {
                setSelectedStatus("rejected");
                setUpdateData(Math.random());
              },
            },
            {
              name: "returned",
              count: 10,
              color: "#de1855",
              function: () => {
                setSelectedStatus("returned");
                setUpdateData(Math.random());
              },
            },
          ]}
          fetchData={fetchData}
          isFilter={true}
          data={data?.data}
          // exel={true}
          deleteBulk={true}
          bulkDeleteUrl={"/order/delete_bulk/"}
          deleteToastMessage={"Orders deleted successfully"}
          deleteBtnText={"Delete Bulk Orders"}
        />
        <Box mt={2} sx={{}}>
          <DataGridTabel
            columns={columns}
            data={data}
            setData={setData}
            checkbox={true}
          />
        </Box>
        {/* Modal */}
        <ModalShow />
        <ModalInfo />
        <ModalDelete />
        {/* Fillter */}
        <Filters
          emailSearch={emailSearch}
          setEmailSearch={setEmailSearch}
          phoneSearch={phoneSearch}
          setPhoneSearch={setPhoneSearch}
          fetchData={fetchData}
        />
      </Box>
    </>
  );
};

export default Tabel;
