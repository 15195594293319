import React, { useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Paper from "@mui/material/Paper";
import Draggable from "react-draggable";
import { useSetRecoilState, useRecoilValue } from "recoil";
import { Box, IconButton, Tooltip, Typography } from "@mui/material";
import {
  openModalApplicant,
  openModalDelete,
  openModalInfo,
  openModalShow,
} from "../../recoil/modal";
import { methodRequestStatus } from "../../recoil/global";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import DrawIcon from "@mui/icons-material/Draw";
import DeleteIcon from "@mui/icons-material/Delete";
import CloseIcon from "@mui/icons-material/Close";
import KeyboardReturnIcon from "@mui/icons-material/KeyboardReturn";

function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

export default function GeneralModal(props) {
  const setOpenInfo = useSetRecoilState(openModalInfo);
  const setOpenShow = useSetRecoilState(openModalShow);
  const setOpenDelete = useSetRecoilState(openModalDelete);
  const setOpenApplicant = useSetRecoilState(openModalApplicant);
  const methodRequest = useRecoilValue(methodRequestStatus);

  const onClose = () => {
    try {
      props.onClose();
    } catch (error) {
      console.log(error);
    }
  };

  const handleClose = () => {
    setOpenShow(false);
    setOpenInfo(false);
    setOpenDelete(false);
    setOpenApplicant(false);
    onClose();
  };

  return (
    <div>
      <Dialog
        open={props.open}
        onClose={handleClose}
        PaperComponent={PaperComponent}
        maxWidth={props.maxWidth ? props.maxWidth : "lg"}
        aria-labelledby="draggable-dialog-title"
        sx={
          ({
            width: "100%",
            overflow: "hidden",
            borderRadius: 10,

            h2: {
              backgroundImage: props?.headerBg
                ? props?.headerBg
                : "linear-gradient(310deg, #00c6fb 0%, #005bea 100%) !important",
            },
          },
          props.style)
        }
      >
        <DialogTitle
          className="color-primary"
          id="draggable-dialog-title"
          sx={{
            background: props?.headerBg
              ? props?.headerBg
              : "linear-gradient(310deg, #00c6fb 0%, #005bea 100%) !important",
            display: "flex",
            justifyContent: "space-between",
            "button:hover": {
              background: "#0f1535 !important",
            },
          }}
          style={
            ({
              cursor: "move",
              alignItems: "center",
              borderBottom: "1px solid #bdc3c7",
            },
            props.titleStyle)
          }
        >
          <Typography
            variant="h6"
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              color: "#fff",
              svg: {
                fill: "#fff",
              },
            }}
            fontWeight={600}
          >
            {props.icon ? (
              props.icon
            ) : methodRequest === "add" ? (
              <AddCircleIcon className="color-primary" />
            ) : methodRequest === "edit" ? (
              <DrawIcon className="color-primary" />
            ) : methodRequest === "delete" ? (
              <DeleteIcon className="color-primary" />
            ) : methodRequest === "return" ? (
              <KeyboardReturnIcon className="color-primary" />
            ) : (
              props.icon
            )}
            &nbsp;
            {props.title
              ? props.title
              : methodRequest === "add"
              ? "Add"
              : methodRequest === "edit"
              ? "Edit"
              : methodRequest === "delete"
              ? "Delete"
              : methodRequest === "return"
              ? "Return"
              : props.title}
          </Typography>
          {methodRequest == "remove-footer" && (
            <Tooltip title="Close">
              <IconButton color="warning" onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            </Tooltip>
          )}
        </DialogTitle>
        <Box
          component="form"
          sx={{
            pb: 2,
            background: "#0f1535",
            color: "#fff !important",
            ".MuiInputBase-inputSizeSmall": {
              color: "#fff !important",
            },
            input: {
              color: "#fff !important",
              // "-webkit-text-fill-color": "#fff !important",
            },

            "& .MuiInputBase-root.Mui-disabled": {
              "& input": {
                color: "#fff", // Specific text color for disabled input
                "-webkit-text-fill-color": "#ffffffad !important",
              },
            },
            label: {
              color: "#fff !important",
              background: "transparent",
            },
            fieldset: {
              borderColor: "#ffffff26 !important",
            },

            svg: {
              fill: "#ffffff26",
            },
            h5: {
              color: "#fff !important",
            },
            h2: {
              color: "#fff !important",
            },
            p: {
              color: "#fff !important",
            },
            span: {
              color: "#fff !important",
            },
          }}
          onSubmit={props.onSubmit}
        >
          <DialogContent>
            <DialogContentText>{props.children}</DialogContentText>
          </DialogContent>
          {methodRequest !== "remove-footer" && (
            <DialogActions>
              {!props?.btnFooterHide && !props?.loading && (
                <Button
                  sx={{
                    background:
                      "linear-gradient(310deg, #ee0979, #ff6a00) !important",
                    color: "#fff",
                    py: 1,
                    px: 2,
                  }}
                  onClick={handleClose}
                >
                  <Typography color={"#fff"}>Close</Typography>
                </Button>
              )}
              {!props?.btnFooterHide && !props?.loading && (
                <Button
                  disabled={props.disabled}
                  variant="contained"
                  type="submit"
                  sx={{
                    background:
                      "linear-gradient(310deg, #00c6fb 0%, #005bea 100%) !important",
                    color: "#fff",
                    py: 1,
                    px: 2,
                  }}
                >
                  <Typography color={"#fff"}>
                    {methodRequest === "add"
                      ? "Add"
                      : methodRequest === "edit"
                      ? "Save"
                      : methodRequest === "delete"
                      ? "Delete"
                      : "Done"}
                  </Typography>
                </Button>
              )}
              {!props.btnFooterHide && props?.loading && (
                <Button
                  disabled={props.disabled}
                  variant="contained"
                  type="submit"
                >
                  Loading...
                </Button>
              )}
            </DialogActions>
          )}
        </Box>
      </Dialog>
    </div>
  );
}
