import React, { useState } from "react";
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Typography,
  IconButton,
  Grid,
  TextField,
  Box,
  Link,
} from "@mui/material";
import { IoIosAddCircleOutline } from "react-icons/io";
import { AiOutlineSearch } from "react-icons/ai";

import { useRecoilState, useSetRecoilState } from "recoil";
import { dataTabelState } from "../../recoil/Tabel";
import { openModalInfo } from "../../recoil/modal";
import {
  dataClickItem,
  methodRequestStatus,
  openFilterState,
  selectedDateState,
  selectedDateToState,
  updateDataState,
} from "../../recoil/global";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import ExportButton from "../Others/ExportButton";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";

import { CalendarMonth, Label } from "@mui/icons-material";
import DropDown from "../Others/DropDown";

import tempNotImage from "../../assets/003-office.png";
import CloseIcon from "@mui/icons-material/Close";
import moment from "moment";
import { Avatar, InputBase, Paper } from "@mui/material";
import axios from "axios";
import { selectedTableItemState } from "../../recoil/select";
import { toast } from "react-toastify";
import ModalDelete from "./modal/ModalDelete";
import { useNavigate } from "react-router-dom";

export const HeaderGridTabel = (props) => {
  const [openBulkDelete, setOpenBulkDelete] = useState(false);
  const [bulkUrl, setBulkUrl] = useState(null);
  const [deleteToastMessage, setDeleteToastMessage] = useState(null);
  const [title, setTitle] = useState(null);

  const [dataState, setDataState] = useRecoilState(dataTabelState);
  const [selected, setSelected] = useRecoilState(selectedTableItemState);

  const [selectedDate, setSelectedDate] = useRecoilState(selectedDateState);
  const [selectedDateTo, setSelectedDateTo] =
    useRecoilState(selectedDateToState);

  const [updateData, setUpdateData] = useRecoilState(updateDataState);

  const setOpenAdd = useSetRecoilState(openModalInfo);
  const setOpenDelete = useSetRecoilState(openModalInfo);
  const setDataItem = useSetRecoilState(dataClickItem);
  const setMethodRequest = useSetRecoilState(methodRequestStatus);
  const setOpenFilter = useSetRecoilState(openFilterState);

  const navigate = useNavigate();

  // function add
  const handleAdd = () => {
    setOpenAdd(true);
    setDataItem(null);
    setMethodRequest("add");
  };

  console.log(
    "props.noSearch && !props.exel && !props.btn && !props.delete",
    props.noSearch && !props.exel && !props.btn && !props.delete
  );

  function handleOpenDatePicker(id) {
    const datePicker = document.getElementById(id);
    if ("showPicker" in HTMLInputElement.prototype) {
      datePicker.showPicker();
    } else {
      datePicker.click();
      datePicker.focus();
    }
  }

  return (
    <Card
      elevation={0}
      sx={{
        background: "transparent",
        borderBottom: !props.noBorder && "1px solid #ffffff5e",
        pb: 2,
      }}
    >
      <ModalDelete
        bulkDeleteUrl={bulkUrl}
        selected={selected}
        open={openBulkDelete}
        setOpen={setOpenBulkDelete}
        deleteToastMessage={deleteToastMessage}
        title={title}
      />
      <CardHeader
        sx={{
          background: "#transparent",
          width: "100%",
        }}
        title={
          <Box display={"flex"} width={"100%"} justifyContent={"space-between"}>
            <Box width={"100%"}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "start",
                  padding: "0px 10px",
                  svg: {
                    fill: "#fff",
                  },
                }}
              >
                {/* <Typography
                  variant="h6"
                  fontWeight="bold"
                  px={1}
                  mr={1}
                  sx={{
                    borderRight: "1px solid #343141",
                  }}
                  color={"#fff"}
                >
                  Dashboard
                </Typography> */}
                {props.href && (
                  <Link
                    onClick={()=>{
                      navigate(props.href)
                    }}
                    sx={{
                      cursor: "pointer",
                      borderRight: "1px solid #fff",
                      marginRight: "10px",
                    }}
                  >
                    <Typography
                      variant="h6"
                      fontWeight="bold"
                      px={2}
                      color={"#fff"}
                    >
                      {props.hrefText}
                    </Typography>
                  </Link>
                )}
                {props.icon}
                <Typography
                  variant="h6"
                  fontWeight="bold"
                  px={2}
                  color={"#fff"}
                >
                  {props.title}
                </Typography>
              </Box>
              {props?.types?.length > 0 && (
                <Box>
                  {props.types.map((item, index) => (
                    <Button
                      key={index}
                      variant="text"
                      sx={{
                        backgroundColor: "transparent",
                        "&:hover": {
                          backgroundColor: "transparent",
                        },
                      }}
                      onClick={() => {
                        item.function();
                        console.log("item", item);
                      }}
                    >
                      <Typography fontSize={12} color={item.color}>
                        {item.name}
                      </Typography>{" "}
                      {/* <Typography
                      fontSize={12}
                      mx={1}
                      color={"rgb(108, 117, 125)"}
                    >
                      ({item.count})
                    </Typography>{" "} */}
                    </Button>
                  ))}
                </Box>
              )}
            </Box>
            <Box
              width={"100%"}
              display={"flex"}
              justifyContent={"flex-end"}
              alignItems={"center"}
            >
              <>
                {props.exel && (
                  <ExportButton data={props.data} fileName="data" />
                )}
                {props.btn && (
                  <Button
                    variant="contained"
                    startIcon={<IoIosAddCircleOutline size={25} />}
                    sx={{
                      background:
                        "linear-gradient(310deg, #7928ca, #ff0080) !important",
                      minHeight: 40,
                      height: "fit-content",

                      mx: 1,
                    }}
                    onClick={() => handleAdd()}
                  >
                    {props.btn}
                  </Button>
                )}
                {props.deleteBulk && (
                  <Button
                    variant="contained"
                    startIcon={<DeleteOutlineIcon size={25} />}
                    sx={{
                      background:
                        "linear-gradient(310deg, #7928ca, #ff0080) !important",
                      minHeight: 40,
                      height: "fit-content",
                      mx: 1,
                    }}
                    onClick={() => {
                      selected.length > 0
                        ? setOpenBulkDelete(true)
                        : toast.error("Please select items to delete");
                        setMethodRequest("delete");
                        setBulkUrl(props.bulkDeleteUrl);
                        setDeleteToastMessage(props.deleteToastMessage);
                        setTitle("Delete");
                    }}
                  >
                    {props.deleteBtnText}
                  </Button>
                )}
                 {props.deactivateBulk && (
                  <Button
                    variant="contained"
                    startIcon={<DeleteOutlineIcon size={25} />}
                    sx={{
                      background:
                        "linear-gradient(310deg, #7928ca, #ff0080) !important",
                      minHeight: 40,
                      height: "fit-content",
                      mx: 1,
                    }}
                    onClick={() => {
                      selected.length > 0
                        ? setOpenBulkDelete(true)
                        : toast.error("Please select items to delete");
                        setMethodRequest("delete");
                        setBulkUrl(props.bulkDeleteUrl);
                        setDeleteToastMessage(props.deactivateToastMessage);
                        setTitle("Deactivate");
                    }}
                  >
                    {props.deactivateBtnText}
                  </Button>
                )}
              </>
            </Box>
          </Box>
        }
      />
      {(!props.noSearch || props.exel || props.btn || props.delete) && (
        <CardContent>
          <Grid container columnSpacing={1} rowSpacing={2} sx={{ px: 1 }}>
            <Grid item xs={6}>
              <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                {!props.noSearch && (
                  <Box
                    sx={{
                      width: "100%",
                      maxWidth: "450px",
                    }}
                  >
                    <Typography variant="h6" color={"#fff"} fontSize={"14px"}>
                      Search
                    </Typography>
                    <TextField
                      value={dataState.filters.search}
                      sx={{
                        border: "1px solid #ffffff26",
                        borderRadius: "4px",
                        backgroundColor: "#0f1535",
                        padding: "0px",
                        color: "#fff",
                        "& .MuiInputBase-formControl": {
                          width: "100%",
                          borderRadius: "4px",
                        },
                        input: {
                          color: "#fff",
                          borderRadius: "4px",
                        },
                      }}
                      onChange={(e) => {
                        setDataState((old) => ({
                          ...old,
                          filters: {
                            ...old.filters,
                            search: e.target.value,
                          },
                        }));
                      }}
                      size="small"
                      onKeyPress={(e) => {
                        if (e.key === "Enter") {
                          props.fetchData();
                        }
                      }}
                      InputProps={{
                        endAdornment: (
                          <IconButton
                            sx={{
                              color: "#fff",
                            }}
                            onClick={() => {
                              props.fetchData();
                            }}
                          >
                            <AiOutlineSearch />
                          </IconButton>
                        ),
                      }}
                      // placeholder="Search"
                      fullWidth
                    />
                  </Box>
                )}
                {props.date && (
                  <>
                    <Box
                      sx={{
                        width: "100%",
                        maxWidth: "250px",
                      }}
                    >
                      <Typography variant="h6" color={"#fff"} fontSize={"14px"}>
                        From
                      </Typography>

                      <Paper
                        component="form"
                        sx={{
                          p: "2px 4px",
                          display: "flex",
                          alignItems: "center",
                          width: "100%",
                          border: "1px solid #ffffff26",
                          background: "#0f1535",
                        }}
                      >
                        <InputBase
                          sx={{
                            ml: 1,
                            flex: 1,
                            background: "#0f1535",
                            color: "#fff",
                            svg: { fill: "#fff" },
                            appearance: "none",
                            "input[type='date']::-webkit-calendar-picker-indicator":
                              {
                                background: "transparent",
                                bottom: 0,
                                color: "transparent",
                                cursor: "pointer",
                                height: "auto",
                                left: 0,
                                position: "absolute",
                                right: 0,
                                top: 0,
                                width: "auto",
                              },
                          }}
                          placeholder="Search"
                          inputProps={{ "aria-label": "search" }}
                          type="Date"
                          id="date-picker-header-from"
                          value={selectedDate}
                          onChange={(e) => {
                            setSelectedDate(e.target.value);
                          }}
                        />
                        <IconButton
                          type="button"
                          aria-label="search"
                          color="inherit"
                          sx={{
                            svg: { fill: "#fff" },
                          }}
                          onClick={() => {
                            handleOpenDatePicker("date-picker-header-from");
                          }}
                        >
                          <CalendarMonth />
                        </IconButton>
                      </Paper>
                    </Box>
                    <Box
                      sx={{
                        width: "100%",
                        maxWidth: "250px",
                      }}
                    >
                      <Typography variant="h6" color={"#fff"} fontSize={"14px"}>
                        To
                      </Typography>
                      <Paper
                        component="to"
                        sx={{
                          p: "2px 4px",
                          display: "flex",
                          alignItems: "center",
                          width: "100%",
                          border: "1px solid #ffffff26",
                          background: "#0f1535",
                        }}
                      >
                        <InputBase
                          sx={{
                            ml: 1,
                            flex: 1,
                            background: "#0f1535",
                            color: "#fff",
                            svg: { fill: "#fff" },
                            appearance: "none",
                            "input[type='date']::-webkit-calendar-picker-indicator":
                              {
                                background: "transparent",
                                bottom: 0,
                                color: "transparent",
                                cursor: "pointer",
                                height: "auto",
                                left: 0,
                                position: "absolute",
                                right: 0,
                                top: 0,
                                width: "auto",
                              },
                          }}
                          placeholder="Search"
                          inputProps={{ "aria-label": "search" }}
                          type="Date"
                          id="date-picker-header-to"
                          value={selectedDateTo}
                          onChange={(e) => {
                            setSelectedDateTo(e.target.value);
                          }}
                        />
                        <IconButton
                          type="button"
                          aria-label="search"
                          color="inherit"
                          sx={{
                            svg: { fill: "#fff" },
                          }}
                          onClick={() => {
                            handleOpenDatePicker("date-picker-header-to");
                          }}
                        >
                          <CalendarMonth />
                        </IconButton>
                      </Paper>
                    </Box>
                  </>
                )}
              </Box>
            </Grid>
          </Grid>
        </CardContent>
      )}
    </Card>
  );
};
