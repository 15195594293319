import React, { useEffect, useState } from "react";
import { FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import { useRecoilState } from "recoil";
import { selectedCategoryState } from "../../recoil/select";
import { GetCategoryData } from "../../services/api/category";

const SelectCategory = (props) => {
  const [selectedCategory, setSelectedCategory] = useRecoilState(
    selectedCategoryState
  );
  const [data, setData] = useState([]);
  const handleChange = (event) => {
    setSelectedCategory(event.target.value);
  };

  const fetchData = () => {
    setData((old) => ({
      ...old,
      loading: true,
      data: [],
    }));
    GetCategoryData(0, 10000, "")
      .then((res) => {
        setData((old) => ({
          ...old,
          data: res.results,
          total: res?.count,
          loading: false,
        }));
        console.log("Dtest", res);
      })
      .catch(() => {
        setData({ ...data, loading: false });
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <FormControl fullWidth margin="normal" sx={{ minWidth: 300 }}>
      <InputLabel id="status-label" sx={{ background: "#fff", top: -15,left: -10, fontSize: "1.25rem" }}>
        Select Category
      </InputLabel>
      {!data?.loading && (
        <Select
          size="small"
          sx={{ minWidth: 300 }}
          labelId="status-label"
          id="status-select"
          onChange={handleChange}
          value={selectedCategory}
          required={props.required ? true : false}
        >
          {data?.data?.map((item, index) => (
            <MenuItem key={index} value={item.id}>
              {item.name}
            </MenuItem>
          ))}
        </Select>
      )}
    </FormControl>
  );
};

export default SelectCategory;
