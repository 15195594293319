import { useEffect } from "react";
import { Avatar, Box } from "@mui/material";
import {
  useRecoilState,
  useRecoilValue,
  useResetRecoilState,
  useSetRecoilState,
} from "recoil";
import { DataGridTabel } from "../DataGridTabel/DataGridTabel";
import { ColumnText } from "../DataGridTabel/ColumnText";
import { ColumnButton } from "../DataGridTabel/ColumnButton";
import { HeaderGridTabel } from "../DataGridTabel/HeaderGridTabel";
import HomeWorkIcon from "@mui/icons-material/HomeWork";
import ModalInfo from "./Modal/ModalInfo";
import {
  dataClickItem,
  methodRequestStatus,
  openFilterState,
  updateDataState,
} from "../../recoil/global";
import {
  confirmModalState,
  openModalDelete,
  openModalInfo,
  openModalShow,
} from "../../recoil/modal";
import ModalDelete from "./Modal/ModalDelete";
import { useState } from "react";
import { dataTabelState } from "../../recoil/Tabel";
import Filters from "./Filters";
import { selectedStatusState } from "../../recoil/select";
import { GetUserData } from "../../services/api/user";
import AdminPanelSettingsOutlinedIcon from "@mui/icons-material/AdminPanelSettingsOutlined";
import moment from "moment";
import { maxWidth, minWidth } from "@mui/system";
import { useNavigate } from "react-router-dom";

export const Tabel = () => {
  const [data, setData] = useRecoilState(dataTabelState);
  const [emailSearch, setEmailSearch] = useState(null);
  const [phoneSearch, setPhoneSearch] = useState(null);

  const selectedStatus = useRecoilValue(selectedStatusState);
  const state = useRecoilValue(openFilterState);
  const updateData = useRecoilValue(updateDataState);

  const resetConfirm = useResetRecoilState(confirmModalState);

  const setDataItem = useSetRecoilState(dataClickItem);
  const setOpenModal = useSetRecoilState(openModalInfo);
  const setOpenDelete = useSetRecoilState(openModalDelete);
  const setMethodRequest = useSetRecoilState(methodRequestStatus);

  const navigate = useNavigate();

  useEffect(() => {
    setDataItem(null);
  }, [state]);

  const handelEdit = (data) => {
    setOpenModal(true);
    setDataItem(data);
    setMethodRequest("edit");
  };

  const handelDelete = (data) => {
    setOpenDelete(true);
    setDataItem(data);
    setMethodRequest("delete");
  };

 

  const transformStatus = (status) => {
    return status
      .toLowerCase()
      .split("_")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };

  const columns = [
    {
      field: "first_name",
      headerName: "Customers",
      headerClassName: "grid--header",
      flex: 1,
      maxWidth: 300,
      minWidth: 300,
      renderCell: (params) => {
        return (
          <Box
            display={"flex"}
            alignItems={"center"}
            sx={{
              overflowX: "auto",
              scrollbarWidth: "none",
              height: "65px",
              cursor: "pointer",
              width: "100%",
            }}
            gap={2}
            onClick={() => {
              navigate(`/userDetailes?id=${params.row.id}`);
            }}

          >
            <Avatar
              sx={{
                borderRadius: "9999999px",
              }}
              sizes="small"
              alt={params.row?.first_name}
              src={"https://www.w3schools.com/"}
            />
            <ColumnText
              text={
                params.row?.first_name
                  ? `${params.row?.first_name} ${params.row?.last_name || ""}`
                  : "N/A"
              }
              variant="subtitle1"
              fontWeight="bold"
            />
          </Box>
        );
      },
    },
    {
      field: "email",
      headerName: "Email",
      headerClassName: "grid--header",
      flex: 1,

      maxWidth: 280,
      minWidth: 280,
      renderCell: (params) => {
        return (
          <Box
            display={"flex"}
            alignItems={"center"}
            sx={{
              overflowX: "auto",
              scrollbarWidth: "none",
              height: "65px",
              cursor: "pointer",
              width: "100%",
            }}
            gap={2}
            onClick={() => {
              navigate(`/userDetailes?id=${params.row.id}`);
            }}
          >
            <ColumnText
              text={params.row?.email ? params.row?.email : "N/A"}
              variant="subtitle1"
              fontWeight="bold"
            />
          </Box>
        );
      },
    },
    {
      field: "total_orders",
      headerName: "Orders",
      headerClassName: "grid--header",
      flex: 1,
      maxWidth: 160,
      minWidth: 160,
      renderCell: (params) => {
        return (
          <Box
            display={"flex"}
            alignItems={"center"}
            sx={{
              overflowX: "auto",
              scrollbarWidth: "none",
            }}
            gap={2}
          >
            <ColumnText
              text={params.row?.total_orders ? params.row?.total_orders : "N/A"}
              variant="subtitle1"
              fontWeight="bold"
            />
          </Box>
        );
      },
    },
    {
      field: "total_orders_price",
      headerName: "Total Spent",
      headerClassName: "grid--header",
      flex: 1,
      maxWidth: 150,
      minWidth: 150,
      renderCell: (params) => {
        return (
          <Box
            display={"flex"}
            alignItems={"center"}
            sx={{
              overflowX: "auto",
              scrollbarWidth: "none",
            }}
            gap={2}
          >
            <ColumnText
              text={
                params.row?.total_orders_price
                  ? "$" + params.row?.total_orders_price
                  : "$0"
              }
              variant="subtitle1"
              fontWeight="bold"
            />
          </Box>
        );
      },
    },
    {
      field: "city",
      headerName: "Location",
      headerClassName: "grid--header",
      flex: 1,
      minWidth: 180,
      maxWidth: 180,
      renderCell: (params) => {
        return (
          <Box
            display={"flex"}
            alignItems={"center"}
            sx={{
              overflowX: "auto",
              scrollbarWidth: "none",
            }}
            gap={2}
          >
            <ColumnText
              text={
                params.row?.city ? transformStatus(params.row?.city) : "N/A"
              }
              variant="subtitle1"
              fontWeight="bold"
            />
          </Box>
        );
      },
    },
    {
      field: "role",
      headerName: "Role",
      headerClassName: "grid--header",
      flex: 1,
      maxWidth: 150,
      minWidth: 150,
      renderCell: (params) => {
        return (
          <Box
            display={"flex"}
            alignItems={"center"}
            sx={{
              overflowX: "auto",
              scrollbarWidth: "none",
            }}
            gap={2}
          >
            <ColumnText
              text={
                params.row?.role ? (
                  params.row?.role === "Admin" ? (
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",

                        color: "#fff",
                        borderRadius: 2,
                      }}
                    >
                      <AdminPanelSettingsOutlinedIcon
                        sx={{
                          color: "#fff",
                          fontSize: 20,
                          marginRight: 1,
                        }}
                      />
                      {transformStatus(params.row?.role)}
                    </Box>
                  ) : (
                    transformStatus(params.row?.role)
                  )
                ) : (
                  "N/A"
                )
              }
              variant="subtitle1"
              fontWeight="bold"
            />
          </Box>
        );
      },
    },
    {
      field: "last_order_date",
      headerName: "Last Order",
      headerClassName: "grid--header",
      flex: 1,
      minWidth: 175,
      renderCell: (params) => {
        return (
          <Box
            display={"flex"}
            alignItems={"center"}
            sx={{
              overflowX: "auto",
              scrollbarWidth: "none",
            }}
            gap={2}
          >
            <ColumnText
              text={
                params.row?.last_order_date
                  ? moment(new Date(params.row.last_order_date)).format(
                      "MMM D YYYY, h:mm a"
                    )
                  : "N/A"
              }
              variant="subtitle1"
              fontWeight="bold"
            />
            {/* <ColumnText text={params.row?.job_title?.name_ar} name_ar="caption" color={"gray"} /> */}
          </Box>
        );
      },
    },    {
      field: "is_active",
      headerName: "Active",
      headerClassName: "grid--header",
      flex: 1,
      minWidth: 175,
      renderCell: (params) => {
        return (
          <Box
            display={"flex"}
            alignItems={"center"}
            sx={{
              overflowX: "auto",
              scrollbarWidth: "none",
            }}
            gap={2}
          >
            <ColumnText
              text={
                params.row?.is_active
                  ? "Active"
                  : "Inactive"
              }
              variant="subtitle1"
              fontWeight="bold"
            />
            {/* <ColumnText text={params.row?.job_title?.name_ar} name_ar="caption" color={"gray"} /> */}
          </Box>
        );
      },
    },
    // {
    //   field: "operations",
    //   headerName: "Operations",
    //   flex: 1,
    //   headerClassName: "grid--header",
    //   renderCell: (params) => {
    //     return (
    //       <Box
    //         display={"flex"}
    //         alignItems={"center"}
    //         sx={{
    //           overflowX: "auto",
    //           scrollbarWidth: "none",
    //         }}
    //       >
    //         <ColumnButton
    //           onClick={() => handelEdit(params.row)}
    //           type={"edit"}
    //         />
    //          <Box
    //           sx={{
    //             width: "1px",
    //             height: "20px",
    //             backgroundColor: "#f0f0f0",
    //             margin: "0 10px",
    //           }}
    //         ></Box>
    //         <ColumnButton
    //           onClick={() => handelDelete(params.row)}
    //           type={"delete"}
    //         />
    //       </Box>
    //     );
    //   },
    // },
  ];

  const fetchData = () => {
    setData((old) => ({
      ...old,
      loading: true,
      data: [],
    }));
    GetUserData(
      data.filters.skip,
      data.filters.take,
      data.filters.search,
      emailSearch,
      phoneSearch,
      selectedStatus
    )
      .then((res) => {
        console.log("Dtest", res);
        setData((old) => ({
          ...old,
          data: res.results.map((item) => ({
            ...item,
            id: item.pk,
          })),
          total: res?.count,
          loading: false,
        }));
      })
      .catch(() => {
        setData({ ...data, loading: false });
      });
  };

  let filters = data.filters.page || data.filters.take || data.filters.skip;

  useEffect(() => {
    fetchData();
  }, [filters, updateData]);

  return (
    <>
      <Box>
        <HeaderGridTabel
          title="Users"
          icon={
            <HomeWorkIcon
              size={25}
              className="color-primary"
              color={"#08001e"}
            />
          }
          btn={"Add User"}
          fetchData={fetchData}
          isFilter={false}

          deleteBulk={true}
          bulkDeleteUrl={"/auth/admin/users/delete-bulk/"}
          deleteToastMessage={"Users Deleted successfully"}
          deleteBtnText={"Delete Bulk users"}

          deactivateBulk={true}
          deactivateUrl={"/auth/admin/users/deactivate-bulk/"}
          deactivateToastMessage={"User deactivated successfully"}
          deactivateBtnText={"Deactivated Bulk users"}

        />
        <Box mt={2}>
          <DataGridTabel
            columns={columns}
            data={data}
            setData={setData}
            checkbox={true}
          />
        </Box>
        {/* Modal */}
        <ModalInfo />
        <ModalDelete />
        {/* Fillter */}
        <Filters
          emailSearch={emailSearch}
          setEmailSearch={setEmailSearch}
          phoneSearch={phoneSearch}
          setPhoneSearch={setPhoneSearch}
          fetchData={fetchData}
        />
      </Box>
    </>
  );
};

export default Tabel;
