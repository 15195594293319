import { deleteData, getData, patchData, postData, putData } from "..";

// get Notifications
export const GetNotificationsData = async (
  skip,
  take,
  search,
  level,
  createdAfter,
  createdBefor,
  filter
) => {
  console.log("skip", createdAfter, createdBefor);
  if ((skip !== undefined && take !== undefined) || search !== search) {
    try {
      const request = await getData(
        `/notifications/?page=${
          skip === 0 ? 1 : skip || take
        }&page_size=${take}${search ? "&search=" + search : ""}${
          level ? `&notification_level=${level}` : ""
        }${createdAfter ? `&created_after=${createdAfter}` : ""}${
          createdBefor ? `&created_before=${createdBefor}` : ""
        }${filter ? `&linked_model_name=${filter}` : ""}`
      ); // Handle the data
      return request;
    } catch (error) {
      console.error(error); // Handle the error
      throw error;
    }
  }
};

// get Notifications By Id
export const GetNotificationsByIdData = async (id) => {
  if (id) {
    try {
      const request = await getData(`/notifications/${id}`); // Handle the data
      return request;
    } catch (error) {
      console.error(error); // Handle the error
      throw error;
    }
  }
};

// post Notifications
export const PostNotifications = async (data) => {
  try {
    const request = await postData(`/notifications/`, data); // Handle the data
    return request;
  } catch (error) {
    console.error(error); // Handle the error
    throw error;
  }
};

// put Notifications
export const PutNotifications = async (id, data) => {
  try {
    const request = await putData(`/notifications/${id}/`, data); // Handle the data
    return request;
  } catch (error) {
    console.error(error); // Handle the error
    throw error;
  }
};

// delete Notifications
export const DeleteNotifications = async (id) => {
  try {
    const request = await deleteData(`/notifications/${id}`); // Handle the data
    return request;
  } catch (error) {
    console.error(error); // Handle the error
    throw error;
  }
};

export const GetFilteredNotifications = async (filter, hidden) => {
  try {
    const request = await getData(
      `/notifications/?${filter ? `linked_model_name=${filter}` : ""}${
        hidden > 0 ? `&hidden=${hidden === 1 ? "false" : "true"}` : ""
      }`
    ); // Handle the data
    return request;
  } catch (error) {
    console.error(error); // Handle the error
    throw error;
  }
};

export const HideNotification = async (id) => {
  try {
    const request = await patchData(`/notifications/${id}/set-hidden/`); // Handle the data
    return request;
  } catch (error) {
    console.error(error); // Handle the error
    throw error;
  }
};

export const HideAllNotification = async () => {
  try {
    const request = await patchData(`/notifications/set-all-hidden/`); // Handle the data
    return request;
  } catch (error) {
    console.error(error); // Handle the error
    throw error;
  }
};
