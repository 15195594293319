import React, { useEffect } from "react";
import { FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import { useRecoilState } from "recoil";
import { selectedTagsState } from "../../recoil/select";

const SelectTags = (props) => {
  const [selectedTags, setSelectedTags] = useRecoilState(selectedTagsState);

  const handleChange = (event) => {
    setSelectedTags(event.target.value);
  };


  const data = ["new", "sale", "featured","no Tag"];

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  return (
    <FormControl fullWidth margin="normal" sx={{ minWidth: 300 }}>
      <InputLabel id="status-label" sx={{ background: "#fff",top: -6 }}>
        Select Tag
      </InputLabel>
      <Select
        size="small"
        sx={{ minWidth: 300 }}
        labelId="status-label"
        id="status-select"
        onChange={handleChange}
        value={selectedTags}
      >
        {data?.map((status, index) => (
          <MenuItem key={index} value={status}>
           {capitalizeFirstLetter(status)}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default SelectTags;
