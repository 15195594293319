import React, { useEffect, useState } from "react";
import { FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import { useRecoilState } from "recoil";
import {
  selectedSubCategoryState,
  selectedCategoryState,
} from "../../recoil/select";
import { GetSubCategoryData } from "../../services/api/subCategory";

const SelectSubCategory = (props) => {
  const [selectedSubCategory, setSelectedSubCategory] = useRecoilState(
    selectedSubCategoryState
  );
  const [selectedCategory, setSelectedCategory] = useRecoilState(
    selectedCategoryState
  );
  const [filteredData, setFilteredData] = useState([]);
  const [data, setData] = useState([]);
  const handleChange = (event) => {
    setSelectedSubCategory(event.target.value);
  };

  const fetchData = () => {
    setData((old) => ({
      ...old,
      loading: true,
      data: [],
    }));
    GetSubCategoryData(0, 25, "",selectedCategory)
      .then((res) => {
        setData((old) => ({
          ...old,
          data: selectedCategory ? res.results : res.results,
          total: res?.count,
          loading: false,
        }));
        selectedCategory && setFilteredData(res.results);
      })
      .catch(() => {
        setData({ ...data, loading: false });
      });
  };

  useEffect(() => {
    fetchData();
  }, [selectedCategory]);


  useEffect(() => {
    console.log("selectedCategory", selectedCategory);
    console.log("filteredData", filteredData);
    console.log("data", data);
  }, [filteredData]);

  return (
    <FormControl fullWidth margin="normal" sx={{ minWidth: 300 }}>
      <InputLabel id="status-label" sx={{ background: "#fff", top: -15,left: -10, fontSize: "1.25rem" }}>
        Select Sub Category
      </InputLabel>
      {
        !data?.loading && (
          <Select
            size="small"
            sx={{ minWidth: 300 }}
            labelId="status-label"
            id="status-select"
            onChange={handleChange}
            value={selectedSubCategory}
          >
            {filteredData?.map((item, index) => (
              <MenuItem key={index} value={item.id}>
                {item.name}
              </MenuItem>
            ))}
          </Select>
        )
      }
    </FormControl>
  );
};

export default SelectSubCategory;
