import { useEffect, useRef } from "react";
import { Avatar, Box } from "@mui/material";
import {
  useRecoilState,
  useRecoilValue,
  useResetRecoilState,
  useSetRecoilState,
} from "recoil";
import { DataGridTabel } from "../DataGridTabel/DataGridTabel";
import { ColumnText } from "../DataGridTabel/ColumnText";
import { ColumnButton } from "../DataGridTabel/ColumnButton";
import { HeaderGridTabel } from "../DataGridTabel/HeaderGridTabel";
import HomeWorkIcon from "@mui/icons-material/HomeWork";
import ModalInfo from "./Modal/ModalInfo";
import {
  dataClickItem,
  methodRequestStatus,
  openFilterState,
  updateDataState,
} from "../../recoil/global";
import {
  confirmModalState,
  openModalDelete,
  openModalInfo,
} from "../../recoil/modal";
import ModalDelete from "./Modal/ModalDelete";
import { useState } from "react";
import { dataTabelState } from "../../recoil/Tabel";
import Filters from "./Filters";
import {
  selectedStatusState,
  selectedNotificationLevelState,
} from "../../recoil/select";
import { GetNotificationsData } from "../../services/api/notifications";
import { maxHeight, minHeight } from "@mui/system";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import jsPDF from "jspdf";
import "jspdf-autotable";
import copy from "clipboard-copy";
import * as XLSX from "xlsx";
import { useReactToPrint } from "react-to-print";

export const Tabel = () => {
  const [data, setData] = useRecoilState(dataTabelState);
  const [createdAfter, setCreatedAfter] = useState(null);
  const [createdBefor, setCreatedBefor] = useState(null);

  const selectedStatus = useRecoilValue(selectedStatusState);
  const state = useRecoilValue(openFilterState);
  const updateData = useRecoilValue(updateDataState);
  const notificationLevel = useRecoilValue(selectedNotificationLevelState);

  const resetConfirm = useResetRecoilState(confirmModalState);

  const setDataItem = useSetRecoilState(dataClickItem);
  const setOpenModal = useSetRecoilState(openModalInfo);
  const setOpenDelete = useSetRecoilState(openModalDelete);
  const setMethodRequest = useSetRecoilState(methodRequestStatus);

  const navigate = useNavigate();
  const contentRef = useRef();

  const handlePrint = useReactToPrint({
    contentRef,
  });

  useEffect(() => {
    setDataItem(null);
  }, [state]);

  const handelEdit = (data) => {
    setOpenModal(true);
    setDataItem(data);
    setMethodRequest("remove-footer");
  };

  const handelDelete = (data) => {
    setOpenDelete(true);
    setDataItem(data);
    setMethodRequest("delete");
  };

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const columns = [
    {
      field: "id",
      headerName: "Notification ID",
      headerClassName: "grid--header",
      maxHeight: 50,
      flex: 1,
      renderCell: (params) => {
        return (
          <Box
            display={"flex"}
            alignItems={"center"}
            sx={{
              overflowX: "auto",
              scrollbarWidth: "none",
              cursor: "pointer",
              width: "100%",
              height: "40px",
            }}
            onClick={() => {
              if (params.row?.related_object?.id) {
                navigate(`/product?id=${params.row?.related_object?.id}`);
              } else {
                toast.error("Product not found");
              }
            }}
          >
            <ColumnText
              text={
                params.row?.id
                  ? params.row?.id
                  : "N/A"
              }
              variant="subtitle1"
              fontWeight="bold"
            />
            {/* <ColumnText text={params.row?.job_title?.name_ar} name_ar="caption" color={"gray"} /> */}
          </Box>
        );
      },
    },
    {
      field: "Product",
      headerName: "Product",
      headerClassName: "grid--header",
      flex: 1,
      renderCell: (params) => {
        return (
          <Box
            display={"flex"}
            alignItems={"center"}
            sx={{
              overflowX: "auto",
              scrollbarWidth: "none",
              cursor: "pointer",
              width: "100%",
              height: "40px",
            }}
            onClick={() => {
              if (params.row?.related_object?.id) {
                navigate(`/product?id=${params.row?.related_object?.id}`);
              } else {
                toast.error("Product not found");
              }
            }}
          >
            <ColumnText
              text={
                params.row?.related_object?.name
                  ? params.row?.related_object?.name
                  : "N/A"
              }
              variant="subtitle1"
              fontWeight="bold"
            />
            {/* <ColumnText text={params.row?.job_title?.name_ar} name_ar="caption" color={"gray"} /> */}
          </Box>
        );
      },
    },
    {
      field: "Status",
      headerName: "Status",
      headerClassName: "grid--header",
      flex: 1,
      renderCell: (params) => {
        return (
          <Box
            display={"flex"}
            alignItems={"center"}
            sx={{
              overflowX: "auto",
              scrollbarWidth: "none",
              cursor: "pointer",
              width: "100%",
              height: "40px",
            }}
            onClick={() => {
              if (params.row?.related_object?.id) {
                navigate(`/product?id=${params.row?.related_object?.id}`);
              } else {
                toast.error("Product not found");
              }
            }}
          >
            <ColumnText
              text={
                params.row?.description
                  ? params.row?.description?.includes("out of")
                    ? "only 0 keys in stock"
                    : "only" +
                      params.row?.description
                        ?.split("only")[1]
                        ?.replace("left", "in stock")
                  : "N/A"
              }
              sx={{
                background:
                  params.row.notification_level === "normal"
                    ? "linear-gradient(310deg, #f7971e, #ffd200) !important"
                    : params.row.notification_level === "low"
                    ? "linear-gradient(310deg, #5fe2a9, #00a79d) !important"
                    : "linear-gradient(310deg, #ee0979, #ff6a00) !important",
                px: 1,
                borderRadius: "999px",
              }}
              variant="subtitle1"
              fontWeight="bold"
            />
          </Box>
        );
      },
    },
    {
      field: "Category",
      headerName: "Category",
      headerClassName: "grid--header",
      flex: 1,
      renderCell: (params) => {
        return (
          <Box
            display={"flex"}
            alignItems={"center"}
            sx={{
              overflowX: "auto",
              scrollbarWidth: "none",
              cursor: "pointer",
              width: "100%",
              height: "40px",
            }}
            onClick={() => {
              if (params.row?.related_object?.id) {
                navigate(`/product?id=${params.row?.related_object?.id}`);
              } else {
                toast.error("Product not found");
              }
            }}
          >
            <ColumnText
              text={
                params.row?.related_object?.category_name
                  ? params.row?.related_object?.category_name
                  : "N/A"
              }
              variant="subtitle1"
              fontWeight="bold"
            />
          </Box>
        );
      },
    },
    // {
    //   field: "operations",
    //   headerName: "Operations",
    //   flex: 1,
    //   headerClassName: "grid--header",
    //   renderCell: (params) => {
    //     return (
    //       <Box
    //         display={"flex"}
    //         alignItems={"center"}
    //         sx={{
    //           overflowX: "auto",
    //           scrollbarWidth: "none",
    //         }}
    //       >
    //         <ColumnButton
    //           onClick={() => handelEdit(params.row)}
    //           type={"show"}
    //         />
    //         <ColumnButton
    //           onClick={() => handelDelete(params.row)}
    //           type={"delete"}
    //         />
    //       </Box>
    //     );
    //   },
    // },
  ];

  const fetchData = () => {
    setData((old) => ({
      ...old,
      loading: true,
      data: [],
    }));
    GetNotificationsData(
      data.filters.skip,
      data.filters.take,
      data.filters.search,
      notificationLevel,
      createdAfter,
      createdBefor,
      "products.Product"
    )
      .then((res) => {
        console.log("Dtest", res);
        setData((old) => ({
          ...old,
          data: res.results,
          total: res?.count,
          loading: false,
        }));
      })
      .catch(() => {
        setData({ ...data, loading: false });
      });
  };

  let filters = data.filters.page || data.filters.take || data.filters.skip;

  useEffect(() => {
    fetchData();
  }, [filters, updateData, notificationLevel, createdBefor, createdAfter]);

  const exportToExcel = () => {
    const newData = data.data.map((item) => {
      return {
        ProductId: item?.related_object_id,
        Product: item?.related_object?.name,
        Status: item?.description,
        Category: item?.related_object?.category_name,
      };
    });
    const ws = XLSX.utils.json_to_sheet(newData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Data");
    XLSX.writeFile(
      wb,
      `${new Date().toISOString().split("T")[0]}/product-notifications.xlsx`
    );
  };

  const exportToPDF = () => {
    const newData = data.data.map((item) => {
      return {
        ProductId: item?.related_object_id,
        Product: item?.related_object?.name,
        Status: item?.description,
        Category: item?.related_object?.category_name,
      };
    });

    const doc = new jsPDF();

    // Add title
    doc.text(
      "                       Original Software | Admin Dashboard Template",
      14,
      10
    );

    // Define table data
    const tableData = [
      ["ProductId", "Product", "Status", "Category"],
      ...newData.map((item) => [
        item.ProductId,
        item.Product,
        item.Status,
        item.Category,
      ]),
    ];

    // Generate table
    doc.autoTable({
      head: [tableData[0]], // Table headers
      body: tableData.slice(1), // Table rows
      startY: 20, // Position below title
      styles: {
        fontSize: 10, // Default font size for table
        cellPadding: 0, // Cell padding
        halign: "center", // Text alignment
        valign: "middle", // Vertical alignment
      },
      headStyles: {
        fontSize: 11, // Font size for the header
        minCellHeight: 11, // Custom header row height
        halign: "center", // Center-align header text
        fillColor: [45, 65, 84], // Light gray background color for headers
      },
      columnStyles: {
        0: { minCellHeight: 10 }, // OrderID column width
        1: { minCellHeight: 10 }, // Customer Name column width
        2: { minCellHeight: 10 }, // Email column width
        3: { minCellHeight: 10 }, // Status column width
      },
    });

    // Save the PDF
    doc.save(
      `${new Date().toISOString().split("T")[0]}/product-notifications.pdf`
    );
  };

  const copyToClipboard = () => {
    const newData = data.data.map((item) => {
      return {
        ProductId: item?.related_object_id,
        Product: item?.related_object?.name,
        Status: item?.description,
        Category: item?.related_object?.category_name,
      };
    });
    copy(JSON.stringify(newData));
    toast.success("Data copied to clipboard");
  };

  return (
    <>
      <Box>
        <HeaderGridTabel
          title="Notifications"
          icon={
            <HomeWorkIcon
              size={25}
              className="color-primary"
              color={"#08001e"}
            />
          }
          // btn={"Add Notifications"}
          fetchData={fetchData}
          isFilter={true}
        />
        <Box mt={2}>
          <DataGridTabel
            columns={columns}
            data={data}
            setData={setData}
            small={true}
            height={"60vh"}
            exports={true}
            exportToExcel={exportToExcel}
            exportToPDF={exportToPDF}
            print={handlePrint}
            copy={copyToClipboard}
          />
        </Box>
        <div style={{ display: "none" }}>
          <div ref={contentRef}>
            <h2 style={{ textAlign: "center", width: "100%" }}>
              Original Software | Admin Dashboard Template
            </h2>
            <table
              style={{
                borderCollapse: "collapse",
                width: "100%",
                textAlign: "center",
              }}
            >
              <thead>
                <tr style={{ backgroundColor: "#ccc" }}>
                  <th style={{ border: "1px solid #000", padding: "7px" }}>
                    Product ID
                  </th>
                  <th style={{ border: "1px solid #000", padding: "7px" }}>
                    Product
                  </th>
                  <th style={{ border: "1px solid #000", padding: "7px" }}>
                    Status
                  </th>
                  <th style={{ border: "1px solid #000", padding: "7px" }}>
                    Category
                  </th>
                </tr>
              </thead>
              <tbody>
                {data.data
                  .map((item) => {
                    return {
                      ProductId: item?.related_object_id,
                      Product: item?.related_object?.name,
                      Status: item?.description,
                      Category: item?.related_object?.category_name,
                    };
                  })
                  .map((row) => (
                    <tr key={row.ProductId}>
                      <td
                        style={{
                          border: "1px solid #000",
                          padding: "7px",
                          maxWidth: "50px",
                        }}
                      >
                        {row.ProductId}
                      </td>
                      <td
                        style={{
                          border: "1px solid #000",
                          padding: "7px",
                          maxWidth: "150px",
                        }}
                      >
                        {row.Product}
                      </td>
                      <td
                        style={{
                          border: "1px solid #000",
                          padding: "7px",
                          maxWidth: "200px",
                        }}
                      >
                        {row?.Status}
                      </td>

                      <td
                        style={{
                          border: "1px solid #000",
                          padding: "7px",
                          maxWidth: "100px",
                        }}
                      >
                        {row.Category}
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
        {/* Modal */}
        <ModalInfo />
        <ModalDelete />
        {/* Fillter */}
        <Filters
          createdBefor={createdBefor}
          setCreatedAfter={setCreatedAfter}
          createdAfter={createdAfter}
          setCreatedBefor={setCreatedBefor}
          fetchData={fetchData}
        />
      </Box>
    </>
  );
};

export default Tabel;
