import React, { useState } from "react";
import { Box, Button, Grid, TextField, Typography } from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import DeleteIcon from "@mui/icons-material/Delete";
import { v4 as uuidv4 } from "uuid";

const MultiImageProduct = ({ imgList, setImgList, imageKey, path }) => {
  const [previewImage, setPriviewImage] = useState(null);

  const toggleBodyOverflow = (shouldDisableScroll) => {
    document.body.style.overflow = shouldDisableScroll ? "hidden" : "auto";
    const div = document.getElementsByClassName("MuiDialog-paperWidthSm");
    div[0].style.overflowY = shouldDisableScroll ? "hidden" : "auto";
  };

  const handleDeleteImage = (id) => {
    const newList = imgList.map((item) => {
      if (item.id === id && item?.state === "new") {
        return { ...item, state: "null" };
      } else if (item.id === id) {
        return { ...item, state: "deleted" };
      }
      return item;
    });
    setImgList(newList);
  };

  const handleImageChange = (event, id) => {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onloadend = () => {
      const base64String = reader.result;
      const newList = imgList.map((item) => {
        if (item.id === id) {
          return {
            ...item,
            [imageKey]: URL.createObjectURL(file),
            file: file,
            base64: base64String,
            state: item?.state === "new" ? "new" : "updated",
          };
        }
        return item;
      });
      setImgList(newList);
    };

    reader.readAsDataURL(file);
  };

  const handleAddImage = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onloadend = () => {
      const base64String = reader.result;
      const newList = imgList.concat({
        id: uuidv4(),
        [imageKey]: URL.createObjectURL(file),
        file: file,
        base64: base64String,
        state: "new",
      });
      setImgList(newList);
    };

    reader.readAsDataURL(file);
  };

  return (
    <Box
      sx={{
        width: "100%",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
        }}
        noValidate
      >
        <Button
          margin="normal"
          component="label"
          variant="outlined"
          sx={{
            width: "100%",
            py: "40px",
            border: "2px dashed #008cff",
            svg: {
              fill: "#008cff !important",
            },
            "&:hover": {
              borderWidth: "2px !important",
            },
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="100"
            height="100"
            fill="#008cff"
            class="bi bi-cloud-upload"
            viewBox="0 0 16 16"
          >
            <path
              fill-rule="evenodd"
              d="M4.406 1.342A5.53 5.53 0 0 1 8 0c2.69 0 4.923 2 5.166 4.579C14.758 4.804 16 6.137 16 7.773 16 9.569 14.502 11 12.687 11H10a.5.5 0 0 1 0-1h2.688C13.979 10 15 8.988 15 7.773c0-1.216-1.02-2.228-2.313-2.228h-.5v-.5C12.188 2.825 10.328 1 8 1a4.53 4.53 0 0 0-2.941 1.1c-.757.652-1.153 1.438-1.153 2.055v.448l-.445.049C2.064 4.805 1 5.952 1 7.318 1 8.785 2.23 10 3.781 10H6a.5.5 0 0 1 0 1H3.781C1.708 11 0 9.366 0 7.318c0-1.763 1.266-3.223 2.942-3.593.143-.863.698-1.723 1.464-2.383"
            />
            <path
              fill-rule="evenodd"
              d="M7.646 4.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 5.707V14.5a.5.5 0 0 1-1 0V5.707L5.354 7.854a.5.5 0 1 1-.708-.708z"
            />
          </svg>
          <input
            type="file"
            name={`UpFile`}
            onChange={handleAddImage}
            style={{ display: "none" }}
          />
        </Button>
      </Box>
      {imgList.length > 0 && (
        <Box
          sx={{
            width: "100%",
            maxHeight: "200px",
            overflowY: "auto",
            mt: 2,
            px: 1,
            pb: 2,
          }}
        >
          {imgList
            .filter(
              (item) => item?.state !== "deleted" && item?.state !== "null"
            )
            .map((item, index) => (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                }}
                sm={12}
                key={item.id}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-around",
                    width: "100%",
                    mt: 3,
                  }}
                  noValidate
                >
                  <Box
                    sx={{
                      width: "100%",
                      display: "flex",
                      gap: "10px",
                      alignItems: "center",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "space-around",
                        width: "100%",
                        backgroundColor: "white",
                        position: "relative",
                        ".hide": {
                          display: "none",
                          transition: "all 0.3s ease",
                        },
                        "&:hover": {
                          ".hide": {
                            display: "flex",
                          },
                        },
                      }}
                    >
                      <img
                        src={imageKey ? item[imageKey] : item?.image_file}
                        alt="product"
                        style={{
                          width: "100%",
                          height: "auto",
                          margin: "auto",
                        }}
                      />
                      <Box
                        className="hide"
                        sx={{
                          position: "absolute",
                          cursor: "pointer",
                          color: "#fff",
                          background: "#00000066",
                          width: "100%",
                          height: "100%",
                          zIndex: 10,
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                        onClick={() => {
                          setPriviewImage(
                            imageKey ? item[imageKey] : item?.image_file
                          );
                          // over flow hidden
                          toggleBodyOverflow(true);
                        }}
                      >
                        <Typography>Preivew</Typography>
                      </Box>
                    </Box>
                    <Button
                      margin="normal"
                      component="label"
                      variant="outlined"
                      startIcon={<CloudUploadIcon />}
                      sx={{
                        width: "100%",
                        height: "50px",
                        mx: "auto",
                        borderRadius: "5px",
                      }}
                    >
                      Change
                      <input
                        type="file"
                        name={`file-${item.id}`}
                        onChange={(e) => handleImageChange(e, item.id)}
                        style={{ display: "none" }}
                      />
                    </Button>
                    <Button
                      sx={{
                        width: "100%",
                        backgroundColor: "red",
                        color: "white",
                        height: "50px",

                        borderRadius: "5px",
                        svg: {
                          fill: "#fff",
                        },
                      }}
                      variant="contained"
                      startIcon={<DeleteIcon />}
                      onClick={() => handleDeleteImage(item.id)}
                    >
                      Remove
                    </Button>
                  </Box>
                </Box>
              </Box>
            ))}
        </Box>
      )}
      {previewImage && (
        <Box
          sx={{
            width: "100vw",
            height: "100vh",
            background: "#00000088",
            position: "absolute",
            top: "0px",
            left: "0px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 100,
          }}
          onClick={() => {
            setPriviewImage(null);
            toggleBodyOverflow();
          }}
        >
          <img
            src={previewImage}
            style={{
              width: "auto",
              height: "auto",
              maxHeight: "90vh",
            }}
          />
        </Box>
      )}
    </Box>
  );
};

export default MultiImageProduct;
