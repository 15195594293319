import { useEffect } from "react";
import { Avatar, Box, Typography } from "@mui/material";
import {
  useRecoilState,
  useRecoilValue,
  useResetRecoilState,
  useSetRecoilState,
} from "recoil";
import { DataGridTabel } from "../DataGridTabel/DataGridTabel";
import { ColumnText } from "../DataGridTabel/ColumnText";
import { HeaderGridTabel } from "../DataGridTabel/HeaderGridTabel";
import HomeWorkIcon from "@mui/icons-material/HomeWork";
import {
  dataClickItem,
  methodRequestStatus,
  openFilterState,
  updateDataState,
  selectedDateState,
  selectedDateToState,
} from "../../recoil/global";
import {
  confirmModalState,
  openModalDelete,
  openModalInfo,
} from "../../recoil/modal";
import { useState } from "react";
import { dataTabelState } from "../../recoil/Tabel";

import {
  selectedStatusState,
  selectedCategoryState,
  selectedSubCategoryState,
  selectedTagsState,
} from "../../recoil/select";
import { GetOrderPerPaymentMethod } from "../../services/api/order";

export const Tabel = () => {
  const [data, setData] = useRecoilState(dataTabelState);

  const [hasOpthions, setHasOptions] = useState(null);
  const [isOpthions, setIsOptions] = useState(null);
  const [disconted, setDisconted] = useState(null);
  const [productStat, setProductStat] = useState([]);
  const [ordersPerMonth, setOrdersPerMonth] = useState({});
  const [totalRevenue, setTotalRevenue] = useState(0);
  const [totalTransferTimes, setTotalTransferTimes] = useState(0);

  const selectedDate = useRecoilValue(selectedDateState);
  const selectedDateTo = useRecoilValue(selectedDateToState);

  const selectedStatus = useRecoilValue(selectedStatusState);
  const state = useRecoilValue(openFilterState);
  const updateData = useRecoilValue(updateDataState);
  const category = useRecoilValue(selectedCategoryState);
  const subCategory = useRecoilValue(selectedSubCategoryState);
  const tags = useRecoilValue(selectedTagsState);
  const openFilter = useRecoilValue(openFilterState);

  const resetConfirm = useResetRecoilState(confirmModalState);

  const setDataItem = useSetRecoilState(dataClickItem);
  const setOpenModal = useSetRecoilState(openModalInfo);
  const setOpenDelete = useSetRecoilState(openModalDelete);
  const setMethodRequest = useSetRecoilState(methodRequestStatus);
  useEffect(() => {
    setDataItem(null);
  }, [state]);

  const handelEdit = (data) => {
    setOpenModal(true);
    setDataItem(data);
    setMethodRequest("edit");
  };

  const handelDelete = (data) => {
    setOpenDelete(true);
    setDataItem(data);
    setMethodRequest("delete");
  };

  const columns = [
    {
      field: "day",
      headerName: "Date",
      headerClassName: "grid--header",
      flex: 1,
      renderCell: (params) => {
        return (
          <Box
            display={"flex"}
            alignItems={"center"}
            sx={{
              overflowX: "auto",
              scrollbarWidth: "none",
            }}
            gap={2}
          >
            <ColumnText
              text={params.row?.day ? params.row?.day : "N/A"}
              variant="subtitle1"
              fontWeight="bold"
            />
            {/* <ColumnText text={params.row?.job_title?.name_ar} name_ar="caption" color={"gray"} /> */}
          </Box>
        );
      },
    },
    {
      field: "credit_card",
      headerName: "Master Rafidain",
      headerClassName: "grid--header",
      flex: 1,
      renderCell: (params) => {
        return (
          <Box
            display={"flex"}
            alignItems={"center"}
            sx={{
              overflowX: "auto",
              scrollbarWidth: "none",
            }}
            gap={2}
          >
            <ColumnText
              text={
                params.row?.credit_card
                  ? `${params.row?.credit_card_count}`
                  : "0"
              }
              sx={{
                color: "#02c27a !important",
              }}
              variant="subtitle1"
              fontWeight="bold"
            />
            <Box
              sx={{
                borderRight: "1px solid #e0e0e0",
                height: "20px",
              }}
            ></Box>
            <ColumnText
              text={
                params.row?.credit_card ? `${params.row?.credit_card} ` : "0"
              }
              variant="subtitle1"
              fontWeight="bold"
              sx={{ color: "#02c27a" }}
            />
          </Box>
        );
      },
    },

    {
      field: "fast_pay",
      headerName: "Fast pay",
      headerClassName: "grid--header",
      flex: 1,
      renderCell: (params) => {
        return (
          <Box
            display={"flex"}
            alignItems={"center"}
            sx={{
              overflowX: "auto",
              scrollbarWidth: "none",
            }}
            gap={2}
          >
            <ColumnText
              text={
                params.row?.fast_pay_count
                  ? `${params.row?.fast_pay_count} `
                  : "0"
              }
              variant="subtitle1"
              fontWeight="bold"
              sx={{ color: "#ffc107 !important" }}
            />
            <Box
              sx={{
                borderRight: "1px solid #e0e0e0",
                height: "20px",
              }}
            ></Box>
            <ColumnText
              text={params.row?.fast_pay ? `${params.row?.fast_pay}` : "0"}
              variant="subtitle1"
              fontWeight="bold"
            />
          </Box>
        );
      },
    },
    {
      field: "zain_cash",
      headerName: "Zain cash",
      headerClassName: "grid--header",
      flex: 1,
      renderCell: (params) => {
        return (
          <Box
            display={"flex"}
            alignItems={"center"}
            sx={{
              overflowX: "auto",
              scrollbarWidth: "none",
            }}
            gap={2}
          >
            <ColumnText
              text={
                params.row?.zain_cash_count
                  ? `${params.row?.zain_cash_count} `
                  : "0"
              }
              variant="subtitle1"
              fontWeight="bold"
              sx={{ color: "#fc185a !important" }}
            />
            <Box
              sx={{
                borderRight: "1px solid #e0e0e0",
                height: "20px",
              }}
            ></Box>
            <ColumnText
              text={params.row?.zain_cash ? `${params.row?.zain_cash}` : "0"}
              variant="subtitle1"
              fontWeight="bold"
            />
          </Box>
        );
      },
    },
    {
      field: "cash",
      headerName: "Cash",
      headerClassName: "grid--header",
      flex: 1,
      renderCell: (params) => {
        return (
          <Box
            display={"flex"}
            alignItems={"center"}
            sx={{
              overflowX: "auto",
              scrollbarWidth: "none",
            }}
            gap={2}
          >
            <ColumnText
              text={params.row?.cash_count ? `${params.row?.cash_count} ` : "0"}
              variant="subtitle1"
              fontWeight="bold"
              sx={{ color: "#0dcaf0 !important" }}
            />
            <Box
              sx={{
                borderRight: "1px solid #e0e0e0",
                height: "20px",
              }}
            ></Box>
            <ColumnText
              text={params.row?.cash ? `${params.row?.cash}` : "0"}
              variant="subtitle1"
              fontWeight="bold"
            />
          </Box>
        );
      },
    },
    {
      field: "Wallet",
      headerName: "Wallet",
      headerClassName: "grid--header",
      flex: 1,
      renderCell: (params) => {
        return (
          <Box
            display={"flex"}
            alignItems={"center"}
            sx={{
              overflowX: "auto",
              scrollbarWidth: "none",
            }}
            gap={2}
          >
            <ColumnText
              text={
                params.row?.Wallet_count ? `${params.row?.Wallet_count} ` : "0"
              }
              variant="subtitle1"
              fontWeight="bold"
              sx={{ color: "#e0e0e0" }}
            />
            <Box
              sx={{
                borderRight: "1px solid #e0e0e0",
                height: "20px",
              }}
            ></Box>
            <ColumnText
              text={params.row?.Wallet ? `${params.row?.Wallet}` : "0"}
              variant="subtitle1"
              fontWeight="bold"
            />
          </Box>
        );
      },
    },
  ];

  const fetchData = () => {
    setData((old) => ({
      ...old,
      loading: true,
      data: [],
    }));
    console.log("selectedDate", selectedDate, selectedDateTo);

    GetOrderPerPaymentMethod(selectedDate, selectedDateTo)
      .then((res) => {
        setData((old) => ({
          ...old,
          data: res.map((item, index) => {
            return {
              ...item,
              id: index + 1,
            };
          }),
          total: res?.count,

          loading: false,
        }));
        setTotalRevenue(
          res.length > 1
            ? res[res.length - 1].cash +
                res[res.length - 1].zain_cash +
                res[res.length - 1].credit_card +
                res[res.length - 1].Wallet +
                res[res.length - 1].fast_pay +
                res[res.length - 1].fib
            : 0
        );
        setTotalTransferTimes(
          res.length > 1
            ? res[res.length - 1].cash_count +
                res[res.length - 1].zain_cash_count +
                res[res.length - 1].credit_card_count +
                res[res.length - 1].Wallet_count +
                res[res.length - 1].fast_pay_count +
                res[res.length - 1].fib_count
            : 0
        );
      })
      .catch((err) => {
        console.log(err);
        setData({ ...data, loading: false });
      });
  };

  let filters = data.filters.page || data.filters.take || data.filters.skip;

  useEffect(() => {
    fetchData();
    // console.log("data", data.data.length > 0);
  }, [
    updateData,
    selectedDate,
    selectedDateTo,
    hasOpthions,
    isOpthions,
    disconted,
  ]);

  return (
    <>
      <Box>
        <HeaderGridTabel
          title="Products"
          icon={
            <HomeWorkIcon
              size={25}
              className="color-primary"
              color={"#08001e"}
            />
          }
          fetchData={fetchData}
          isFilter={true}
          data={data?.data}
          noSearch={true}
          exel={true}
          date={true}
        />
        <Box mt={2}>
          <DataGridTabel
            columns={columns}
            data={data}
            setData={setData}
            noPagination={true}
            noBorder={true}
            small={true}
            height={"calc(80vh - 200px)"}
          />
          <Box
            sx={{
              backgroundColor: "#080d2a",
              px: 2,
              py: 2,
              mx: "24px",
              borderBottom: "1px solid #ffffff5e",
            }}
          >
            <Typography variant="h6" fontSize={"18px"} color={"#fff"}>
              Total transfer times : {totalTransferTimes} Total Amount :{" "}
              {totalRevenue} ID
            </Typography>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default Tabel;
