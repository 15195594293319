import React, { useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { Box, Grid, TextField, Typography } from "@mui/material";
import { openModalShow } from "../../../recoil/modal";
import { dataClickItem } from "../../../recoil/global";
import GeneralModal from "../../GeneralModal/GeneralModal";
import "leaflet/dist/leaflet.css";

export default function ModalShow(props) {
  const [open, setOpen] = useRecoilState(openModalShow);
  const [loading, setLoading] = useState(false);

  function formatDate(inputDate) {
    let formattedDate = `${inputDate.split("T")[0]}`;
    return formattedDate;
  }
  function formatHours(inputDate) {
    let date = new Date(inputDate);
    let hours = date.getHours();
    let minutes = String(date.getMinutes()).padStart(2, "0");
    let period = hours >= 12 ? "PM" : "AM";
    hours = hours % 12;
    hours = hours ? hours : 12;
    let formattedDate = `${hours}:${minutes} ${period}`;
    return formattedDate;
  }
  const dataItem = useRecoilValue(dataClickItem);

  return (
    <GeneralModal
      maxWidth="sm"
      open={open}
      title={"Product Serials"}
      btnFooterHide={true}
      loading={loading}
      headerBg={"linear-gradient(310deg,#7928ca,#ff0080) !important"}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "start",
          alignItems: "center",

          border: "1px solid #ffffff26",
          borderRadius: "0.375rem",
          px: 3,
          py: 2,
        }}
      >
        {props?.data?.map((item, index) => (
          <Typography
            color={"#fff"}
            py={2}
            px={2}
            sx={{
              borderTop: index !== 0 && "1px solid #ffffff26",
              width: "100%",
            }}
            key={item.id}
          >
            {item.key_serial}
          </Typography>
        ))}
      </Box>
    </GeneralModal>
  );
}
