import React from "react";
import { Button } from "@mui/material";
import * as XLSX from "xlsx";
import { SiGoogledocs } from "react-icons/si";

const ExportButton = ({ data, fileName, sx }) => {
  const exportToExcel = () => {
    const ws = XLSX.utils.json_to_sheet(data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Data");
    XLSX.writeFile(wb, `${fileName}.xlsx`);
  };

  return (
    <Button
      variant="contained"
      startIcon={<SiGoogledocs size={18} />}
      sx={{
        background: "linear-gradient(310deg, #7928ca, #ff0080) !important",
        minHeight: 40,
        height: "fit-content",
        "&:hover": {
          scale: 1.1,
        },
        mx: 1,
      }}
      onClick={() => exportToExcel()}
    >
      Export to Excel
    </Button>
  );
};

export default ExportButton;
