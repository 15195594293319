// get reports data
import { deleteData, getData, postData, putData } from "..";

export const GetReportsApi = async () => {
  try {
    const request = await getData(`/product/stats/`); // Handle the data
    return request;
  } catch (error) {
    console.error(error); // Handle the error
    throw error;
  }
};

export const GetProductPerMonth = async (from) => {
  try {
    const request = await getData(
      `/order/total_sold_per_product_per_month/?start_date=${from}&end_date=${
        new Date().toISOString().split("T")[0]
      }`
    ); // Handle the data
    return request;
  } catch (error) {
    console.error(error); // Handle the error
    throw error;
  }
};

export const GetWholesaleOrderPerMonth = async (from) => {
  try {
    const request = await getData(
      `/order/wholesale_order_per_month/?start_date=${from}&end_date=${
        new Date().toISOString().split("T")[0]
      }`
    ); // Handle the data
    return request;
  } catch (error) {
    console.error(error); // Handle the error
    throw error;
  }
};
