import React, { useEffect, useState } from "react";
import { Box, Button, Grid, TextField, Typography } from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import DeleteIcon from "@mui/icons-material/Delete";
import { Checkbox, FormControlLabel } from "@mui/material";
import { v4 as uuidv4 } from "uuid";
import * as XLSX from "xlsx";
import { toast } from "react-toastify";
import { GetProductData } from "../../services/api/product";
import DropDown from "../Others/DropDown";
import { FormControl, InputLabel, Select, MenuItem } from "@mui/material";

import { GetKeyValidityData } from "../../services/api/keyValidity";
import { GetKeyUsersCountData } from "../../services/api/keyUsersCount";

const ProductOptions = ({ optionList, setOptionList }) => {
  const [productSearch, setProductSearch] = useState("");
  const [productList, setProductList] = useState([]);
  const [productDropdown, setProductDropdown] = useState(false);
  const [loading, setLoading] = useState(false);

  const [fouces, setFouces] = useState(0);

  const [keyValidity, setKeyValidity] = useState([]);
  const [keyUesrCount, setKeyUesrCount] = useState([]);

  const fetchData = () => {
    GetKeyValidityData(0, 10000, "")
      .then((res) => {
        console.log("Dtest", res);
        setKeyValidity(res.results);
      })
      .catch((err) => {
        console.log(err);
      });

    GetKeyUsersCountData(0, 10000, "")
      .then((res) => {
        setKeyUesrCount(res.results);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    fetchData();
  }, []);

  const capitalizeFirstLetter = (string) => {
    return string?.charAt(0)?.toUpperCase() + string?.slice(1);
  };

  useEffect(() => {
    productSearch ? setLoading(true) : setLoading(false);
    productSearch
      ? GetProductData(0, 25, productSearch)
          .then((res) => {
            console.log(res);
            setProductList(res.results);
            res.results.length > 0 && setProductDropdown(true);
          })
          .catch((err) => {
            console.log(err);
          })
          .finally(() => {
            setLoading(false);
          })
      : setProductList([]);
  }, [productSearch]);

  const handleChange = (id, key, value) => {
    const list = optionList.map((item) => {
      if (item.id === id) {
        return {
          ...item,
          [key]: value,
          state: item?.state === "new" ? "new" : "updated",
        };
      }
      return item;
    });
    setOptionList(list);
  };

  const handleChange2 = (id, key, value, key2, value2, key3, value3) => {
    const list = optionList.map((item) => {
      if (item.id === id) {
        return {
          ...item,
          [key]: value,
          [key2]: value3,
          [key3]: value3,

          state: item?.state === "new" ? "new" : "updated",
        };
      }
      return item;
    });
    setOptionList(list);
  };

  const handleDelete = (id) => {
    const list = optionList.map((item) => {
      if (item.id === id && item?.state === "new") {
        return { ...item, state: "null" };
      } else if (item.id === id) {
        return { ...item, state: "deleted" };
      }
      return item;
    });
    setOptionList(list);
  };

  return (
    <Box>
      <Grid container>
        {optionList
          .filter((item) => item.state !== "deleted" && item.state !== "null")
          .map((item, index) => (
            <Grid
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
                width: "100%",
              }}
              item
              xs={12}
              sm={12}
              key={item.id}
            >
              <Grid sm={4} xs={12}>
                <FormControl
                  sx={{
                    mx: 2,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "start",

                    position: "relative",
                  }}
                  noValidate
                >
                  <Typography color={"#fff"}>Product</Typography>

                  <TextField
                    fullWidth
                    margin="small"
                    size="small"
                    id="outlined-basic"
                    placeholder="Product"
                    value={item.search}
                    onChange={(e) => {
                      handleChange(item.id, "search", e.target.value);
                      setProductSearch(e.target.value);
                      setFouces(item.id);
                    }}
                  />

                  <DropDown
                    open={(productDropdown || loading) && fouces === item.id}
                    setOpen={setProductDropdown}
                  >
                    {loading ? (
                      <Typography
                        component="p"
                        variant="p"
                        fontWeight={500}
                        sx={{
                          mb: 1,
                          background: "#08001e88",
                          color: "#fff",
                          borderRadius: 10,
                          px: 1,
                        }}
                      >
                        Loading...
                      </Typography>
                    ) : (
                      <Box
                        sx={{
                          maxHeight: "300px",
                          minWidth: "100%",
                          overflowY: "auto",
                        }}
                      >
                        {productList &&
                          productList &&
                          productList.length > 0 &&
                          productList.map((item2, index) => {
                            return (
                              <Typography
                                key={item.id + "-" + item2.id}
                                sx={{
                                  color: "#fff",

                                  cursor: "pointer",
                                  padding: "10px",
                                  borderBottom: "1px solid #ffffff26",
                                  "&:hover": {
                                    backgroundColor: "#ffffff26",
                                  },
                                }}
                                onClick={() => {
                                  console.log("item2", item2);
                                  handleChange2(
                                    item.id,
                                    "product",
                                    item2.id,
                                    "name",
                                    item2.name,
                                    "search",
                                    item2.name +
                                      " - " +
                                      item2?.keys_users_count?.count ||
                                      "N/A" +
                                        " / user - " +
                                        item2?.keys_validity?.validity ||
                                      "N/A" +
                                        " / " +
                                        item2?.keys_validity?.validity_unit ||
                                      "N/A"
                                  );
                                  setProductDropdown(false);
                                  setFouces(null);
                                  setProductSearch("");
                                }}
                              >
                                {item2.name +
                                  " / " +
                                  item2?.keys_users_count?.count ||
                                  "N/A" +
                                    " - user / " +
                                    item2?.keys_validity?.validity ||
                                  "N/A" +
                                    "  - " +
                                    item2?.keys_validity?.validity_unit ||
                                  "N/A"}
                              </Typography>
                            );
                          })}
                      </Box>
                    )}
                  </DropDown>
                </FormControl>
              </Grid>
              <Grid
                sx={{
                  mb: 1,
                  gap: 2,
                }}
                sm={8}
                xs={12}
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "100%",
                    mt: 3,
                  }}
                  noValidate
                >
                  <FormControl fullWidth margin="normal" sx={{ minWidth: 150 }}>
                    <InputLabel
                      id="status-label"
                      sx={{
                        background: "#fff",
                        top: -15,
                        left: -10,
                        fontSize: "1.25rem",
                      }}
                    >
                      Select Key Validity
                    </InputLabel>
                    <Select
                      size="small"
                      sx={{ minWidth: 150 }}
                      labelId="status-label"
                      id="status-select"
                      onChange={(e) => {
                        handleChange(item.id, "keys_validity", e.target.value);
                      }}
                      value={item.keys_validity}
                    >
                      {keyValidity?.map((status, index) => (
                        <MenuItem key={index} value={status.id}>
                          {status.validity}{" "}
                          {capitalizeFirstLetter(status.validity_unit)}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "100%",
                    mt: 3,
                  }}
                  noValidate
                >
                  <FormControl fullWidth margin="normal" sx={{ minWidth: 150 }}>
                    <InputLabel
                      id="status-label"
                      sx={{
                        background: "#fff",
                        top: -15,
                        left: -10,
                        fontSize: "1.25rem",
                      }}
                    >
                      Select Key Validity
                    </InputLabel>
                    <Select
                      size="small"
                      sx={{ minWidth: 150 }}
                      labelId="status-label"
                      id="status-select"
                      onChange={(e) => {
                        handleChange(
                          item.id,
                          "keys_users_count",
                          e.target.value
                        );
                      }}
                      value={item.keys_users_count}
                    >
                      {keyUesrCount?.map((status, index) => (
                        <MenuItem key={index} value={status.id}>
                          {status.count} / Users
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "100%",
                    mt: 4,
                  }}
                  noValidate
                >
                  <Button
                    margin="normal"
                    component="label"
                    variant="outlined"
                    sx={{
                      width: "90%",
                    }}
                    onClick={() => {
                      handleDelete(item.id);
                    }}
                  >
                    Delete
                  </Button>
                </Box>
              </Grid>
            </Grid>
          ))}
      </Grid>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
          width: "90%",
          mx: "auto",

          mt: 3,
        }}
        noValidate
      >
        <Button
          margin="normal"
          component="label"
          variant="outlined"
          sx={{
            width: "90%",
            my: 2,
          }}
          onClick={() => {
            setOptionList([
              ...optionList,
              {
                id: uuidv4(),
                name: "",
                search: "",
                keys_validity: keyValidity.length > 0 && keyValidity[0].id,
                keys_users_count: keyUesrCount.length > 0 && keyUesrCount[0].id,
                product: 0,
                state: "new",
              },
            ]);
          }}
        >
          New Option
        </Button>
      </Box>
    </Box>
  );
};

export default ProductOptions;
