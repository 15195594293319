import React, { useEffect, useState } from "react";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import {
  Box,
  Button,
  Grid,
  TextField,
  Typography,
  TextareaAutosize,
  IconButton,
} from "@mui/material";
import { toast } from "react-toastify";
import { openModalInfo } from "../../../recoil/modal";
import {
  dataClickItem,
  methodRequestStatus,
  updateDataState,
  wholesaleTypesState,
} from "../../../recoil/global";
import {
  dataSelectCityState,
  dataSelectProvinceState,
  selectedJobFieldState,
  selectedTagsState,
  selectedKeyValidityState,
  selectedKeyUserCountState,
  selectedCategoryState,
  selectedSubCategoryState,
  selectedCompanyState,
} from "../../../recoil/select";
import GeneralModal from "../../GeneralModal/GeneralModal";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import "leaflet/dist/leaflet.css";
import { posisionMapState } from "../../../recoil/location";
import {
  PostProduct,
  PostProductKeysImages,
  PatchProductKeysImages,
  PutProduct,
  PatchProduct,
  GetProductByIdData,
} from "../../../services/api/product";
import SelectTags from "../../Selects/SelectTags";
import SelectKeyUserCount from "../../Selects/SelectKeyUserCount";
import SelectKeyValidity from "../../Selects/SelectKeyUserValidity";
import SelectCategory from "../../Selects/SelectCategory";
import SelectSubCategory from "../../Selects/SelectSubCategory";
import SelectCompany from "../../Selects/SelectCompany";
import DeleteIcon from "@mui/icons-material/Delete";
import { Girl } from "@mui/icons-material";
import MultiImage from "../../Others/MultiImage";
import ProductSection from "../../Others/ProductSection";
import MultiText from "../../Others/MultiText";
import axios from "axios";
import MultiWholesale from "../../Others/MultiWholesale";
import SellIcon from "@mui/icons-material/Sell";
import KeyIcon from "@mui/icons-material/Key";
import LanguageIcon from "@mui/icons-material/Language";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import MultiImageProduct from "../../Others/MultiImageProduct";
import ProductOptions from "../../Others/ProductOptions";
import { useLocation, useNavigate } from "react-router-dom";

export default function ModalInfo() {
  const [selectedTags, setSelectedTags] = useRecoilState(selectedTagsState);
  const [selectedKeyUserCount, setSelectedKeyUserCount] = useRecoilState(
    selectedKeyUserCountState
  );
  const [selectedKeyValidity, setSelectedKeyValidity] = useRecoilState(
    selectedKeyValidityState
  );
  const [selectedCategory, setSelectedCategory] = useRecoilState(
    selectedCategoryState
  );
  const [selectedSubCategory, setSelectedSubCategory] = useRecoilState(
    selectedSubCategoryState
  );
  const [selectedCompany, setSelectedCompany] =
    useRecoilState(selectedCompanyState);
  const [open, setOpen] = useRecoilState(openModalInfo);
  const [showPassword, setShowPassword] = useState(false);

  const [sendData, setSendData] = useState(false);
  const [imgList, setImgList] = useState([]);
  const [sectionList, setSectionList] = useState([]);
  const [keyList, setKeyList] = useState([]);
  const [originalSections, setOriginalSections] = useState([]);
  const [originalKeys, setOriginalKeys] = useState([]);
  const [originalImages, setOriginalImages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [wholesaleTypes, setWholesaleTypes] =
    useRecoilState(wholesaleTypesState);
  const [wholesalePrices, setWholesalePrices] = useState([]);

  const [productBase64, setProductBase64] = useState([]);
  const [taps, setTaps] = useState(false);
  const [published, setPublished] = useState(false);

  const [optionList, setOptionList] = useState([]);
  const [opthions2, setOpthions2] = useState([]);

  const [dataItem, setDataItem] = useRecoilState(dataClickItem);
  const [methodRequest, setMethodRequest] = useRecoilState(methodRequestStatus);

  const setUpdateData = useSetRecoilState(updateDataState);

  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const id = searchParams.get("id");

  // const convertToBase64 = (file) => {
  //   const reader = new FileReader();
  //   reader.readAsDataURL(file);
  //   reader.onloadend = () => {
  //     setBase64(reader.result);
  //   };
  //   reader.onerror = (error) => {
  //     console.error("Error converting file to base64:", error);
  //   };
  // };

  // useEffect(() => {
  //   dataItem?.images?.length > 0 &&
  //     setProductBase64(
  //       dataItem?.images.map((item) => {
  //         return item.image_file;
  //       })
  //     );
  // }, [dataItem]);

  useEffect(() => {
    if (id) {
      const loading = toast.loading("Loading...");
      GetProductByIdData(id)
        .then((res) => {
          setDataItem(res);
          setPublished(res.status === "active" ? true : false);
        })
        .catch((err) => {
          console.error(err);
          toast.error("Product not found");
        })
        .finally(() => toast.dismiss(loading));
    }
  }, [id]);

  useEffect(() => {
    const setPothOpthions = (dataItem) => {
      setOptionList(
        dataItem?.options_readonly.map((item) => {
          return {
            ...item,
            keys_users_count: item?.keys_users_count?.id,
            keys_validity: item?.keys_validity?.id,
          };
        })
      );
      setOpthions2(
        dataItem?.options_readonly.map((item) => {
          return {
            ...item,
            keys_users_count: item?.keys_users_count?.id,
            keys_validity: item?.keys_validity?.id,
          };
        })
      );
    };

    if (open) {
      dataItem ? setSelectedTags(dataItem?.tag) : setSelectedTags([]);
      dataItem?.keys_users_count?.count
        ? setSelectedKeyUserCount(dataItem?.keys_users_count?.count)
        : setSelectedKeyUserCount(0);
      dataItem?.keys_validity?.validity
        ? setSelectedKeyValidity(dataItem?.keys_validity.validity)
        : setSelectedKeyValidity(0);
      dataItem?.category
        ? setSelectedCategory(dataItem?.category)
        : setSelectedCategory(0);
      dataItem?.sub_category
        ? setSelectedSubCategory(dataItem?.sub_category)
        : setSelectedSubCategory(0);
      dataItem?.company
        ? setSelectedCompany(dataItem?.company)
        : setSelectedCompany(0);
      dataItem?.status === "active" ? setPublished(true) : setPublished(false);

      dataItem?.images ? setImgList(dataItem?.images) : setImgList([]);
      dataItem?.images ? setOriginalImages(dataItem?.images) : setImgList([]);
      dataItem?.sections
        ? setSectionList(dataItem?.sections)
        : setSectionList([]);
      dataItem?.sections
        ? setOriginalSections(dataItem?.sections)
        : setSectionList([]);
      dataItem?.product_keys
        ? dataItem.product_keys.length > 0 && setKeyList(dataItem?.product_keys)
        : setKeyList([]);
      dataItem?.product_keys
        ? dataItem?.product_keys?.length > 0 &&
          setOriginalKeys(dataItem?.product_keys)
        : setKeyList([]);

      dataItem?.tag ? setTaps(dataItem?.tag) : setTaps("No Tag");

      dataItem?.options
        ? dataItem.options.length > 0 && setOptionList(dataItem?.options)
        : dataItem?.options_readonly
        ? dataItem.options_readonly.length > 0 && setPothOpthions(dataItem)
        : setOptionList([]);

      let prices = [];
      dataItem?.wholesale_pricings
        ? dataItem?.wholesale_pricings?.length > 0 &&
          wholesaleTypes.map((item) => {
            prices.push({
              ...item,
              new:
                !dataItem.wholesale_pricings.filter(
                  (it) => it.wholesale_user_type === item.id
                )?.length > 0,
              old_id:
                dataItem.wholesale_pricings.find(
                  (it) => it.wholesale_user_type === item.id
                )?.id || null,
              prices:
                dataItem.wholesale_pricings.find(
                  (it) => it.wholesale_user_type === item.id
                )?.price || 0,
            });
            prices?.length === wholesaleTypes?.length &&
              setWholesalePrices(prices);
          })
        : wholesaleTypes.map((item) => {
            prices.push({
              ...item,
              new: true,
              prices: 0,
            });
            prices?.length === wholesaleTypes?.length &&
              setWholesalePrices(prices);
          });
    }
  }, [dataItem, open]);

  useEffect(() => {
    console.log("wholesalePrices", wholesalePrices);
  }, [wholesalePrices]);

  // send request
  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);

    setLoading(true);
    // function api request
    if (!data.get("name") || !data.get("name_ar") || data.get("price")) {
      !data.get("name") && toast.error(`name: this filed is required`);
      !data.get("name_ar") && toast.error(`name_ar: this filed is required`);
      !data.get("price") && toast.error(`price: this filed is required`);
    }

    if (methodRequest === "add") {
      // data form
      const formData = new FormData();

      console.log("selectedKeyUserCount", selectedKeyUserCount);

      const infoData = {
        seq: data.get("seq"),
        name: data.get("name"),
        name_ar: data.get("name_ar"),
        description: data.get("description"),
        description_ar: data.get("description_ar"),
        cashback_amount: data.get("cashback"),
        qty: data.get("quantity"),
        SKU_code: data.get("SKU"),
        price: data.get("price"),
        old_price: data.get("old_price"),
        tutorial: data.get("tutorial"),
        download_link: data.get("download_link"),
        tag: selectedTags === "no Tag" ? null : selectedTags,
        keys_users_count:
          selectedKeyUserCount === 0 ? null : selectedKeyUserCount,
        keys_validity: selectedKeyValidity === 0 ? null : selectedKeyValidity,
        category: selectedCategory,
        sub_category: selectedSubCategory,
        company: selectedCompany,
        number_of_keys_to_send_notification:
          data.get("number_of_keys_to_send_notification") || 5,
        status: published ? "active" : "inactive",
      };

      !infoData.keys_users_count && delete infoData.keys_users_count;
      !infoData.keys_validity && delete infoData.keys_validity;
      !infoData.download_link && delete infoData.download_link;
      !infoData.tutorial && delete infoData.tutorial;
      !infoData.seq && delete infoData.seq;
      !infoData.description && delete infoData.description;
      !infoData.description_ar && delete infoData.description_ar;
      !infoData.cashback_amount && delete infoData.cashback_amount;
      !infoData.SKU_code && delete infoData.SKU_code;
      !infoData.old_price && delete infoData.old_price;
      !infoData.category && delete infoData.category;
      !infoData.sub_category && delete infoData.sub_category;
      !infoData.company && delete infoData.company;
      !infoData.qty && delete infoData.qty;

      // function api request
      PostProductKeysImages(
        infoData,
        imgList,
        keyList,
        sectionList,
        wholesalePrices,
        optionList,
        setUpdateData
      )
        .then((res) => {
          console.log(res);
          toast.success("Added successfully");
          handleClose();
          setSelectedCategory(0);
          setSelectedSubCategory(0);
          setSelectedCompany(0);
          setSelectedKeyUserCount(0);
          setSelectedKeyValidity(0);
          setImgList([]);
          setSectionList([]);
          setKeyList([]);
          setSendData(true);
          setTimeout(() => {
            setUpdateData(Math.random());
          }, 1000);
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          if (err.response) {
            const errorData = err.response.data;

            if (typeof errorData === "object" && !Array.isArray(errorData)) {
              // If error data is an object, iterate over its keys
              Object.keys(errorData).forEach((key) => {
                const messages = errorData[key];
                if (Array.isArray(messages)) {
                  messages.forEach((message) => {
                    toast.error(key + ": " + message);
                  });
                } else {
                  toast.error(key + ": " + messages);
                }
              });
            } else if (typeof errorData.message === "string") {
              // If there's a 'message' field, display it directly
              toast.error(errorData.message);
            } else {
              // Handle other types of error data as needed
              toast.error("Unexpected error occurred");
            }
          } else {
            // If there's no response from the server
            toast.error("Check your internet connection");
          }
        });
    } else {
      const data = new FormData(event.currentTarget);
      // data form
      const formData = new FormData();
      console.log("function execute");
      const infoData = {
        seq: data.get("seq"),
        name: data.get("name"),
        name_ar: data.get("name_ar"),
        description: data.get("description"),
        description_ar: data.get("description_ar"),
        cashback_amount: data.get("cashback"),
        qty: data.get("quantity"),
        SKU_code: data.get("SKU"),
        price: data.get("price"),
        old_price: data.get("old_price"),
        tutorial: data.get("tutorial"),
        download_link: data.get("download_link"),
        tag: selectedTags === "no Tag" ? null : selectedTags,
        keys_users_count: selectedKeyUserCount ? selectedKeyUserCount : null,
        keys_validity: selectedKeyValidity ? selectedKeyValidity : null,
        category: selectedCategory,
        sub_category: selectedSubCategory,
        company: selectedCompany,
        number_of_keys_to_send_notification:
          data.get("number_of_keys_to_send_notification") || 5,
        status: published ? "active" : "inactive",
      };
      // remove all null data
      !infoData.keys_users_count && delete infoData.keys_users_count;
      !infoData.keys_validity && delete infoData.keys_validity;
      !infoData.download_link && delete infoData.download_link;
      !infoData.tutorial && delete infoData.tutorial;
      !infoData.seq && delete infoData.seq;
      !infoData.description && delete infoData.description;
      !infoData.description_ar && delete infoData.description_ar;
      !infoData.cashback_amount && delete infoData.cashback_amount;
      !infoData.SKU_code && delete infoData.SKU_code;
      !infoData.old_price && delete infoData.old_price;
      !infoData.category && delete infoData.category;
      !infoData.sub_category && delete infoData.sub_category;
      !infoData.company && delete infoData.company;
      !infoData.qty && delete infoData.qty;

      const idItem = dataItem?.id;
      PatchProductKeysImages(
        idItem,
        infoData,
        imgList,
        keyList,
        sectionList,
        wholesalePrices,
        optionList,
        setUpdateData
      )
        .then((res) => {
          console.log(res);
          toast.success("Edit successfully");
          setSendData(true);
          handleClose();
          setSelectedCategory(0);
          setSelectedSubCategory(0);
          setSelectedCompany(0);
          setSelectedKeyUserCount(0);
          setSelectedKeyValidity(0);
          setImgList([]);
          setSectionList([]);
          setKeyList([]);
          setSendData(true);
          setTimeout(() => {
            setUpdateData(Math.random());
          }, 1000);
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          if (err.response) {
            const errorData = err.response.data;

            if (typeof errorData === "object" && !Array.isArray(errorData)) {
              // If error data is an object, iterate over its keys
              Object.keys(errorData).forEach((key) => {
                const messages = errorData[key];
                if (Array.isArray(messages)) {
                  messages.forEach((message) => {
                    toast.error(key + ": " + message);
                  });
                } else {
                  toast.error(key + ": " + messages);
                }
              });
            } else if (typeof errorData.message === "string") {
              // If there's a 'message' field, display it directly
              toast.error(errorData.message);
            } else {
              // Handle other types of error data as needed
              toast.error("Unexpected error occurred");
            }
          } else {
            // If there's no response from the server
            toast.error("Check your internet connection");
          }
        });
    }
  };

  useEffect(() => {
    const updateOptionList = async () => {
      for (const item of opthions2) {
        try {
          const res = await GetProductByIdData(item.product);
          setOptionList((prevOptionList) =>
            prevOptionList.map((it) => {
              if (it.product === item.product) {
                return {
                  ...it,
                  name: res.name,
                  id: it.id,
                  search:
                    res.name + " - " + res?.keys_users_count?.count ||
                    "N/A" + " / user - " + res?.keys_validity?.validity ||
                    "N/A" + " / " + res?.keys_validity?.validity_unit ||
                    "N/A",
                };
              } else {
                return it;
              }
            })
          );
        } catch (err) {
          console.error(err);
        }
      }
    };
    updateOptionList();
  }, [opthions2]);

  // close modal
  const handleClose = () => {
    !id && setOpen(false);
    setDataItem(null);
    setImgList([]);
    setSectionList([]);
    setKeyList([]);
    setSelectedTags([]);
    setSelectedKeyUserCount(0);
    setSelectedKeyValidity(0);
    setSelectedCategory(0);
    setSelectedSubCategory(0);
    id && navigate(-1);
  };

  // hide and show password
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);
  return (
    <GeneralModal
      maxWidth="sm"
      style={{
        ".MuiDialog-paperWidthSm": {
          maxWidth: "100vw !important",
          maxHeight: "100vh !important",
          height: "100vh !important",
          margin: "0px !important",
          borderRadius: "0px",
          backgroundColor: "#0f1535 !important",
        },
        h2: {
          background: "linear-gradient(310deg, #7928ca, #ff0080) !important",
          color: "#fff",
          borderRadius: "0px",
        },
      }}
      icon={() => {
        return <></>;
      }}
      open={open}
      onSubmit={handleSubmit}
      loading={loading}
      onClose={() => {
        setImgList([]);
        setSectionList([]);
        setKeyList([]);
        setSelectedTags([]);
        setSelectedKeyUserCount(0);
        setSelectedKeyValidity(0);
        setSelectedCategory(0);
        setSelectedSubCategory(0);
        setLoading(false);
        id && navigate(-1);
      }}
    >
      {dataItem && (
        <Box sx={{ mx: 1, my: 2 }}>
          <Grid container>
            <Grid container sm={8}>
              <Grid item xs={12} sm={6} px={1}>
                <Box
                  noValidate
                  sx={{
                    background: "#0a0e2a",
                    p: 2,
                    borderRadius: "0.375rem",
                  }}
                >
                  <Typography color={"#fff"} fontSize={"1.25rem"}>
                    Product Title
                  </Typography>
                  <TextField
                    size="small"
                    margin="normal"
                    fullWidth
                    id="name"
                    placeholder="write title here...."
                    name="name"
                    autoComplete="name"
                    autoFocus
                    defaultValue={dataItem?.name}
                  />
                  <Typography color={"#fff"} mt={2} fontSize={"1.25rem"}>
                    Mini Description
                  </Typography>
                  <TextField
                    size="small"
                    margin="normal"
                    fullWidth
                    id="description"
                    placeholder="write description here...."
                    name="description"
                    autoComplete="Description"
                    autoFocus
                    defaultValue={dataItem?.description}
                    multiline
                    rows={5}
                    mb={2}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} sm={6} px={1}>
                <Box
                  noValidate
                  sx={{
                    background: "#0a0e2a",
                    p: 2,
                    borderRadius: "0.375rem",
                  }}
                >
                  <Typography
                    color={"#fff"}
                    fontSize={"1.25rem"}
                    width={"100%"}
                    textAlign={"right"}
                  >
                    اسم المنتج
                  </Typography>
                  <TextField
                    size="small"
                    margin="normal"
                    fullWidth
                    id="name_ar"
                    name="name_ar"
                    autoComplete="name_ar"
                    textAlign={"right"}
                    placeholder="اكتب العنوان هنا...."
                    dir="rtl"
                    defaultValue={dataItem?.name_ar}
                  />
                  <Typography
                    color={"#fff"}
                    mt={2}
                    fontSize={"1.25rem"}
                    width={"100%"}
                    textAlign={"right"}
                  >
                    وصف قصير
                  </Typography>

                  <TextField
                    size="small"
                    margin="normal"
                    fullWidth
                    id="description_ar"
                    placeholder="اكتب الوصف هنا...."
                    name="description_ar"
                    autoComplete="Description_ar"
                    defaultValue={dataItem?.description_ar}
                    multiline
                    dir="rtl"
                    rows={5}
                    mb={2}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} sm={12} px={1} mt={2}>
                <Box
                  sx={{
                    background: "#0a0e2a",
                    p: 2,
                    borderRadius: "0.375rem",
                  }}
                >
                  <Typography
                    color={"#fff"}
                    mb={3}
                    fontSize={"1.25rem"}
                    fontWeight={500}
                  >
                    Inventory
                  </Typography>
                  <Grid container>
                    <Grid sm={2.5} px={1}>
                      <Box
                        border={"1px solid #ffffff26"}
                        overflow={"hidden"}
                        borderRadius={"0.375rem"}
                        mb={4}
                      >
                        <Button
                          sx={{
                            px: 3,
                            py: 1,
                            borderBottom: "1px solid #ffffff26",
                            svg: {
                              fill: "#d3d7dc !important",
                              width: "20px",
                              height: "20px",
                            },
                            width: "100%",
                            borderRadius: "0px",
                            display: "flex",
                            justifyContent: "start",
                            alignItems: "center",
                            "&:hover": {
                              background: "#0d6efd",
                            },
                            background:
                              taps === "pricing" ? "#0d6efd" : "transparent",
                            h5: {
                              color: taps === "pricing" ? "#fff" : "#d3d7dc",
                            },
                          }}
                          onClick={() => setTaps("pricing")}
                        >
                          <SellIcon />
                          <Typography
                            variant="h5"
                            fontSize={"14px"}
                            mx={1}
                            textTransform={"capitalize"}
                          >
                            Pricing
                          </Typography>
                        </Button>
                        <Button
                          sx={{
                            px: 3,
                            py: 1,
                            borderBottom: "1px solid #ffffff26",
                            svg: {
                              fill: "#d3d7dc !important",
                              width: "20px",
                              height: "20px",
                            },
                            width: "100%",
                            borderRadius: "0px",
                            display: "flex",
                            justifyContent: "start",
                            alignItems: "center",
                            "&:hover": {
                              background: "#0d6efd",
                            },
                            background:
                              taps === "key" ? "#0d6efd" : "transparent",
                            h5: {
                              color: taps === "key" ? "#fff" : "#d3d7dc",
                            },
                          }}
                          onClick={() => setTaps("key")}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            class="bi bi-box-seam-fill"
                            viewBox="0 0 16 16"
                          >
                            <path
                              fill-rule="evenodd"
                              d="M15.528 2.973a.75.75 0 0 1 .472.696v8.662a.75.75 0 0 1-.472.696l-7.25 2.9a.75.75 0 0 1-.557 0l-7.25-2.9A.75.75 0 0 1 0 12.331V3.669a.75.75 0 0 1 .471-.696L7.443.184l.01-.003.268-.108a.75.75 0 0 1 .558 0l.269.108.01.003zM10.404 2 4.25 4.461 1.846 3.5 1 3.839v.4l6.5 2.6v7.922l.5.2.5-.2V6.84l6.5-2.6v-.4l-.846-.339L8 5.961 5.596 5l6.154-2.461z"
                            />
                          </svg>
                          <Typography
                            variant="h5"
                            fontSize={"14px"}
                            mx={1}
                            textTransform={"capitalize"}
                          >
                            Key Validation
                          </Typography>
                        </Button>
                        <Button
                          sx={{
                            px: 3,
                            py: 1,
                            borderBottom: "1px solid #ffffff26",
                            svg: {
                              fill: "#d3d7dc !important",
                              width: "20px",
                              height: "20px",
                            },
                            width: "100%",
                            borderRadius: "0px",
                            display: "flex",
                            justifyContent: "start",
                            alignItems: "center",
                            "&:hover": {
                              background: "#0d6efd",
                            },
                            background:
                              taps === "addKey" ? "#0d6efd" : "transparent",
                            h5: {
                              color: taps === "addKey" ? "#fff" : "#d3d7dc",
                            },
                          }}
                          onClick={() => setTaps("addKey")}
                        >
                          <KeyIcon />
                          <Typography
                            variant="h5"
                            fontSize={"14px"}
                            mx={1}
                            textTransform={"capitalize"}
                          >
                            Add Key
                          </Typography>
                        </Button>
                        <Button
                          sx={{
                            px: 3,
                            py: 1,
                            borderBottom: "1px solid #ffffff26",
                            svg: {
                              fill: "#d3d7dc !important",
                              width: "20px",
                              height: "20px",
                            },
                            width: "100%",
                            borderRadius: "0px",
                            display: "flex",
                            justifyContent: "start",
                            alignItems: "center",
                            "&:hover": {
                              background: "#0d6efd",
                            },
                            background:
                              taps === "wholesale" ? "#0d6efd" : "transparent",
                            h5: {
                              color: taps === "wholesale" ? "#fff" : "#d3d7dc",
                            },
                          }}
                          onClick={() => setTaps("wholesale")}
                        >
                          <LanguageIcon />
                          <Typography
                            variant="h5"
                            fontSize={"14px"}
                            mx={1}
                            textTransform={"capitalize"}
                          >
                            Wholesale Prices
                          </Typography>
                        </Button>
                        <Button
                          sx={{
                            px: 3,
                            py: 1,
                            borderBottom: "1px solid #ffffff26",
                            svg: {
                              fill: "#d3d7dc !important",
                              width: "20px",
                              height: "20px",
                            },
                            width: "100%",
                            borderRadius: "0px",
                            display: "flex",
                            justifyContent: "start",
                            alignItems: "center",
                            "&:hover": {
                              background: "#0d6efd",
                            },
                            background:
                              taps === "attributes" ? "#0d6efd" : "transparent",
                            h5: {
                              color: taps === "attributes" ? "#fff" : "#d3d7dc",
                            },
                          }}
                          onClick={() => setTaps("attributes")}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            class="bi bi-hdd-rack-fill"
                            viewBox="0 0 16 16"
                          >
                            <path d="M2 2a2 2 0 0 0-2 2v1a2 2 0 0 0 2 2h1v2H2a2 2 0 0 0-2 2v1a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2v-1a2 2 0 0 0-2-2h-1V7h1a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2zm.5 3a.5.5 0 1 1 0-1 .5.5 0 0 1 0 1m2 0a.5.5 0 1 1 0-1 .5.5 0 0 1 0 1m-2 7a.5.5 0 1 1 0-1 .5.5 0 0 1 0 1m2 0a.5.5 0 1 1 0-1 .5.5 0 0 1 0 1M12 7v2H4V7z" />
                          </svg>
                          <Typography
                            variant="h5"
                            fontSize={"14px"}
                            mx={1}
                            textTransform={"capitalize"}
                          >
                            Attributes
                          </Typography>
                        </Button>

                        <Button
                          sx={{
                            px: 3,
                            py: 1,
                            svg: {
                              fill: "#d3d7dc !important",
                              width: "20px",
                              height: "20px",
                            },
                            width: "100%",
                            borderRadius: "0px",
                            display: "flex",
                            justifyContent: "start",
                            alignItems: "center",
                            "&:hover": {
                              background: "#0d6efd",
                            },
                            background:
                              taps === "sections" ? "#0d6efd" : "transparent",
                            h5: {
                              color: taps === "sections" ? "#fff" : "#d3d7dc",
                            },
                          }}
                          onClick={() => setTaps("sections")}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            class="bi bi-handbag-fill"
                            viewBox="0 0 16 16"
                          >
                            <path d="M8 1a2 2 0 0 0-2 2v2H5V3a3 3 0 1 1 6 0v2h-1V3a2 2 0 0 0-2-2M5 5H3.36a1.5 1.5 0 0 0-1.483 1.277L.85 13.13A2.5 2.5 0 0 0 3.322 16h9.355a2.5 2.5 0 0 0 2.473-2.87l-1.028-6.853A1.5 1.5 0 0 0 12.64 5H11v1.5a.5.5 0 0 1-1 0V5H6v1.5a.5.5 0 0 1-1 0z" />
                          </svg>
                          <Typography
                            variant="h5"
                            fontSize={"14px"}
                            mx={1}
                            textTransform={"capitalize"}
                          >
                            Add sections
                          </Typography>
                        </Button>
                      </Box>
                    </Grid>
                    <Grid item sm={9.5} px={1}>
                      <Box
                        display={"flex"}
                        sx={{
                          gap: "15px",
                          visibility: taps === "pricing" ? "visible" : "hidden",
                          position:
                            taps === "pricing" ? "relative" : "absolute",
                        }}
                      >
                        <Box
                          sx={{
                            width: "100%",
                          }}
                        >
                          <Typography color={"#fff"}>price</Typography>
                          <TextField
                            size="small"
                            margin="normal"
                            fullWidth
                            id="price"
                            placeholder="$$$"
                            name="price"
                            autoComplete="price"
                            sx={{
                              mt: 1,
                            }}
                            defaultValue={dataItem?.price}
                            type="number"
                            inputMode="numeric"
                            onWheel={(e) => e.target.blur()}
                          />
                        </Box>
                        <Box
                          sx={{
                            width: "100%",
                          }}
                        >
                          <Typography color={"#fff"}>Old price</Typography>

                          <TextField
                            size="small"
                            margin="normal"
                            fullWidth
                            id="old_price"
                            placeholder="$$$"
                            name="old_price"
                            autoComplete="old_price"
                            defaultValue={dataItem?.old_price}
                            inputMode="numeric"
                            type="number"
                            onWheel={(e) => e.target.blur()}
                            sx={{
                              mt: 1,
                            }}
                          />
                        </Box>
                        <Box
                          sx={{
                            width: "100%",
                          }}
                        >
                          <Typography color={"#fff"}>Cashback</Typography>

                          <TextField
                            size="small"
                            margin="normal"
                            fullWidth
                            id="cashback"
                            placeholder="$$$"
                            name="cashback"
                            autoComplete="cashback"
                            defaultValue={dataItem?.cashback_amount}
                            type="number"
                            onWheel={(e) => e.target.blur()}
                            inputMode="numeric"
                            sx={{
                              mt: 1,
                            }}
                          />
                        </Box>
                      </Box>
                      <Box
                        sx={{
                          visibility: taps === "key" ? "visible" : "hidden",
                          position: taps === "key" ? "relative" : "absolute",
                        }}
                      >
                        <Typography
                          textTransform={"capitalize"}
                          fontSize={"1.1rem"}
                          mb={2}
                          color={"#fff"}
                          display={"block"}
                        >
                          Users and Key validation
                        </Typography>
                        <Box
                          display={"flex"}
                          sx={{
                            gap: "15px",
                            mt: 1,
                          }}
                        >
                          <Box
                            sx={{
                              width: "100%",
                            }}
                          >
                            <SelectKeyUserCount />
                          </Box>
                          <Box
                            sx={{
                              width: "100%",
                            }}
                          >
                            <SelectKeyValidity />
                          </Box>
                        </Box>
                        <Box
                          display={"flex"}
                          sx={{
                            gap: "15px",
                            mt: 1,
                          }}
                        >
                          <Box
                            sx={{
                              width: "100%",
                            }}
                          >
                            <ProductOptions
                              optionList={optionList}
                              setOptionList={setOptionList}
                            />
                          </Box>
                        </Box>
                      </Box>
                      <Box
                        sx={{
                          visibility: taps === "addKey" ? "visible" : "hidden",
                          position: taps === "addKey" ? "relative" : "absolute",
                        }}
                      >
                        <Typography
                          textTransform={"capitalize"}
                          fontSize={"1.1rem"}
                          mb={1}
                          color={"#fff"}
                          display={"block"}
                        >
                          Add Key
                        </Typography>
                        <Box
                          display={"flex"}
                          sx={{
                            gap: "15px",
                            mt: "-10px",
                            maxHeight: "400px",
                            overflowY: "auto",
                          }}
                        >
                          <Box
                            sx={{
                              width: "100%",
                            }}
                          >
                            <MultiText
                              keyList={keyList}
                              setKeyList={setKeyList}
                            />
                          </Box>
                        </Box>
                      </Box>
                      <Box
                        sx={{
                          visibility:
                            taps === "wholesale" ? "visible" : "hidden",
                          position:
                            taps === "wholesale" ? "relative" : "absolute",
                        }}
                      >
                        <Typography
                          textTransform={"capitalize"}
                          fontSize={"1.1rem"}
                          mb={1}
                          color={"#fff"}
                          display={"block"}
                        >
                          wholesale
                        </Typography>
                        <Box
                          display={"flex"}
                          sx={{
                            gap: "15px",
                            mt: "-10px",
                            maxHeight: "400px",
                            overflowY: "auto",
                          }}
                        >
                          <Box
                            sx={{
                              width: "100%",
                            }}
                          >
                            <MultiWholesale
                              setWholesalePrices={setWholesalePrices}
                              wholesalePrices={wholesalePrices}
                            />
                          </Box>
                        </Box>
                      </Box>
                      <Box
                        sx={{
                          visibility:
                            taps === "attributes" ? "visible" : "hidden",
                          position:
                            taps === "attributes" ? "relative" : "absolute",
                        }}
                      >
                        <Typography
                          textTransform={"capitalize"}
                          fontSize={"1.1rem"}
                          mb={1}
                          color={"#fff"}
                          display={"block"}
                        >
                          attributes
                        </Typography>
                        <Box
                          display={"flex"}
                          sx={{
                            gap: "15px",
                            mt: "-10px",
                            maxHeight: "400px",
                            overflowY: "auto",
                          }}
                        >
                          <Box
                            sx={{
                              width: "100%",
                            }}
                          >
                            <TextField
                              size="small"
                              margin="normal"
                              fullWidth
                              id="SKU"
                              placeholder="SKU"
                              name="SKU"
                              autoComplete="SKU"
                              defaultValue={dataItem?.SKU_code}
                            />
                          </Box>
                          <Box
                            sx={{
                              width: "100%",
                            }}
                          >
                            <TextField
                              size="small"
                              margin="normal"
                              fullWidth
                              id="quantity"
                              placeholder="Quantity"
                              name="quantity"
                              autoComplete="quantity"
                              defaultValue={dataItem?.qty}
                            />
                          </Box>
                        </Box>
                        <Box
                          display={"flex"}
                          sx={{
                            gap: "15px",
                            mt: "-10px",
                          }}
                        >
                          <Box
                            sx={{
                              width: "100%",
                            }}
                          >
                            <TextField
                              size="small"
                              margin="normal"
                              fullWidth
                              id="tutorial"
                              placeholder="Tutorial"
                              name="tutorial"
                              autoComplete="Tutorial"
                              defaultValue={dataItem?.tutorial}
                            />
                          </Box>
                          <Box
                            sx={{
                              width: "100%",
                            }}
                          >
                            <TextField
                              size="small"
                              margin="normal"
                              fullWidth
                              id="download_link"
                              placeholder="Download Link"
                              name="download_link"
                              autoComplete="download_link"
                              defaultValue={dataItem?.download_link}
                            />
                          </Box>
                        </Box>
                        <Box
                          display={"flex"}
                          sx={{
                            gap: "15px",
                            mt: "-10px",
                            maxHeight: "400px",
                            overflowY: "auto",
                          }}
                        >
                          <Box
                            sx={{
                              width: "100%",
                            }}
                          >
                            <TextField
                              size="small"
                              margin="normal"
                              fullWidth
                              id="number_of_keys_to_send_notification"
                              placeholder="Notification Limt"
                              name="number_of_keys_to_send_notification"
                              autoComplete="number_of_keys_to_send_notification"
                              defaultValue={
                                dataItem?.number_of_keys_to_send_notification
                              }
                              type="number"
                              inputMode="numeric"
                              onWheel={(e) => e.target.blur()}
                            />
                          </Box>
                        </Box>
                      </Box>
                      <Box
                        sx={{
                          visibility:
                            taps === "sections" ? "visible" : "hidden",
                          position:
                            taps === "sections" ? "relative" : "absolute",
                        }}
                      >
                        <Typography
                          textTransform={"capitalize"}
                          fontSize={"1.1rem"}
                          mb={1}
                          color={"#fff"}
                          display={"block"}
                        >
                          Add sections
                        </Typography>
                        <Box
                          display={"flex"}
                          sx={{
                            gap: "15px",
                            mt: "-10px",
                            maxHeight: "400px",
                            overflowY: "auto",
                            width: "100%",
                          }}
                        >
                          <ProductSection
                            sectionList={sectionList}
                            setSectionList={setSectionList}
                          />
                        </Box>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
              {imgList.length > 0 && (
                <Grid item xs={12} sm={12} px={1} mt={2} height={180}></Grid>
              )}
            </Grid>

            <Grid sm={4} px={2}>
              <Box
                sx={{
                  background: "#0a0e2a",
                  p: 2,
                  borderRadius: "0.375rem",
                }}
              >
                <FormControl>
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    defaultValue={published ? "true" : "false"}
                    name="radio-buttons-group"
                    row
                  >
                    <FormControlLabel
                      value="true"
                      control={<Radio />}
                      label="Publish"
                      onClick={() => setPublished(true)}
                    />
                    <FormControlLabel
                      value="false"
                      control={<Radio />}
                      label="Draft"
                      onClick={() => setPublished(false)}
                    />
                  </RadioGroup>
                </FormControl>
              </Box>
              <Box
                sx={{
                  background: "#0a0e2a",
                  p: 2,
                  mt: 3,
                  borderRadius: "0.375rem",
                }}
              >
                <MultiImageProduct
                  imgList={imgList}
                  setImgList={setImgList}
                  imageKey={"image_file"}
                />
              </Box>
              <Box
                sx={{
                  background: "#0a0e2a",
                  p: 2,
                  mt: 3,
                  borderRadius: "0.375rem",
                  display: "flex",
                  flexDirection: "column",
                  gap: "12px",
                }}
              >
                <SelectCategory />
                <SelectSubCategory />
                <SelectCompany />
                <SelectTags />
              </Box>
            </Grid>
          </Grid>
        </Box>
      )}
    </GeneralModal>
  );
}
