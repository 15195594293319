import { deleteData, getData, patchData, postData, putData } from "..";

// get Order
export const GetOrderData = async (
  skip,
  take,
  search,
  orderNumber,
  selectedStatus,
  selectedPaymentMethod,
  paymentStatus,
  selectedCreatedFromDate,
  selectedCreatedToDate,
  isView,
  createdBy
) => {
  if ((skip !== undefined && take !== undefined) || search !== search) {
    try {
      const request = await getData(
        `/order?page=${
          skip === 0 ? 1 : skip
        }&page_size=${take}&ordering=-created&search=${search}&order_number=${
          orderNumber == undefined ? "" : orderNumber
        }&status=${selectedStatus}&payment_method=${selectedPaymentMethod}&payment_status=${paymentStatus}&is_viewed=${isView}&created_at_after=${selectedCreatedToDate}&created_at_before=${selectedCreatedToDate}&${
          createdBy ? "&created_by__id=" + createdBy : ""
        }`
      ); // Handle the data
      return request;
    } catch (error) {
      console.error(error); // Handle the error
      throw error;
    }
  }
};

// get Order By Id
export const GetOrderByIdData = async (id) => {
  if (id) {
    try {
      const request = await getData(`/order/${id}`); // Handle the data
      return request;
    } catch (error) {
      console.error(error); // Handle the error
      throw error;
    }
  }
};

// post Order
export const PostOrder = async (data) => {
  try {
    const request = await postData(`/order/`, data); // Handle the data
    return request;
  } catch (error) {
    console.error(error); // Handle the error
    throw error;
  }
};

// post Order for user
export const PostUserOrder = async (data) => {
  try {
    const request = await postData(`/order/crete_order_for_user/`, data); // Handle the data
    return request;
  } catch (error) {
    console.error(error); // Handle the error
    throw error;
  }
};

// put Order
export const PutOrder = async (id, data) => {
  try {
    const request = await putData(`/order/${id}/`, data); // Handle the data
    return request;
  } catch (error) {
    console.error(error); // Handle the error
    throw error;
  }
};

// delete Order
export const DeleteOrder = async (id) => {
  try {
    const request = await deleteData(`/order/${id}`); // Handle the data
    return request;
  } catch (error) {
    console.error(error); // Handle the error
    throw error;
  }
};

// patch Order
export const PatchOrderPaid = async (id) => {
  try {
    const request = await patchData(`/order/${id}/mark_as_paid/`); // Handle the data
    return request;
  } catch (error) {
    console.error(error); // Handle the error
    throw error;
  }
};

// patch Order
export const PatchOrderApproved = async (id, data) => {
  try {
    const request = await patchData(`/order/${id}/approve_order/`, data); // Handle the data
    return request;
  } catch (error) {
    console.error(error); // Handle the error
    throw error;
  }
};

// patch Order
export const PatchOrderRejected = async (id, data) => {
  try {
    const request = await patchData(`/order/${id}/reject_order/`, data); // Handle the data
    return request;
  } catch (error) {
    console.error(error); // Handle the error
    throw error;
  }
};

// patch Order
export const PatchOrderReturn = async (id) => {
  try {
    const request = await patchData(`/order/${id}/return_order/`); // Handle the data
    return request;
  } catch (error) {
    console.error(error); // Handle the error
    throw error;
  }
};

// get Order for home
export const GetOrderDataHome = async (skip, take, search) => {
  if ((skip !== undefined && take !== undefined) || search !== search) {
    try {
      const request = await getData(
        `/order?page=${skip === 0 ? 1 : skip}&page_size=${take}`
      ); // Handle the data
      return request;
    } catch (error) {
      console.error(error); // Handle the error
      throw error;
    }
  }
};

// get Order stats for home
export const GetOrderStats = async (from) => {
  try {
    const request = await getData(
      `/order/stats/?start_date=${from}&end_date=${
        new Date().toISOString().split("T")[0]
      }`
    ); // Handle the data
    return request;
  } catch (error) {
    console.error(error); // Handle the error
    throw error;
  }
};

// get Order stats for home
export const GetSoldProductsPerDay = async (from, to, name) => {
  try {
    const request = await getData(
      `/order/total_sold_per_product_per_day/?start_date=${from}&end_date=${to}${
        name ? "&product_name=" + name : ""
      }`
    ); // Handle the data
    return request;
  } catch (error) {
    console.error(error); // Handle the error
    throw error;
  }
};

// get Order stats for home
export const GetTopSoldProducts = async (from) => {
  try {
    const request = await getData(
      `/order/top_sold_products/?start_date=${from}&end_date=${
        new Date().toISOString().split("T")[0]
      }`
    ); // Handle the data
    return request;
  } catch (error) {
    console.error(error); // Handle the error
    throw error;
  }
};

// get rop users per type
export const GetUsersPerType = async (from) => {
  try {
    const request = await getData(
      `/order/top_users_per_type/?start_date=${from}&end_date=${
        new Date().toISOString().split("T")[0]
      }`
    ); // Handle the data
    return request;
  } catch (error) {
    console.error(error); // Handle the error
    throw error;
  }
};

export const GetOrderPerPaymentMethod = async (from, to) => {
  try {
    const request = await getData(
      `/order/total_payment_per_type_per_day/?start_date=${from}&end_date=${to}`
    ); // Handle the data
    return request;
  } catch (error) {
    console.error(error); // Handle the error
    throw error;
  }
};

export const GetOrderReports = async (from, to, name, page, page_size) => {
  try {
    const request = await getData(
      `/order/revenu/?created_after=${from}&created_before=${to}${
        name ? "&username=" + name : ""
      }${page ? "&page=" + page : ""}${
        page_size ? "&page_size=" + page_size : ""
      }`
    ); // Handle the data
    return request;
  } catch (error) {
    console.error(error); // Handle the error
    throw error;
  }
};

export const GetOrderLineData = async (skip, take, createdBy) => {
  if (skip !== undefined && take !== undefined) {
    try {
      const request = await getData(
        `/order-line/?page=${
          skip === 0 ? 1 : skip
        }&page_size=${take}&ordering=-created${
          createdBy ? "&created_by__id=" + createdBy : ""
        }`
      ); // Handle the data
      return request;
    } catch (error) {
      console.error(error); // Handle the error
      throw error;
    }
  }
};
