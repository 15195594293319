import { useEffect } from "react";
import { Avatar, Box, Typography, Grid } from "@mui/material";
import {
  useRecoilState,
  useRecoilValue,
  useResetRecoilState,
  useSetRecoilState,
} from "recoil";
import { DataGridTabel } from "../DataGridTabel/DataGridTabel";
import { ColumnText } from "../DataGridTabel/ColumnText";
import { HeaderGridTabel } from "../DataGridTabel/HeaderGridTabel";
import HomeWorkIcon from "@mui/icons-material/HomeWork";
import {
  dataClickItem,
  methodRequestStatus,
  openFilterState,
  updateDataState,
  selectedDateState,
  selectedDateToState,
} from "../../recoil/global";
import {
  confirmModalState,
  openModalDelete,
  openModalInfo,
} from "../../recoil/modal";
import { useState } from "react";
import { dataTabelState } from "../../recoil/Tabel";

import {
  selectedStatusState,
  selectedCategoryState,
  selectedSubCategoryState,
  selectedTagsState,
} from "../../recoil/select";
import { GetUserPerDay } from "../../services/api/user";
import { GetUsersPerType } from "../../services/api/order";
import ExportButton from "../Others/ExportButton";

export const Tabel = () => {
  const [data, setData] = useRecoilState(dataTabelState);

  const [hasOpthions, setHasOptions] = useState(null);
  const [isOpthions, setIsOptions] = useState(null);
  const [disconted, setDisconted] = useState(null);
  const [topUsers, setTopUsers] = useState([]);

  const selectedDate = useRecoilValue(selectedDateState);
  const selectedDateTo = useRecoilValue(selectedDateToState);

  const selectedStatus = useRecoilValue(selectedStatusState);
  const state = useRecoilValue(openFilterState);
  const updateData = useRecoilValue(updateDataState);
  const category = useRecoilValue(selectedCategoryState);
  const subCategory = useRecoilValue(selectedSubCategoryState);
  const tags = useRecoilValue(selectedTagsState);
  const openFilter = useRecoilValue(openFilterState);

  const resetConfirm = useResetRecoilState(confirmModalState);

  const setDataItem = useSetRecoilState(dataClickItem);
  const setOpenModal = useSetRecoilState(openModalInfo);
  const setOpenDelete = useSetRecoilState(openModalDelete);
  const setMethodRequest = useSetRecoilState(methodRequestStatus);
  useEffect(() => {
    setDataItem(null);
  }, [state]);

  const handelEdit = (data) => {
    setOpenModal(true);
    setDataItem(data);
    setMethodRequest("edit");
  };

  const handelDelete = (data) => {
    setOpenDelete(true);
    setDataItem(data);
    setMethodRequest("delete");
  };

  const columns = [
    {
      field: "day",
      headerName: "Date",
      headerClassName: "grid--header",
      flex: 1,
      renderCell: (params) => {
        return (
          <Box
            display={"flex"}
            alignItems={"center"}
            sx={{
              overflowX: "auto",
              scrollbarWidth: "none",
            }}
            gap={2}
          >
            <ColumnText
              text={params.row?.day ? params.row?.day : "N/A"}
              variant="subtitle1"
              fontWeight="bold"
            />
            {/* <ColumnText text={params.row?.job_title?.name_ar} name_ar="caption" color={"gray"} /> */}
          </Box>
        );
      },
    },
    {
      field: "wholesale_user",
      headerName: "Whole Selor",
      headerClassName: "grid--header",
      flex: 1,
      renderCell: (params) => {
        return (
          <Box
            display={"flex"}
            alignItems={"center"}
            sx={{
              overflowX: "auto",
              scrollbarWidth: "none",
            }}
            gap={2}
          >
            <ColumnText
              text={
                params.row?.wholesale_user ? params.row?.wholesale_user : "0"
              }
              variant="subtitle1"
              fontWeight="bold"
            />
          </Box>
        );
      },
    },
    {
      field: "normal_user",
      headerName: "Retails",
      headerClassName: "grid--header",
      flex: 1,
      renderCell: (params) => {
        return (
          <Box
            display={"flex"}
            alignItems={"center"}
            sx={{
              overflowX: "auto",
              scrollbarWidth: "none",
            }}
            gap={2}
          >
            <ColumnText
              text={params.row?.normal_user ? params.row?.normal_user : "0"}
              variant="subtitle1"
              fontWeight="bold"
            />
          </Box>
        );
      },
    },
    {
      field: "Admin",
      headerName: "Quantity",
      headerClassName: "grid--header",
      flex: 1,
      renderCell: (params) => {
        return (
          <Box
            display={"flex"}
            alignItems={"center"}
            sx={{
              overflowX: "auto",
              scrollbarWidth: "none",
            }}
            gap={2}
          >
            <ColumnText
              text={params.row?.admin ? params.row?.admin : "0"}
              variant="subtitle1"
              fontWeight="bold"
            />
          </Box>
        );
      },
    },
  ];

  const fetchData = () => {
    setData((old) => ({
      ...old,
      loading: true,
      data: [],
    }));
    console.log("selectedDate", selectedDate, selectedDateTo);

    GetUserPerDay(selectedDate)
      .then((res) => {
        console.log(
          "selectedDate results",
          res.map((item, index) => {
            return {
              ...item,
              id: index + 1,
            };
          })
        );
        setData((old) => ({
          ...old,
          data: res.map((item, index) => {
            return {
              ...item,
              id: index + 1,
            };
          }),
          total: res?.count,

          loading: false,
        }));
      })
      .catch((err) => {
        console.log(err);
        setData({ ...data, loading: false });
      });

    GetUsersPerType(selectedDate)
      .then((res) => {
        console.log("res", res);
        setTopUsers(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  let filters = data.filters.page || data.filters.take || data.filters.skip;

  useEffect(() => {
    fetchData();
    // console.log("data", data.data.length > 0);
  }, [
    updateData,
    selectedDate,
    selectedDateTo,
    hasOpthions,
    isOpthions,
    disconted,
  ]);

  return (
    <>
      <Box>
        <HeaderGridTabel
          title="Users"
          icon={
            <HomeWorkIcon
              size={25}
              className="color-primary"
              color={"#08001e"}
            />
          }
          fetchData={fetchData}
          isFilter={true}
          data={data?.data}
          noSearch={true}
          exel={true}
        />
        <Box mt={2}>
          <DataGridTabel
            columns={columns}
            data={data}
            setData={setData}
            noPagination={true}
            noBorder={true}
            height={"40vh !important"}
            small={"true"}
          />
        </Box>
        <Grid container px={2} mt={2}>
          <Grid xl={2} lg={1} sm={12}></Grid>
          <Grid item xl={4} lg={5} px={2} sm={12}>
            <Box
              display={"flex"}
              alignItems={"center"}
              flexDirection={"column"}
              gap={2}
              px={2}
              py={3}
              borderRadius={3}
              sx={{
                backgroundColor: "#0b102c",
                padding: "10px",
              }}
            >
              <Box
                display={"flex"}
                alignItems={"center"}
                justifyContent={"space-between"}
                width={"100%"}
              >
                <Typography variant="h6" ml={1} color={"#fff"}>
                  Top Retails at Date:
                </Typography>
                <ExportButton data={topUsers.normal} fileName="data" />
              </Box>
              <Box mt={3} width={"100%"}>
                {topUsers?.normal?.slice(0, 5)?.map((item, index) => {
                  return (
                    <Grid
                      container
                      key={index}
                      sx={{
                        borderBottom: "1px solid #ffffff5e",
                        padding: "10px",
                      }}
                    >
                      <Grid
                        display={"flex"}
                        alignItems={"center"}
                        xl={2}
                        lg={2}
                        sm={2}
                      >
                        <Avatar
                          sx={{
                            width: 55,
                            height: 55,
                          }}
                          alt={item.user}
                          src="/static/images/avatar/1.jpg"
                        />
                      </Grid>
                      <Grid
                        xl={8}
                        lg={8}
                        sm={8}
                        display={"flex"}
                        alignItems={"center"}
                      >
                        <Typography
                          variant="h6"
                          color={"#fff"}
                          width={"100%"}
                          overflow={"hidden"}
                          textOverflow={"ellipsis"}
                          whiteSpace={"nowrap"}
                          sx={{
                            my: "auto",
                          }}
                        >
                          {item.user}
                        </Typography>
                      </Grid>
                      <Grid
                        xl={2}
                        lg={2}
                        sm={2}
                        display={"flex"}
                        justifyContent={"end"}
                        px={1}
                      >
                        <Typography variant="h6" color={"#fff"}>
                          {item.total_orders}
                        </Typography>
                      </Grid>
                    </Grid>
                  );
                })}
              </Box>
            </Box>
          </Grid>
          <Grid item xl={4} lg={5} px={2} sm={12}>
            <Box
              display={"flex"}
              alignItems={"center"}
              flexDirection={"column"}
              gap={2}
              px={2}
              py={3}
              borderRadius={3}
              sx={{
                backgroundColor: "#0b102c",
                padding: "10px",
              }}
            >
              <Box
                display={"flex"}
                alignItems={"center"}
                justifyContent={"space-between"}
                width={"100%"}
              >
                <Typography variant="h6" ml={1} color={"#fff"}>
                  Top Whole Salor at Date:
                </Typography>
                <ExportButton data={topUsers.wholesale} fileName="data" />
              </Box>
              <Box mt={3} width={"100%"}>
                {topUsers?.wholesale?.slice(0, 5)?.map((item, index) => {
                  return (
                    <Grid
                      container
                      key={index}
                      sx={{
                        borderBottom: "1px solid #ffffff5e",
                        padding: "10px",
                      }}
                    >
                      <Grid
                        display={"flex"}
                        alignItems={"center"}
                        xl={2}
                        lg={2}
                        sm={2}
                      >
                        <Avatar
                          sx={{
                            width: 55,
                            height: 55,
                          }}
                          alt={item.user}
                          src="/static/images/avatar/1.jpg"
                        />
                      </Grid>
                      <Grid
                        xl={8}
                        lg={8}
                        sm={8}
                        display={"flex"}
                        alignItems={"center"}
                      >
                        <Typography
                          variant="h6"
                          color={"#fff"}
                          width={"100%"}
                          overflow={"hidden"}
                          textOverflow={"ellipsis"}
                          whiteSpace={"nowrap"}
                          sx={{
                            my: "auto",
                          }}
                        >
                          {item.user}
                        </Typography>
                      </Grid>
                      <Grid
                        xl={2}
                        lg={2}
                        sm={2}
                        display={"flex"}
                        justifyContent={"end"}
                        px={1}
                      >
                        <Typography variant="h6" color={"#fff"}>
                          {item.total_orders}
                        </Typography>
                      </Grid>
                    </Grid>
                    // <Box
                    //   display={"flex"}
                    //   alignItems={"center"}
                    //   justifyContent={"space-between"}
                    //   width={"100%"}
                    //   key={index}
                    //   sx={{
                    //     borderBottom: "1px solid #ffffff5e",
                    //     padding: "10px",
                    //   }}
                    // >
                    //   <Box display={"flex"} alignItems={"center"} gap={2}>
                    //     <Avatar
                    //       alt={item.user}
                    //       src="/static/images/avatar/1.jpg"
                    //     />
                    //     <Typography variant="h6" color={"#fff"}>
                    //       {item.user}
                    //     </Typography>
                    //   </Box>
                    //   <Typography variant="h6" color={"#fff"}>
                    //     {item.total_orders}
                    //   </Typography>
                    // </Box>
                  );
                })}
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default Tabel;
