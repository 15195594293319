import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import Cookies from "js-cookie";
import { useRecoilState } from "recoil";
import { userInfo } from "../../recoil";
import { useEffect, useState } from "react";
import StepperLogin from "./StepperLogin";
import anim_auth from "../../assets/animations/anim_auth.json";
import Lottie from "lottie-web";
import { ShapesAuth } from "./ShapesAuth";
import { IconButton, InputAdornment } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
const LoginForm = () => {
  const [userInfoData, setUserInfoData] = useRecoilState(userInfo);
  const [showPassword, setShowPassword] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    Lottie.loadAnimation({
      container: document.querySelector("#auth-anim"),
      renderer: "svg",
      loop: true,
      autoplay: true,
      animationData: anim_auth,
    });
  }, []);
  
  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    // function resend email
    // run function login
    const infoData = {
      email: data.get("email"),
      password: data.get("password"),
    };

    axios
      .post(`/auth/login/`, infoData, {
        headers: {
          Authorization: "",
        },
      })
      .then((res) => {
        console.log(res);
        if (res?.data?.user?.is_superuser) {
          toast.success(res.data?.message);
          Cookies.set("token", res?.data.access);
          setUserInfoData(res.data?.user);
          navigate("/");
        } else {
          toast.error("لاتملك الصلاحيات للدخول");
        }
      })
      .catch((err) => {
        toast.error(<div>المعلومات غير صحيحه</div>);
      });
  };

  // route if to hoe if user not login
  useEffect(() => {
    if (userInfoData) {
      navigate("/");
    }
  }, [userInfoData]);

  // hide and show password
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);

  return (
    <Box
      sx={{
        position: "relative",
        overflow: "hidden",
        background: "#fff",
        backdropFilter: "blur(10px)",
        display: "flex",
        justifyContent: "center",
      }}
    >
      <ShapesAuth />
      <Grid
        container
        sx={{
          maxWidth: { xs: "90vw", md: "70vw" },
          height: {xl: "70vh", lg: "80vh",},
          my: "15vh",

          justifyContent: "center",
          alignItems: "center",
          background: { xs: "#ffffff", lg: "#ffffff44" },
          padding: 0,
          boxShadow: "1px 4px 10px #dcdde1",
          borderRadius: "10px",
          overflow: "hidden",
          backdropFilter: "blur(20px)",
        }}
      >
        <CssBaseline />
        <Grid
          item
          md={false}
          lg={7}
          sx={{
            background: "#ffffff44",
            height: "100%",
            overflow: "hidden",
            display: { xs: "none", lg: "block" },
          }}
        >
          <Box sx={{ mt: "2vh" }}>
            <Box
              sx={{
                width: 360,
                height: 360,
                overflow: "hidden",
                mx: "auto",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <span id="auth-anim"></span>
            </Box>
            <StepperLogin />
          </Box>
        </Grid>
        <Grid
          item
          md={12}
          lg={5}
          component={Paper}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            boxShadow: "none",
            background: "#ffffff44",
          }}
        >
          <Box
            sx={{
              my: 4,
              mx: 4,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              boxShadow: "none",
            }}
          >
            <Typography
              component="p"
              variant="p"
              fontWeight={500}
              sx={{
                mb: 1,
                background: "#08001e88",
                color: "#fff",
                borderRadius: 10,
                px: 1,
              }}
            >
              Admin
            </Typography>
            <Typography
              component="h1"
              variant="h3"
              fontWeight={500}
              sx={{
                mb: 3,
              }}
            >
              Sign in
            </Typography>
            <Box
              component="form"
              noValidate
              onSubmit={handleSubmit}
              sx={{ mt: 1 }}
            >
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                autoFocus
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                id="password"
                autoComplete="current-password"
                type={showPassword ? "text" : "password"}
                InputProps={{
                  // <-- This is where the toggle button is added.
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 2, mb: 2 }}
                style={{ padding: 12 }}
              >
                Sign In
              </Button>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default LoginForm;
