import { useEffect } from "react";
import { Avatar, Box, Link } from "@mui/material";
import {
  useRecoilState,
  useRecoilValue,
  useResetRecoilState,
  useSetRecoilState,
} from "recoil";
import { DataGridTabel } from "../DataGridTabel/DataGridTabel";
import { ColumnText } from "../DataGridTabel/ColumnText";
import { ColumnButton } from "../DataGridTabel/ColumnButton";
import { HeaderGridTabel } from "../DataGridTabel/HeaderGridTabel";
import HomeWorkIcon from "@mui/icons-material/HomeWork";
import ModalInfo from "./Modal/ModalInfo";
import {
  dataClickItem,
  methodRequestStatus,
  openFilterState,
  selectedDateState,
  selectedDateToState,
  updateDataState,
} from "../../recoil/global";
import {
  confirmModalState,
  openModalDelete,
  openModalInfo,
  openModalShow,
} from "../../recoil/modal";
import ModalDelete from "./Modal/ModalDelete";
import { useState } from "react";
import { dataTabelState } from "../../recoil/Tabel";
import Filters from "./Filters";
import {
  selectedCreatedFromDateState,
  selectedCreatedToDateState,
  selectedIsViewState,
  selectedPaymentMethodState,
  selectedPaymentStatus,
  selectedStatusState,
} from "../../recoil/select";
import { GetOrderReports } from "../../services/api/order";
import ModalShow from "./Modal/ModalShow";
import { CheckBox } from "@mui/icons-material";
import moment from "moment";
import { useLocation, useNavigate } from "react-router-dom";

export const Tabel = () => {
  const [data, setData] = useRecoilState(dataTabelState);
  const [emailSearch, setEmailSearch] = useState(null);
  const [phoneSearch, setPhoneSearch] = useState(null);

  const [selectedStatus, setSelectedStatus] =
    useRecoilState(selectedStatusState);
  const [updateData, setUpdateData] = useRecoilState(updateDataState);
  const state = useRecoilValue(openFilterState);
  const selectedPaymentMethod = useRecoilValue(selectedPaymentMethodState);
  const paymentStatus = useRecoilValue(selectedPaymentStatus);
  const selectedCreatedFromDate = useRecoilValue(selectedCreatedFromDateState);
  const selectedCreatedToDate = useRecoilValue(selectedCreatedToDateState);
  const isView = useRecoilValue(selectedIsViewState);

  const selectedDate = useRecoilValue(selectedDateState);
  const selectedDateTo = useRecoilValue(selectedDateToState);

  const setDataItem = useSetRecoilState(dataClickItem);
  const setOpenModal = useSetRecoilState(openModalInfo);
  const setOpenShowModal = useSetRecoilState(openModalShow);
  const setOpenDelete = useSetRecoilState(openModalDelete);
  const setMethodRequest = useSetRecoilState(methodRequestStatus);

  const navigate = useNavigate();

  function formatDate(inputDate) {
    let formattedDate = `${inputDate.split("T")[0]}`;
    return formattedDate;
  }
  function formatHours(inputDate) {
    let date = new Date(inputDate);
    let hours = date.getHours();
    let minutes = String(date.getMinutes()).padStart(2, "0");
    let period = hours >= 12 ? "PM" : "AM";
    hours = hours % 12;
    hours = hours ? hours : 12;
    let formattedDate = `${hours}:${minutes} ${period}`;
    return formattedDate;
  }

  // replace _ with space and capitalize first letter of each word

  useEffect(() => {
    setDataItem(null);
  }, [state]);
  const handelShow = (data) => {
    setOpenShowModal(true);
    setDataItem(data);
    setMethodRequest("show");
  };
  const handelPaid = (data) => {
    setOpenModal(true);
    setDataItem(data);
    setMethodRequest("paid");
  };
  const handelApproved = (data) => {
    setOpenModal(true);
    setDataItem(data);
    setMethodRequest("approved");
  };
  const handelRejected = (data) => {
    setOpenModal(true);
    setDataItem(data);
    setMethodRequest("rejected");
  };
  const handelReturn = (data) => {
    setOpenModal(true);
    setDataItem(data);
    setMethodRequest("return");
  };
  const handelDelete = (data) => {
    setOpenDelete(true);
    setDataItem(data);
    setMethodRequest("delete");
  };

  const columns = [
    {
      field: "created_by__username",
      headerName: "User",
      headerClassName: "grid--header",

      minWidth: 240,
      renderCell: (params) => {
        return (
          <Box
            display={"flex"}
            alignItems={"center"}
            gap={2}
            sx={{
              textDecoration: "none",
              cursor: "pointer",
            }}
            color={"#fff"}
          >
            <ColumnText
              text={
                params.row?.created_by_data?.username
                  ? params.row?.created_by_data?.username
                  : "N/A"
              }
              variant="subtitle1"
              fontWeight="bold"
            />
          </Box>
        );
      },
    },
    {
      field: "product_name",
      headerName: "Product Name",
      headerClassName: "grid--header",

      minWidth: 340,
      renderCell: (params) => {
        return (
          <Box
            display={"flex"}
            alignItems={"center"}
            gap={2}
            sx={{
              textDecoration: "none",
              cursor: "pointer",
            }}
            color={"#fff"}
          >
            <ColumnText
              text={params.row?.product_name ? params.row?.product_name : "N/A"}
              variant="subtitle1"
              fontWeight="bold"
            />
          </Box>
        );
      },
    },

    {
      field: "quantity",
      headerName: "Qauntity                ",
      headerClassName: "grid--header",

      minWidth: 200,
      renderCell: (params) => {
        return (
          <Box display={"flex"} alignItems={"center"} gap={2}>
            <ColumnText
              text={params.row?.quantity ? params.row?.quantity : "N/A"}
              variant="subtitle1"
              fontWeight="bold"
            />
          </Box>
        );
      },
    },
    {
      field: "unit_price",
      headerName: "Unit Price",
      headerClassName: "grid--header",

      minWidth: 200,
      renderCell: (params) => {
        return (
          <Box display={"flex"} alignItems={"center"} gap={2}>
            <ColumnText
              text={params.row?.unit_price ? params.row?.unit_price : "N/A"}
              variant="subtitle1"
              fontWeight="bold"
            />
          </Box>
        );
      },
    },
    {
      field: "sub_total",
      headerName: "Total price",
      headerClassName: "grid--header",

      minWidth: 200,
      renderCell: (params) => {
        return (
          <Box display={"flex"} alignItems={"center"} gap={2}>
            <ColumnText
              text={params.row?.sub_total ? params.row?.sub_total : "N/A"}
              variant="subtitle1"
              fontWeight="bold"
            />
          </Box>
        );
      },
    },
    {
      field: "created",
      headerName: "Date",
      headerClassName: "grid--header",

      minWidth: 200,
      renderCell: (params) => {
        return (
          <Box display={"flex"} alignItems={"center"} gap={2}>
            <ColumnText
              text={
                params.row?.created ? moment(params.row?.created).format("MMM D YYYY, h:mm a") : "N/A"
              }
              variant="subtitle1"
              fontWeight="bold"
            />
          </Box>
        );
      },
    },
  ];

  const fetchData = () => {
    setData((old) => ({
      ...old,
      loading: true,
      data: [],
    }));
    GetOrderReports(
      selectedDate,
      selectedDateTo,
      data.filters.search,
      data.filters.skip,
      data.filters.take
    )
      .then((res) => {
        console.log("Dtest", res);
        setData((old) => ({
          ...old,
          data: res.results,
          total: res?.count,
          loading: false,
        }));
      })
      .catch(() => {
        setData({ ...data, data: [], loading: false });
      });
  };

  let filters = data.filters.page || data.filters.take || data.filters.skip;

  useEffect(() => {
    fetchData();
  }, [filters, updateData, selectedDate, selectedDateTo]);

  return (
    <>
      <Box
        sx={{
          m: 0,
        }}
      >
        <HeaderGridTabel
          title="Orders"
          icon={
            <HomeWorkIcon
              size={25}
              className="color-primary"
              color={"#ffffff"}
            />
          }
          fetchData={fetchData}
          isFilter={true}
          data={data?.data}
          date={true}
          exel={true}
        />
        <Box mt={2} sx={{}}>
          <DataGridTabel columns={columns} data={data} setData={setData} />
        </Box>
        {/* Modal */}
        <ModalShow />
        <ModalInfo />
        <ModalDelete />
        {/* Fillter */}
        <Filters
          emailSearch={emailSearch}
          setEmailSearch={setEmailSearch}
          phoneSearch={phoneSearch}
          setPhoneSearch={setPhoneSearch}
          fetchData={fetchData}
        />
      </Box>
    </>
  );
};

export default Tabel;
