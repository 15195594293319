import { atom } from "recoil";

// state open slidbar
export const openSlidbarState = atom({
  key: "openSlidbarState",
  default: true,
});

// save data on click button item
export const dataClickItem = atom({
  key: "dataClickItem",
  default: null,
});

// save num page pagination
export const pageNumState = atom({
  key: "pageNumState",
  default: 1,
});

// save num skip pagination
export const skipNumState = atom({
  key: "skipNumState",
  default: 25,
});

// open reject modal
export const openModalRegect = atom({
  key: "openModalRegect",
  default: false,
});

// open confirm modal
export const openModalConfirm = atom({
  key: "openModalConfirm",
  default: false,
});

// open menu
export const openMenuState = atom({
  key: "openMenuState",
  default: false,
});

// open menu
export const openDropState = atom({
  key: "openDropState",
  default: 0,
});

// open modal method Request
export const methodRequestStatus = atom({
  key: "methodRequestStatus",
  default: null,
});

// update data
export const updateDataState = atom({
  key: "updateDataState",
  default: false,
});

// open filter search
export const openFilterState = atom({
  key: "openFilterState",
  default: {
    right: false,
  },
});

export const wholesaleTypesState = atom({
  key: "wholesaleTypesState",
  default: null,
});

export const SidebarHightState = atom({
  key: "SidebarHightState",
  default: 0,
});

export const selectedDateState = atom({
  key: "selectedDateState",
  // past month
  default: new Date(new Date().setMonth(new Date().getMonth() - 1)).toISOString().split("T")[0],
});

export const selectedDateToState = atom({
  key: "selectedDateToState",
  default: new Date().toISOString().split("T")[0],
});

export const locationState = atom({
  key: "locationState",
  default: null,
});
