import React, { useEffect, useState } from "react";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  Input,
  TableContainer,
  TableHead,
  TextField,
  Typography,
} from "@mui/material";
import { toast } from "react-toastify";
import { openModalInfo } from "../../../recoil/modal";
import {
  dataClickItem,
  methodRequestStatus,
  updateDataState,
} from "../../../recoil/global";
import GeneralModal from "../../GeneralModal/GeneralModal";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import "leaflet/dist/leaflet.css";
import { PostCategory, PutCategory } from "../../../services/api/category";
import {
  PatchOrderApproved,
  PatchOrderPaid,
  PatchOrderRejected,
  PatchOrderReturn,
} from "../../../services/api/order";
import {
  borderRadius,
  color,
  display,
  height,
  margin,
  maxHeight,
  maxWidth,
} from "@mui/system";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { GetUserData } from "../../../services/api/user";
import DropDown from "../../Others/DropDown";
import ProductRow from "../ProductRow";
import AddIcon from "@mui/icons-material/Add";
import { v4 as uuidv4 } from "uuid";
import { transformation } from "leaflet";
import { useTheme } from "@emotion/react";
import { PostUserOrder } from "../../../services/api/order";
import { CheckBox } from "@mui/icons-material";

export default function ModalInfo() {
  const [open, setOpen] = useRecoilState(openModalInfo);
  const [selectedFile, setSelectedFile] = useState(null);
  const [sendData, setSendData] = useState(false);
  const [loading, setLoading] = useState(false);
  const [searchUser, setSearchUser] = useState("");
  const [userDropdown, setUserDropdown] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [users, setUsers] = useState([]);
  const [paymentMethod, setPaymentMethod] = useState("cash");
  const [useWallet, setUseWallet] = useState(false);

  const [productsData, setProductsData] = useState([
    {
      id: uuidv4(),
      name: "",
      quantity: 0,
      price: 0,
    },
  ]);

  const theme = useTheme();

  const dataItem = useRecoilValue(dataClickItem);
  const methodRequest = useRecoilValue(methodRequestStatus);

  const setUpdateData = useSetRecoilState(updateDataState);

  useEffect(() => {
    GetUserData(0, 25, searchUser)
      .then((res) => {
        setUsers(res.results);
        setUserDropdown(res.results.length > 0 && searchUser.length > 0);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [searchUser]);

  useEffect(() => {
    console.log("productsData", productsData);
    productsData.length === 0 &&
      setProductsData([
        {
          id: uuidv4(),
          name: "",
          quantity: 0,
          price: 0,
        },
      ]);
  }, [productsData]);

  // set file data
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
    console.log(file);
  };

  const handleSubmit = () => {
    const data = {
      user: selectedUser?.id,
      payment_method: paymentMethod,
      use_wallet: useWallet,
      user: selectedUser?.pk,
      order_lines: productsData.map((item) => {
        return {
          product: item.id,
          quantity: Number(item.qty),
        };
      }),
    };

    console.log("data", data);

    PostUserOrder(data)
      .then((res) => {
        console.log(res);
        toast.success("Order Added Successfully");
        setUpdateData((old) => Math.random());
        handleClose();
      })
      .catch((err) => {
        console.log(err);
        toast.error("Failed to Add Order");
      });
  };

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  // close modal
  const handleClose = () => {
    setOpen(false);
    setLoading(false);
    setPaymentMethod("cash");
    setUseWallet(false);
    setSearchUser("");
    setSelectedUser(null);
    setProductsData([
      {
        id: uuidv4(),
        name: "",
        quantity: 0,
        price: 0,
      },
    ]);
  };

  return (
    <GeneralModal
      title={"Add Order"}
      maxWidth="sm"
      open={open}
      btnFooterHide={true}
      style={{
        ".MuiDialog-paperWidthSm": {
          maxWidth: "100vw !important",
          maxHeight: "100vh !important",
          height: "100vh !important",
          margin: "0px !important",
          borderRadius: "0px",
          backgroundColor: "#0f1535 !important",
        },
        h2: {
          background: "linear-gradient(310deg, #7928ca, #ff0080) !important",
          color: "#fff",
          borderRadius: "0px",
        },
      }}
      icon={() => {
        return <></>;
      }}
      onSubmit={() => {}}
      loading={loading}
      onClose={() => {
        setLoading(false);
      }}
    >
      <Box
        sx={{
          minHeight: "80vh",
          mt: 2,
          p: 2,
        }}
      >
        <Grid container>
          <Grid item sm={12} md={4} px={1}>
            <Box
              sx={{
                background: "#0b112c",
                padding: "40px 40px",
                borderRadius: "0.375rem",
              }}
            >
              <Box
                position={"relative"}
                display={"flex"}
                width={"100%"}
                border={"1px solid #ffffff26"}
                borderRadius={"0.375rem"}
              >
                <FormControl fullWidth>
                  <InputLabel
                    sx={{
                      color: "#fff",
                    }}
                    id="demo-simple-select-label"
                  >
                    Select User
                  </InputLabel>
                  <Input
                    sx={{
                      color: "#fff",
                      // borderBottom: "1px solid #ffffff26",
                      width: "100%",
                      input: {
                        px: 2,
                        pb: 1,
                        fontSize: "1rem",

                        "-webkit-text-fill-color": "#fff !important",
                      },
                    }}
                    id="user"
                    name="user"
                    autoComplete="User"
                    value={searchUser || selectedUser?.username}
                    onChange={(e) => {
                      setSearchUser(e.target.value);
                      setSelectedUser(null);
                    }}
                    autoFocus
                  />
                </FormControl>
                <DropDown open={userDropdown} setOpen={setUserDropdown}>
                  <Box
                    sx={{
                      maxHeight: "300px",
                      minWidth: "100%",
                      overflowY: "auto",
                    }}
                  >
                    {users &&
                      searchUser &&
                      users.length > 0 &&
                      users.map((user, index) => {
                        return (
                          <Typography
                            key={index}
                            sx={{
                              color: "#fff",

                              cursor: "pointer",
                              padding: "10px",
                              borderBottom: "1px solid #ffffff26",
                              "&:hover": {
                                backgroundColor: "#ffffff26",
                              },
                            }}
                            onClick={() => {
                              setSearchUser("");
                              setSelectedUser(user);
                              setUserDropdown(false);
                            }}
                          >
                            {user.username}
                          </Typography>
                        );
                      })}
                  </Box>
                </DropDown>
              </Box>
            </Box>
          </Grid>
          <Grid item sm={12} md={4} px={1}>
            <Box
              sx={{
                background: "#0b112c",
                padding: "40px 40px",
                borderRadius: "0.375rem",
                width: "100%",
              }}
            >
              <Box
                position={"relative"}
                display={"flex"}
                width={"100%"}
                border={"1px solid #ffffff26"}
                borderRadius={"0.375rem"}
              >
                <FormControl>
                  <InputLabel
                    sx={{
                      color: "#fff",
                      visibility: selectedUser ? "hidden" : "visible",
                    }}
                    id="demo-simple-select-label-2"
                  >
                    User Wallet
                  </InputLabel>

                  <Input
                    sx={{
                      color: "#fff",

                      width: "100%",
                      input: {
                        px: 2,
                        color: "#fff",
                        "-webkit-text-fill-color": "#fff !important",
                        pb: 1,
                        fontSize: "1rem",
                      },
                    }}
                    id="wallet"
                    name="wallet"
                    placeholder="User Wallet"
                    autoComplete="wallet"
                    disabled
                    value={selectedUser?.wallet_balance}
                    autoFocus={selectedUser}
                  />
                </FormControl>
              </Box>
            </Box>
          </Grid>
          <Grid item sm={12} md={4} px={1}>
            <Box
              sx={{
                background: "#0b112c",
                padding: "35px 40px",
                borderRadius: "0.375rem",
              }}
            >
              <Box
                position={"relative"}
                display={"flex"}
                width={"100%"}
                gap={2}
                justifyContent={"start"}
                alignItems={"center"}
                // border={"1px solid #ffffff26"}
                borderRadius={"0.375rem"}
              >
                <FormControl fullWidth margin="normal" sx={{ minWidth: 100 }}>
                  <InputLabel
                    id="status-label"
                    sx={{ background: "#fff", top: -6, left: 0 }}
                    textAlign={"start"}
                  >
                    Select Payment Method
                  </InputLabel>
                  <Select
                    size="small"
                    sx={{ minWidth: 100 }}
                    labelId="status-label"
                    id="status-select"
                    onChange={(e) => {
                      setPaymentMethod(e.target.value);
                    }}
                    value={paymentMethod}
                  >
                    {[
                      "cash",
                      "fib",
                      "zain_cash",
                      "fast_pay",
                      "credit_card",
                    ]?.map((status, index) => (
                      <MenuItem key={index} value={status}>
                        {capitalizeFirstLetter(status)}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <FormControlLabel
                  sx={{
                    mt: 2,
                    accentColor: "#fff",
                    width: "100%",
                  }}
                  control={
                    <Checkbox
                      value={useWallet}
                      sx={{
                        accentColor: "#fff",
                      }}
                      onChange={(e) => setUseWallet(e.target.checked)}
                    />
                  }
                  checked={useWallet}
                  label="Use Wallet"
                />
              </Box>
            </Box>
          </Grid>
          <Grid item sm={12} mt={3}>
            <Box
              sx={{
                background: "#0b112c",
                padding: "20px 40px",
                borderRadius: "0.375rem",
              }}
            >
              <Typography
                color={"#fff"}
                fontSize={"1.25rem"}
                fontWeight={500}
                mb={2}
              >
                Order Details
              </Typography>
              <Box position={"relative"} display={"flex"} width={"100%"}>
                <Grid container>
                  <Grid item sm={0.5} py={1} border={"1px solid #ffffff26"}>
                    <Typography color={"#fff"} textAlign={"center"} py={1}>
                      #
                    </Typography>
                  </Grid>
                  <Grid item sm={3.5} py={1} border={"1px solid #ffffff26"}>
                    <Typography color={"#fff"} textAlign={"center"} py={1}>
                      Product Name
                    </Typography>
                  </Grid>
                  <Grid item sm={1.5} py={1} border={"1px solid #ffffff26"}>
                    <Typography color={"#fff"} textAlign={"center"} py={1}>
                      Quantity
                    </Typography>
                  </Grid>
                  <Grid item sm={2.5} py={1} border={"1px solid #ffffff26"}>
                    <Typography color={"#fff"} textAlign={"center"} py={1}>
                      Unit Price
                    </Typography>
                  </Grid>
                  <Grid item sm={2.5} py={1} border={"1px solid #ffffff26"}>
                    <Typography color={"#fff"} textAlign={"center"} py={1}>
                      Total Price
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    sm={1.5}
                    py={1}
                    border={"1px solid #ffffff26"}
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Button
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        backgroundColor: "#04a96c",
                        border: "1px solid #04a96c",
                        svg: {
                          fill: "#fff",
                        },
                        "&:hover": {
                          backgroundColor: "transparent",
                          svg: {
                            fill: "#04a96c",
                          },
                        },
                      }}
                      onClick={() => {
                        setProductsData([
                          ...productsData,
                          {
                            id: uuidv4(),
                            name: "",
                            quantity: 0,
                            price: 0,
                          },
                        ]);
                      }}
                    >
                      <AddIcon />
                    </Button>
                  </Grid>
                  {productsData?.length > 0 &&
                    productsData.map((item, index) => {
                      return (
                        <ProductRow
                          key={index}
                          index={index}
                          data={item}
                          setProductsData={setProductsData}
                          productsData={productsData}
                        />
                      );
                    })}
                  <Grid item sm={0.5} py={1} border={"1px solid #ffffff26"}>
                    <Typography color={"#fff"} textAlign={"center"} py={1}>
                      Total
                    </Typography>
                  </Grid>
                  <Grid item sm={3.5} py={1} border={"1px solid #ffffff26"}>
                    <Typography
                      color={"#fff"}
                      textAlign={"center"}
                      py={1}
                    ></Typography>
                  </Grid>
                  <Grid item sm={1.5} py={1} border={"1px solid #ffffff26"}>
                    <Typography color={"#fff"} textAlign={"center"} py={1}>
                      {productsData.reduce((acc, item) => acc + item.qty, 0) ||
                        0}
                    </Typography>
                  </Grid>
                  <Grid item sm={2.5} py={1} border={"1px solid #ffffff26"}>
                    <Typography
                      color={"#fff"}
                      textAlign={"center"}
                      py={1}
                    ></Typography>
                  </Grid>
                  <Grid item sm={2.5} py={1} border={"1px solid #ffffff26"}>
                    <Typography color={"#fff"} textAlign={"center"} py={1}>
                      {productsData.reduce(
                        (acc, item) => acc + item.price * item.qty,
                        0
                      ) || 0}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    sm={1.5}
                    py={1}
                    border={"1px solid #ffffff26"}
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  ></Grid>
                </Grid>
              </Box>
            </Box>
          </Grid>
          <Grid item sm={12} mt={3}>
            <Box
              sx={{
                background: "#0b112c",
                padding: "20px 40px",
                borderRadius: "0.375rem",
              }}
            >
              <Button
                sx={{
                  backgroundImage:
                    "linear-gradient(310deg, #00c6fb 0%, #005bea 100%) !important",
                  transition: "all 0.3s ease",
                }}
                onClick={handleSubmit}
              >
                <Typography color={"#fff"} textTransform={"capitalize"}>
                  Submit Order
                </Typography>
              </Button>
              <Button
                mx={2}
                sx={{
                  backgroundImage:
                    "linear-gradient(310deg, #ee0979, #ff6a00) !important",
                  transition: "all 0.3s ease",
                  mx: 2,
                }}
                onClick={handleClose}
              >
                <Typography color={"#fff"}>Cancel</Typography>
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </GeneralModal>
  );
}
