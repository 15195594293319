import React, { useEffect, useRef } from "react";

import {
  Avatar,
  Box,
  Button,
  Grid,
  Input,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import {
  useRecoilState,
  useRecoilValue,
  useResetRecoilState,
  useSetRecoilState,
} from "recoil";
import { DataGridTabel } from "../DataGridTabel/DataGridTabel";
import { ColumnText } from "../DataGridTabel/ColumnText";
import { ColumnButton } from "../DataGridTabel/ColumnButton";
import { HeaderGridTabel } from "../DataGridTabel/HeaderGridTabel";
import HomeWorkIcon from "@mui/icons-material/HomeWork";
import {
  dataClickItem,
  methodRequestStatus,
  openFilterState,
  updateDataState,
} from "../../recoil/global";
import {
  confirmModalState,
  openModalDelete,
  openModalInfo,
  openModalShow,
} from "../../recoil/modal";
import ModalDelete from "./Modal/ModalDelete";
import { useState } from "react";
import { dataTabelState } from "../../recoil/Tabel";
import {
  selectedCreatedFromDateState,
  selectedCreatedToDateState,
  selectedIsViewState,
  selectedPaymentMethodState,
  selectedPaymentStatus,
  selectedStatusState,
} from "../../recoil/select";
import { GetOrderByIdData } from "../../services/api/order";
import { useLocation, useNavigation } from "react-router-dom";
import EmailIcon from "@mui/icons-material/Email";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";

import moment from "moment";
import SelectCity from "../Selects/SelectCity";
import { GetWholeSaleData } from "../../services/api/wholeSale";
import {
  PatchUser,
  SetAdminUser,
  UnsetAdminUser,
} from "../../services/api/user";

import { PostTransactions } from "../../services/api/transactions";
import { GetOrderLineData } from "../../services/api/order";

import { toast } from "react-toastify";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import PermIdentityOutlinedIcon from "@mui/icons-material/PermIdentityOutlined";
import OutlinedFlagOutlinedIcon from "@mui/icons-material/OutlinedFlagOutlined";
import PaymentsOutlinedIcon from "@mui/icons-material/PaymentsOutlined";
import LocalPhoneOutlinedIcon from "@mui/icons-material/LocalPhoneOutlined";
import EventOutlinedIcon from "@mui/icons-material/EventOutlined";
import ModalShow from "./Modal/ModalShow";

import { useReactToPrint } from "react-to-print";
import * as XLSX from "xlsx";
import jsPDF from "jspdf";
import "jspdf-autotable";
import copy from "clipboard-copy";

export default function UserInfo(props) {
  const [userData, setUserData] = useState(props?.data);
  const [addToWallet, setAddToWallet] = useState({});

  const [wholesaleData, setWholesaleData] = useState({
    data: [],
    total: 0,
    loading: false,
  });
  const [serials, setSerials] = useState([]);
  const [tableData, setTableData] = useState();

  const [data, setData] = useRecoilState(dataTabelState);

  const [updateData, setUpdateData] = useRecoilState(updateDataState);
  const setDataItem = useSetRecoilState(dataClickItem);
  const setOpenModal = useSetRecoilState(openModalInfo);
  const setOpenShowModal = useSetRecoilState(openModalShow);
  const setOpenDelete = useSetRecoilState(openModalDelete);
  const setMethodRequest = useSetRecoilState(methodRequestStatus);

  const contentRef = useRef();

  const handlePrint = useReactToPrint({
    contentRef,
  });

  useEffect(() => {
    const newData = data.data.map((item) => {
      const lines =
        item?.order_line_keys.length > 0
          ? item?.order_line_keys.map((it) => {
              return {
                ProductName: item?.product_name,
                DateOfPurches: item?.created?.split("T")[0],
                Price: item.unit_price,
                PaymentMethod: item.payment_method,
                Quantity: 1,
                Serial: it.key_serial,
              };
            })
          : [
              {
                ProductName: item?.product_name,
                DateOfPurches: item?.created?.split("T")[0],
                Price: item.sub_total,
                PaymentMethod: item.payment_method,
                Quantity: item.quantity,
                Serial: "not key",
              },
            ];
      return lines;
    });

    const flatData = newData.flat();
    setTableData(flatData);
  }, [data.data]);

  const exportToExcel = () => {
    const ws = XLSX.utils.json_to_sheet(tableData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Data");
    XLSX.writeFile(
      wb,
      `${new Date().toISOString().split("T")[0]}/${
        userData?.username || ""
      }/order-items.xlsx`
    );
  };

  const exportToPDF = () => {
    const doc = new jsPDF();

    // Add title
    doc.text(
      "                       Original Software | Admin Dashboard Template",
      14,
      10
    );

    // Define table data
    const tableData = [
      [
        "Product Name",
        "date of purchase",
        "Price",
        "payment Method",
        "quantity",
        "serial",
      ],
      ...tableData.map((item) => [
        item.ProductName,
        item.DateOfPurches,
        item.Price,
        item.PaymentMethod,
        item.Quantity,
        item.Serial,
      ]),
    ];

    // Generate table
    doc.autoTable({
      head: [tableData[0]], // Table headers
      body: tableData.slice(1), // Table rows
      startY: 20, // Position below title
      styles: {
        fontSize: 10, // Default font size for table
        cellPadding: 0, // Cell padding
        halign: "center", // Text alignment
        valign: "middle", // Vertical alignment
      },
      headStyles: {
        fontSize: 11, // Font size for the header
        minCellHeight: 11, // Custom header row height
        halign: "center", // Center-align header text
        fillColor: [45, 65, 84], // Light gray background color for headers
      },
      columnStyles: {
        0: { cellWidth: 35, minCellHeight: 10 }, // OrderID column width
        1: { cellWidth: 25 }, // Customer Name column width
        2: { cellWidth: 25 }, // Email column width
        3: { cellWidth: 20 }, // Status column width
        4: { cellWidth: 25 }, // Product column width
        5: { cellWidth: 45 }, // Date column width
      },
    });

    // Save the PDF
    doc.save(
      `${new Date().toISOString().split("T")[0]}/order-notifications.pdf`
    );
  };

  const copyToClipboard = () => {
    copy(JSON.stringify(tableData));
    toast.success("Data copied to clipboard");
  };

  const handelShow = (data) => {
    console.log("show");
    setOpenShowModal(true);
    setSerials(data);
    setMethodRequest("remove-footer");
  };

  const transformStatus = (status) => {
    return status
      .toLowerCase()
      .split("_")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };

  const fetchWholeSaleData = () => {
    setWholesaleData((old) => ({
      ...old,
      loading: true,
      data: [],
    }));
    GetWholeSaleData(0, 10000, "")
      .then((res) => {
        setWholesaleData((old) => ({
          ...old,
          data: res?.results,
          total: res?.count,
          loading: false,
        }));
      })
      .catch(() => {
        setWholesaleData({ ...data, loading: false });
      });
  };

  const inputs = [
    {
      header: "First Name",
      name: "first_name",
      placeHolder: "First Name",
      type: "text",
    },
    {
      header: "Last Name",
      name: "last_name",
      placeHolder: "Last Name",
      type: "text",
    },
    {
      header: "Phone",
      name: "phone",
      placeHolder: "Phone",
      type: "text",
    },
    {
      header: "Email",
      name: "email",
      placeHolder: "Email",
      type: "email",
    },
    {
      header: "DOB",
      name: "dob",
      placeHolder: "Date of Birth",
      type: "date",
    },
  ];

  const about = [
    {
      icon: <AccountCircleOutlinedIcon />,
      title: "Full Name: ",
      value: `${props?.data?.first_name} ${props?.data?.last_name}`,
    },
    {
      icon: <CheckOutlinedIcon />,
      title: "Status: ",
      value: props?.data?.is_active ? "Active" : "Inactive",
    },
    {
      icon: <PermIdentityOutlinedIcon />,
      title: "Customer type: ",
      value: props?.data?.is_superuser
        ? "Admin"
        : props?.data?.is_wholesale
        ? "Wholesale " + props?.data?.wholesale_type
        : "Retails",
    },
    {
      icon: <OutlinedFlagOutlinedIcon />,
      title: "city: ",
      value: props?.data?.city ? transformStatus(props?.data?.city) : "N/A",
    },
    {
      icon: <LocalPhoneOutlinedIcon />,
      title: "Phone: ",
      value: props?.data?.phone ? props?.data?.phone : "N/A",
    },
    {
      icon: <EventOutlinedIcon />,
      title: "Account Created at:",
      value: props?.data?.created
        ? moment(props?.data?.created).format("MMM D YYYY, h:mm a")
        : "N/A",
    },
  ];

  const citys = [
    "al_anbar",
    "al_basra",
    "al_muthanna",
    "an_najaf",
    "al_qadisiyah",
    "al_sulaymaniyah",
    "babil",
    "baghdad",
    "dhi_qar",
    "dyala",
    "dohuk",
    "erbil",
    "karbala",
    "kirkuk",
    "maysan",
    "ninawa",
    "salah_ad_din",
    "wasit",
  ];

  const updateUser = () => {
    const id = props?.data?.pk;
    const data = userData;
    delete data.pk;
    delete data.username;
    console.log("props.data", props.data);
    id &&
      PatchUser(id, userData)
        .then((res) => {
          if (userData?.is_superuser && !props?.data?.is_superuser) {
            UnsetAdminUser(id)
              .then((res) => {
                console.log(res);
                toast.success("Edit successfully");
                setUpdateData(Math.random());
              })
              .catch((err) => {
                console.log(err);
                if (err.response) {
                  const errorData = err.response.data;

                  if (
                    typeof errorData === "object" &&
                    !Array.isArray(errorData)
                  ) {
                    // If error data is an object, iterate over its keys
                    Object.keys(errorData).forEach((key) => {
                      const messages = errorData[key];
                      if (Array.isArray(messages)) {
                        messages.forEach((message) => {
                          toast.error(key + ": " + message);
                        });
                      } else {
                        toast.error(key + ": " + messages);
                      }
                    });
                  } else if (typeof errorData.message === "string") {
                    // If there's a 'message' field, display it directly
                    toast.error(errorData.message);
                  } else {
                    // Handle other types of error data as needed
                    toast.error("Unexpected error occurred");
                  }
                } else {
                  // If there's no response from the server
                  toast.error("Check your internet connection");
                }
              });
          } else if (!userData?.is_superuser && props?.data?.is_superuser) {
            SetAdminUser(id)
              .then((res) => {
                toast.success("Added successfully");
                setUpdateData(Math.random());
              })
              .catch((err) => {
                console.log(err);
                if (err.response) {
                  const errorData = err.response.data;

                  if (
                    typeof errorData === "object" &&
                    !Array.isArray(errorData)
                  ) {
                    // If error data is an object, iterate over its keys
                    Object.keys(errorData).forEach((key) => {
                      const messages = errorData[key];
                      if (Array.isArray(messages)) {
                        messages.forEach((message) => {
                          toast.error(key + ": " + message);
                        });
                      } else {
                        toast.error(key + ": " + messages);
                      }
                    });
                  } else if (typeof errorData.message === "string") {
                    // If there's a 'message' field, display it directly
                    toast.error(errorData.message);
                  } else {
                    // Handle other types of error data as needed
                    toast.error("Unexpected error occurred");
                  }
                } else {
                  // If there's no response from the server
                  toast.error("Check your internet connection");
                }
              });
          } else {
            toast.success("Edit successfully");
            setUpdateData(Math.random());
          }
        })
        .catch((err) => {
          if (err.response) {
            const errorData = err.response.data;

            if (typeof errorData === "object" && !Array.isArray(errorData)) {
              // If error data is an object, iterate over its keys
              Object.keys(errorData).forEach((key) => {
                const messages = errorData[key];
                if (Array.isArray(messages)) {
                  messages.forEach((message) => {
                    toast.error(key + ": " + message);
                  });
                } else {
                  toast.error(key + ": " + messages);
                }
              });
            } else if (typeof errorData.message === "string") {
              // If there's a 'message' field, display it directly
              toast.error(errorData.message);
            } else {
              // Handle other types of error data as needed
              toast.error("Unexpected error occurred");
            }
          } else {
            // If there's no response from the server
            toast.error("Check your internet connection");
          }
        });
  };

  const updateWallet = () => {
    const data = {
      user: props?.data?.pk,
      transaction_type: "deposit",
      ...addToWallet,
    };

    if (!data.user || !data.amount) {
      toast.error("please add an amount ");
      return;
    }

    PostTransactions(data)
      .then((res) => {
        toast.success("Amount add");
        setUserData({
          ...userData,
          amount: userData?.wallet_balance
            ? userData?.wallet_balance + data.amount
            : data.amount,
        });
        setUpdateData(Math.random());
      })
      .catch((err) => {
        if (err.response) {
          const errorData = err.response.data;

          if (typeof errorData === "object" && !Array.isArray(errorData)) {
            // If error data is an object, iterate over its keys
            Object.keys(errorData).forEach((key) => {
              const messages = errorData[key];
              if (Array.isArray(messages)) {
                messages.forEach((message) => {
                  toast.error(key + ": " + message);
                });
              } else {
                toast.error(key + ": " + messages);
              }
            });
          } else if (typeof errorData.message === "string") {
            // If there's a 'message' field, display it directly
            toast.error(errorData.message);
          } else {
            // Handle other types of error data as needed
            toast.error("Unexpected error occurred");
          }
        } else {
          // If there's no response from the server
          toast.error("Check your internet connection");
        }
      });
  };

  useEffect(() => {
    fetchWholeSaleData();
  }, []);

  useEffect(() => {
    console.log("userData", userData);
    setUserData(props?.data);
  }, [props?.data]);

  const fetchData = () => {
    setData((old) => ({
      ...old,
      loading: true,
      data: [],
    }));

    props?.data?.pk &&
      GetOrderLineData(data.filters.skip, data.filters.take, props?.data?.pk)
        .then((res) => {
          setData((old) => ({
            ...old,
            data: res.results,
            total: res?.count,
            loading: false,
          }));
        })
        .catch((err) => {
          console.log(err);
          setData({ ...data, data: [], loading: false });
        });
  };

  useEffect(() => {
    fetchData();
  }, [userData?.pk]);

  const columns = [
    {
      field: "product_name",
      headerName: "Product Name",
      headerClassName: "grid--header",

      minWidth: 340,
      renderCell: (params) => {
        return (
          <Box
            display={"flex"}
            alignItems={"center"}
            gap={2}
            sx={{
              textDecoration: "none",
              overflowX: "auto",
              scrollbarWidth: "none",
              width: "100%",
            }}
            color={"#fff"}
          >
            <ColumnText
              text={
                params?.row?.product_name ? params.row?.product_name : "N/A"
              }
              sx={{
                fontWeight: "400 !important",
              }}
              variant="subtitle1"
            />
          </Box>
        );
      },
    },
    {
      field: "created",
      headerName: "Date of purchase",
      headerClassName: "grid--header",

      minWidth: 200,
      renderCell: (params) => {
        return (
          <Box
            display={"flex"}
            alignItems={"center"}
            gap={2}
            sx={{
              textDecoration: "none",
              overflowX: "auto",
              scrollbarWidth: "none",
              width: "100%",
            }}
            color={"#fff"}
          >
            <ColumnText
              text={
                params.row?.created
                  ? moment(params.row?.created).format("MMM D YYYY, h:mm a")
                  : "N/A"
              }
              variant="subtitle1"
              sx={{
                fontWeight: "400 !important",
              }}
            />
          </Box>
        );
      },
    },
    {
      field: "sub_total",
      headerName: "Price",
      headerClassName: "grid--header",

      minWidth: 100,
      renderCell: (params) => {
        return (
          <Box
            display={"flex"}
            alignItems={"center"}
            gap={2}
            sx={{
              textDecoration: "none",
              overflowX: "auto",
              scrollbarWidth: "none",
              width: "100%",
            }}
            color={"#fff"}
          >
            <ColumnText
              text={params.row?.sub_total ? params.row.sub_total : "N/A"}
              variant="subtitle1"
              sx={{
                fontWeight: "400 !important",
              }}
            />
          </Box>
        );
      },
    },
    {
      field: "payment_method",
      headerName: "Payment Method",
      headerClassName: "grid--header",

      minWidth: 200,
      renderCell: (params) => {
        return (
          <Box
            display={"flex"}
            alignItems={"center"}
            gap={2}
            sx={{
              textDecoration: "none",
              overflowX: "auto",
              scrollbarWidth: "none",
              width: "100%",
            }}
            color={"#fff"}
          >
            <ColumnText
              text={
                params.row?.payment_method
                  ? params.row.payment_method
                      .replace(/_/g, " ")
                      .replace(
                        /\w\S*/g,
                        (txt) =>
                          txt.charAt(0).toUpperCase() +
                          txt.slice(1).toLowerCase()
                      )
                  : "N/A"
              }
              variant="subtitle1"
              sx={{
                fontWeight: "400 !important",
              }}
            />
          </Box>
        );
      },
    },
    {
      field: "quantity",
      headerName: "Qauntity",
      headerClassName: "grid--header",

      minWidth: 100,
      renderCell: (params) => {
        return (
          <Box
            display={"flex"}
            alignItems={"center"}
            gap={2}
            sx={{
              textDecoration: "none",
              overflowX: "auto",
              scrollbarWidth: "none",
              width: "100%",
            }}
            color={"#fff"}
          >
            <ColumnText
              text={params.row?.quantity ? params.row.quantity : "N/A"}
              variant="subtitle1"
              sx={{
                fontWeight: "400 !important",
              }}
            />
          </Box>
        );
      },
    },

    {
      field: "order_line_keys",
      headerName: "Serial",
      headerClassName: "grid--header",

      minWidth: 150,
      renderCell: (params) => {
        return (
          <Box
            display={"flex"}
            alignItems={"center"}
            gap={2}
            sx={{
              textDecoration: "none",
              cursor:
                params.row?.order_line_keys?.length > 1 ? "pointer" : "text",
              overflowX: "auto",
              scrollbarWidth: "none",
              width: "100%",
            }}
            color={"#fff"}
            onClick={() => {
              params.row?.order_line_keys?.length > 1
                ? handelShow(params.row?.order_line_keys)
                : console.log("no show");
            }}
          >
            <ColumnText
              text={
                params.row?.order_line_keys?.length == 1
                  ? params.row.order_line_keys[0].key_serial
                  : params.row?.order_line_keys?.length > 1
                  ? "Show serials"
                  : "Not Key product"
              }
              variant="subtitle1"
              sx={{
                fontWeight: "400 !important",
                color:
                  params.row?.order_line_keys?.length > 1
                    ? "#0d6efd !important"
                    : "#fff",
              }}
            />
          </Box>
        );
      },
    },
  ];

  return (
    userData && (
      <Box
        sx={{
          px: 4,
          ".user-hover": {
            color: "#0d6efd",
            cursor: "pointer",
            "&:hover": {
              color: "#0a58ca",
            },
          },
        }}
      >
        <ModalShow data={serials} />
        <Grid container>
          <Grid
            item
            sm={12}
            sx={{
              mb: 4,
            }}
          >
            <Box
              sx={{
                height: 115,
                background: "#080c2a",
                width: "100%",
                borderRadius: "0.375rem",
                display: "flex",
                justifyContent: "center",
                overflow: "visible",
                alignItems: "center",
              }}
            >
              <Box>
                <Avatar
                  sx={{
                    borderRadius: "9999999px",
                    height: 140,
                    width: 140,
                    background:
                      "linear-gradient(310deg, #ee0979, #ff6a00) !important",
                    p: 1,
                    fontSize: "3.5rem",
                  }}
                  sizes="small"
                  alt={props?.data?.first_name}
                  src={"https://www.w3schools.com/"}
                />
              </Box>
            </Box>
          </Grid>

          <Grid
            item
            xl={8}
            lg={6}
            sx={{
              pr: 3,
            }}
          >
            <Box
              sx={{
                background:
                  "linear-gradient(to right, #7928ca, #ff0080, #04e09a, #e0d504)",
                pt: "5px",
                borderRadius: "0.375rem",
              }}
            >
              <Box
                sx={{
                  background: "#080c2a",

                  width: "100%",
                  py: 3,
                  px: 4,
                  borderRadius: "0.375rem",
                }}
              >
                <Typography
                  color={"#fff"}
                  fontSize={"1.25rem"}
                  fontWeight={700}
                >
                  Profile
                </Typography>
                <Grid container>
                  {inputs.map((item, index) => (
                    <Grid
                      item
                      sm={6}
                      mt={2}
                      sx={{
                        pr: index % 2 == 0 ? 2 : 0,
                        mt: "1.5rem",
                        "& .css-1nzvwbd-MuiInputBase-root-MuiInput-root::before":
                          {
                            borderBottom: "none",
                          },
                        "& input[type='date']::-webkit-calendar-picker-indicator":
                          {
                            color: "red" /* Change the icon color */,
                            background:
                              "transparent" /* Change the background of the icon */,
                            cursor: "pointer" /* Change cursor style */,
                          },

                        /* For Firefox */
                        ' & input[type="date"]': {
                          appearance:
                            "none" /* Disable the default appearance */,
                          position: "relative",
                        },

                        '& input[type="date"]::after': {
                          content: '"📅"' /* Add a custom icon (optional) */,
                          position: "absolute",
                          right: "15px",
                          top: "50%",
                          transform: "translateY(-50%)",
                          color: "red" /* Icon color */,
                          pointerEvents:
                            "none" /* Allow clicks to pass through to the input */,
                        },
                      }}
                    >
                      <Typography
                        color={"#fff"}
                        fontSize={"14px"}
                        fontWeight={400}
                        marginBottom={"0.5rem"}
                      >
                        {item?.header}
                      </Typography>
                      <input
                        value={userData[item?.name] ? userData[item?.name] : ""}
                        placeholder={item.placeHolder || ""}
                        type={item.type}
                        onChange={(e) =>
                          setUserData({
                            ...userData,
                            [item.name]: e.target.value,
                          })
                        }
                        style={{
                          background: "#0f1535",
                          color: "#fff",
                          border: "1px solid #ffffff26",
                          borderRadius: "0.375rem",
                          width: "100%",
                          padding: "0.6rem .75rem",
                          fontSize: "1rem",
                        }}
                      />
                    </Grid>
                  ))}
                  <Grid
                    item
                    sm={6}
                    mt={2}
                    sx={{
                      mt: "1.5rem",
                    }}
                  >
                    <Typography
                      color={"#fff"}
                      fontSize={"14px"}
                      fontWeight={400}
                      marginBottom={"0.5rem"}
                    >
                      City
                    </Typography>
                    <Select
                      size="small"
                      labelId="status-label"
                      id="status-select"
                      onChange={(e) =>
                        setUserData({ ...userData, city: e.target.value })
                      }
                      value={userData.city}
                      sx={{
                        background: "#0f1535",
                        color: "#fff",
                        border: "1px solid #ffffff26",
                        borderRadius: "0.375rem",
                        width: "100%",
                        padding: "1px ",
                        svg: {
                          fill: "#fff",
                          color: "#fff",
                        },
                      }}
                    >
                      {citys?.map((status, index) => (
                        <MenuItem key={index} value={status}>
                          {transformStatus(status)}
                        </MenuItem>
                      ))}
                    </Select>
                  </Grid>
                  <Grid
                    item
                    sm={12}
                    mt={2}
                    sx={{
                      mt: "1.5rem",
                    }}
                  >
                    <Typography
                      color={"#fff"}
                      fontSize={"14px"}
                      fontWeight={400}
                      marginBottom={"0.5rem"}
                    >
                      Address
                    </Typography>
                    <textarea
                      type="text"
                      value={userData.address}
                      rows="11"
                      spellCheck={false}
                      placeholder={"Address ..."}
                      onChange={(e) =>
                        setUserData({
                          ...userData,
                          address: e.target.value,
                        })
                      }
                      style={{
                        // minHeight: "120px",
                        background: "#0f1535",
                        color: "#fff",
                        border: "1px solid #ffffff26",
                        borderRadius: "0.375rem",
                        width: "100%",
                        padding: "1rem .75rem",
                        fontSize: "1rem",
                        resize: "vertical",
                      }}
                    />
                  </Grid>
                  <Grid
                    container
                    sm={12}
                    sx={{
                      mt: "1.5rem",
                      mb: "10px",
                    }}
                  >
                    <Grid
                      item
                      sm={3}
                      sx={{
                        display: "flex",
                        alignItems: "start",

                        gap: 1,
                      }}
                    >
                      <input
                        type="checkbox"
                        onChange={(e) =>
                          setUserData({
                            ...userData,
                            is_superuser: e.target.checked,
                          })
                        }
                        value={userData.is_superuser}
                        style={{
                          background: "#0f1535",
                          color: "#fff",
                          borderRadius: "0.375rem",

                          border: "1px solid #ffffff26",
                          accentColor: "#0dcaf0",
                        }}
                      />
                      <Typography
                        sx={{
                          color: "#fff",
                          fontSize: "14px",
                          fontWeight: "400",
                        }}
                      >
                        Admin
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      sm={3}
                      sx={{
                        display: "flex",
                        alignItems: "start",

                        gap: 1,
                      }}
                    >
                      <input
                        type="checkbox"
                        onChange={(e) =>
                          setUserData({
                            ...userData,
                            is_active: e.target.checked,
                          })
                        }
                        value={userData.is_active}
                        style={{
                          background: "#0f1535",
                          color: "#fff",
                          borderRadius: "0.375rem",

                          border: "1px solid #ffffff26",
                          accentColor: "#0dcaf0",
                        }}
                      />
                      <Typography
                        sx={{
                          color: "#fff",
                          fontSize: "14px",
                          fontWeight: "400",
                        }}
                      >
                        Active account
                      </Typography>
                    </Grid>
                    <Grid
                      sx={{
                        display: "flex",
                        alignItems: "start",
                        gap: 1,
                      }}
                      item
                      sm={3}
                    >
                      <input
                        type="checkbox"
                        onChange={(e) =>
                          setUserData({
                            ...userData,
                            is_wholesale: e.target.checked,
                          })
                        }
                        value={userData.is_wholesale}
                        checked={userData.is_wholesale}
                        id="wholesale"
                        style={{
                          background: "#0f1535",
                          color: "#fff",
                          borderRadius: "0.375rem",
                          border: "1px solid #ffffff26",
                          accentColor: "#0dcaf0",
                        }}
                      />
                      <Typography
                        sx={{
                          color: "#fff",
                          fontSize: "14px",
                          fontWeight: "400",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          setUserData({
                            ...userData,
                            is_wholesale: !userData.is_wholesale,
                          });
                        }}
                      >
                        Whole salor
                      </Typography>
                    </Grid>
                    {userData.is_wholesale && (
                      <Grid
                        item
                        sm={3}
                        sx={{
                          px: 2,
                        }}
                      >
                        <Select
                          size="small"
                          labelId="status-label"
                          id="status-select"
                          onChange={(e) =>
                            setUserData({
                              ...userData,
                              wholesale_type: e.target.value,
                            })
                          }
                          value={userData.wholesale_type}
                          sx={{
                            background: "#0f1535",
                            color: "#fff",
                            border: "1px solid #ffffff26",
                            borderRadius: "0.375rem",
                            width: "100%",
                            padding: "1px ",
                            svg: {
                              fill: "#fff",
                              color: "#fff",
                            },
                          }}
                        >
                          {wholesaleData.data?.map((status, index) => (
                            <MenuItem key={index} value={status.id}>
                              {transformStatus(status.title)}
                            </MenuItem>
                          ))}
                        </Select>
                      </Grid>
                    )}
                  </Grid>
                  <Grid
                    sm={12}
                    sx={{
                      mt: "1.5rem",
                    }}
                  >
                    <Button
                      variant="contained"
                      sx={{
                        background:
                          "linear-gradient(310deg, #7928ca, #ff0080) !important",
                        minHeight: 40,
                        height: "fit-content",
                        mx: 1,
                        fontWeight: "500",
                      }}
                      onClick={() => updateUser()}
                    >
                      Update Profile
                    </Button>
                    <Button
                      variant="contained"
                      sx={{
                        minHeight: 40,
                        height: "fit-content",
                        mx: 1,
                        fontWeight: "500",
                        background: "#0f1535",
                        color: "#fff",
                        border: "1px solid #ffffff26",
                        borderRadius: "0.375rem",
                      }}
                      onClick={() => {
                        setUserData(props?.data);
                      }}
                    >
                      Reset
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Grid>
          <Grid item xl={4} lg={6}>
            <Box
              sx={{
                borderRadius: "0.375rem",
                background: "#080c2a",
              }}
            >
              <Box
                sx={{
                  background: "#080c2a",

                  width: "100%",
                  py: 3,
                  px: 2,
                  borderRadius: "0.375rem",
                }}
              >
                <Typography
                  color={"#fff"}
                  fontSize={"1.25rem"}
                  fontWeight={700}
                >
                  About
                </Typography>
                <Box
                  sx={{
                    mt: 2,
                    display: "flex",
                    flexDirection: "column",
                    gap: 2.5,
                  }}
                >
                  {about.map((item) => (
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "start",
                        gap: 3,
                        color: "#fff",
                      }}
                    >
                      {item.icon}
                      <Typography>
                        {item.title} {item.value}
                      </Typography>
                    </Box>
                  ))}
                </Box>
              </Box>
            </Box>
            <Box
              sx={{
                borderRadius: "0.375rem",
                background: "#080c2a",
                mt: 3,
              }}
            >
              <Box
                sx={{
                  background: "#080c2a",

                  width: "100%",
                  py: 3,
                  px: 2,
                  borderRadius: "0.375rem",
                }}
              >
                <Typography
                  color={"#fff"}
                  fontSize={"1.25rem"}
                  fontWeight={700}
                >
                  Update wallet
                </Typography>
                <Grid container>
                  <Grid item sm={6} mt={2} pr={1}>
                    <Typography
                      color={"#fff"}
                      fontSize={"14px"}
                      fontWeight={400}
                      marginBottom={"0.5rem"}
                    >
                      Total cash in walt
                    </Typography>
                    <input
                      value={props?.data?.wallet_balance}
                      placeholder={"Total cash"}
                      disabled
                      style={{
                        background: "#0f1535",
                        color: "#fff",
                        border: "1px solid #ffffff26",
                        borderRadius: "0.375rem",
                        width: "100%",
                        padding: "0.6rem .75rem",
                        fontSize: "1rem",
                      }}
                    />
                  </Grid>
                  <Grid item sm={6} mt={2} pl={1}>
                    <Typography
                      color={"#fff"}
                      fontSize={"14px"}
                      fontWeight={400}
                      marginBottom={"0.5rem"}
                    >
                      Maximum debt
                    </Typography>
                    <input
                      value={
                        props?.data?.is_wholesale
                          ? data.find(
                              (item) => item.id == props?.data?.wholesale_type
                            )
                          : 0
                      }
                      placeholder={"Total cash"}
                      disabled
                      style={{
                        background: "#0f1535",
                        color: "#fc185a",
                        border: "1px solid #ffffff26",
                        borderRadius: "0.375rem",
                        width: "100%",
                        padding: "0.6rem .75rem",
                        fontSize: "1rem",
                      }}
                    />
                  </Grid>
                  <Grid item sm={12} mt={2}>
                    <Typography
                      color={"#fff"}
                      fontSize={"14px"}
                      fontWeight={400}
                      marginBottom={"0.5rem"}
                    >
                      Add to walt
                    </Typography>
                    <input
                      value={addToWallet?.amount || ""}
                      placeholder={"Total cash"}
                      type="number"
                      inputMode="numeric"
                      onChange={(e) =>
                        setAddToWallet({
                          ...addToWallet,
                          amount: e.target.value,
                        })
                      }
                      onWheel={(e) => e.target.blur()}
                      style={{
                        background: "#0f1535",
                        color: "#fff",
                        border: "1px solid #ffffff26",
                        borderRadius: "0.375rem",
                        width: "100%",
                        padding: "0.6rem .75rem",
                        fontSize: "1rem",
                      }}
                    />
                  </Grid>
                  <Grid
                    item
                    sm={12}
                    mt={2}
                    sx={{
                      mt: "1.5rem",
                    }}
                  >
                    <Typography
                      color={"#fff"}
                      fontSize={"14px"}
                      fontWeight={400}
                      marginBottom={"0.5rem"}
                    >
                      Notes
                    </Typography>
                    <textarea
                      type="text"
                      value={addToWallet.description}
                      rows="1"
                      spellCheck={false}
                      placeholder={"Please add your note here"}
                      onChange={(e) =>
                        setUserData({
                          ...userData,
                          description: e.target.value,
                        })
                      }
                      style={{
                        // minHeight: "120px",
                        background: "#0f1535",
                        color: "#fff",
                        border: "1px solid #ffffff26",
                        borderRadius: "0.375rem",
                        width: "100%",
                        padding: "1rem .75rem",
                        fontSize: "1rem",
                        resize: "vertical",
                      }}
                    />
                  </Grid>
                  <Grid
                    sm={12}
                    sx={{
                      mt: "1.5rem",
                    }}
                  >
                    <Button
                      variant="contained"
                      sx={{
                        background:
                          "linear-gradient(310deg, #7928ca, #ff0080) !important",
                        minHeight: 40,
                        height: "fit-content",
                        mx: 1,
                        fontWeight: "500",
                      }}
                      onClick={() => updateWallet()}
                    >
                      Update wallet
                    </Button>
                    <Button
                      variant="contained"
                      sx={{
                        minHeight: 40,
                        height: "fit-content",
                        mx: 1,
                        fontWeight: "500",
                        background: "#0f1535",
                        color: "#fff",
                        border: "1px solid #ffffff26",
                        borderRadius: "0.375rem",
                      }}
                      onClick={() => {
                        setAddToWallet({});
                      }}
                    >
                      Reset
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box
              sx={{
                background:
                  "linear-gradient(to right, #7928ca, #ff0080, #04e09a, #e0d504)",
                pt: "5px",
                borderRadius: "0.375rem 0.375rem 0px 0px",
                mt: "1.5rem",
              }}
            ></Box>
            <Box
              sx={{
                position: "relative",
                zIndex: 10,
                px: 2,
                pt: 3,
                background: "#080d2a",
              }}
            >
              <Typography color={"#fff"} fontSize={"1.25rem"} fontWeight={700}>
                User Activities
              </Typography>
            </Box>
            <DataGridTabel
              columns={columns}
              data={data}
              setData={setData}
              cellHeight={45}
              height={"400px"}
              noMinWidth={true}
              exports={true}
              exportToExcel={exportToExcel}
              exportToPDF={exportToPDF}
              print={handlePrint}
              copy={copyToClipboard}
            />
          </Grid>
        </Grid>
        <div style={{ display: "none" }}>
          <div ref={contentRef}>
            <h2 style={{ textAlign: "center", width: "100%" }}>
              Original Software | Admin Dashboard Template
            </h2>
            <table
              style={{
                borderCollapse: "collapse",
                width: "100%",
                textAlign: "center",
              }}
            >
              <thead>
                {/* {
                     "Product Name",
                     "date of purchase",
                     "Price",
                     "payment Method",
                     "quantity",
                     "serial",
                } */}
                <tr style={{ backgroundColor: "#ccc" }}>
                  <th style={{ border: "1px solid #000", padding: "7px" }}>
                    Product name
                  </th>
                  <th style={{ border: "1px solid #000", padding: "7px" }}>
                    Date of purchase
                  </th>
                  <th style={{ border: "1px solid #000", padding: "7px" }}>
                    Price
                  </th>
                  <th style={{ border: "1px solid #000", padding: "7px" }}>
                    payment Method
                  </th>
                  <th style={{ border: "1px solid #000", padding: "7px" }}>
                    quantity
                  </th>
                  <th style={{ border: "1px solid #000", padding: "7px" }}>
                    serial
                  </th>
                </tr>
              </thead>
              <tbody>
                {tableData?.length > 0 &&
                  tableData.map((row) => (
                    <tr key={row.OrderID}>
                      <td
                        style={{
                          border: "1px solid #000",
                          padding: "7px",
                          maxWidth: "50px",
                        }}
                      >
                        {row.ProductName}
                      </td>
                      <td
                        style={{
                          border: "1px solid #000",
                          padding: "7px",
                          maxWidth: "150px",
                        }}
                      >
                        {row?.DateOfPurches}
                      </td>
                      <td
                        style={{
                          border: "1px solid #000",
                          padding: "7px",
                          maxWidth: "200px",
                        }}
                      >
                        {row?.Price}
                      </td>
                      <td
                        style={{
                          border: "1px solid #000",
                          padding: "7px",
                          maxWidth: "100px",
                        }}
                      >
                        {row.PaymentMethod}
                      </td>
                      <td
                        style={{
                          border: "1px solid #000",
                          padding: "7px",
                          maxWidth: "100px",
                        }}
                      >
                        {row.Quantity}
                      </td>
                      <td
                        style={{
                          border: "1px solid #000",
                          padding: "7px",
                          maxWidth: "100px",
                        }}
                      >
                        {row.Serial}
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      </Box>
    )
  );
}
