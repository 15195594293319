import React, { useState, useEffect } from "react";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { Box, Typography, Grid, Button } from "@mui/material";
import { dataClickItem, methodRequestStatus } from "../../recoil/global";
import { dataTabelState } from "../../recoil/Tabel";
import { GetProductData } from "../../services/api/product";
import DropDown from "../Others/DropDown";
import { Input, FormControl } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { v4 as uuidv4 } from "uuid";

export default function ProductRow({
  item,
  index,
  data,
  setProductsData,
  productsData,
}) {
  const [searchProduct, setSearchProduct] = useState("");
  const [productDropdown, setProductDropdown] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [productsList, setProductList] = useState([]);

  useEffect(() => {
    GetProductData(0, 25, searchProduct)
      .then((res) => {
        setProductList(res.results);
        setProductDropdown(searchProduct && res.results.length > 0);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [searchProduct]);
  return (
    <>
      <Grid item sm={0.5} border={"1px solid #ffffff26"}>
        <Typography color={"#fff"} textAlign={"center"} py={1}>
          {index + 1}
        </Typography>
      </Grid>
      <Grid item sm={3.5} border={"1px solid #ffffff26"}>
        <Box position={"relative"} display={"flex"} width={"100%"}>
          <FormControl fullWidth>
            <Input
              sx={{
                color: "#fff",
                borderBottom: "1px solid #ffffff26",
                mt: "10px",
                pb: "10px",
                width: "100%",
                input: {
                  px: 2,
                  pb: 1,
                  "-webkit-text-fill-color": "#fff !important",
                },
              }}
              name="user"
              autoComplete="User"
              value={searchProduct || data?.name}
              onChange={(e) => {
                setSearchProduct(e.target.value);
                setSelectedProduct(null);
                setProductsData(
                  productsData.map((it, i) => {
                    if (it.id === data.id) {
                      return {
                        ...it,
                        name: "",
                        totalQty: 0,
                        price: 0,
                        qty: 1,
                      };
                    } else {
                      return it;
                    }
                  })
                );
              }}
              autoFocus
            />
          </FormControl>
          <DropDown open={productDropdown} setOpen={setProductDropdown}>
            <Box
              sx={{
                maxHeight: "300px",
                minWidth: "100%",
                overflowY: "auto",
              }}
            >
              {productsList &&
                searchProduct &&
                productsList.length > 0 &&
                productsList.map((item, index) => {
                  return (
                    <Typography
                      key={index}
                      sx={{
                        color: "#fff",

                        cursor: "pointer",
                        padding: "10px",
                        borderBottom: "1px solid #ffffff26",
                        "&:hover": {
                          backgroundColor: "#ffffff26",
                        },
                      }}
                      onClick={() => {
                        setProductsData(
                          productsData.map((it, i) => {
                            if (it.id === data.id) {
                              return {
                                id: item.id,
                                name: item.name,
                                totalQty: item.qty,
                                price: item.price,
                                qty: 1,
                              };
                            } else {
                              return it;
                            }
                          })
                        );

                        setProductDropdown(false);
                        setSearchProduct("");
                      }}
                    >
                      {item.name} / {item.keys_validity} / {item.keys_users_count}
                    </Typography>
                  );
                })}
            </Box>
          </DropDown>
        </Box>
      </Grid>
      <Grid item sm={1.5} border={"1px solid #ffffff26"}>
        <Box position={"relative"} display={"flex"} width={"100%"}>
          <FormControl fullWidth>
            <Input
              sx={{
                color: "#fff",
                borderBottom: "1px solid #ffffff26",
                mt: "10px",
                pb: "10px",
                width: "100%",
                input: {
                  px: 2,
                  pb: 1,
                  "-webkit-text-fill-color": "#fff !important",
                },
              }}
              type="number"
              inputMode="numeric"
              name="quantity"
              autoComplete="quantity"
              value={data?.qty}
              onChange={(e) => {
                setProductsData(
                  productsData.map((it, i) => {
                    if (it.id === data.id) {
                      return {
                        ...it,
                        qty:
                          Number(e.target.value) <= data.totalQty &&
                          e.target.value > 0
                            ? Number(e.target.value)
                            : data.qty,
                      };
                    } else {
                      return it;
                    }
                  })
                );
              }}
            />
          </FormControl>
        </Box>
      </Grid>
      <Grid item sm={2.5} border={"1px solid #ffffff26"}>
        <Box position={"relative"} display={"flex"} width={"100%"}>
          <FormControl fullWidth>
            <Input
              sx={{
                color: "#fff",
                borderBottom: "1px solid #ffffff26",
                mt: "10px",
                pb: "10px",
                width: "100%",
                input: {
                  px: 2,
                  pb: 1,
                  "-webkit-text-fill-color": "#fff !important",
                },
              }}
              type="number"
              inputMode="numeric"
              name="quantity"
              autoComplete="quantity"
              value={data?.price}
              disabled
            />
          </FormControl>
        </Box>
      </Grid>
      <Grid item sm={2.5} border={"1px solid #ffffff26"}>
        <Box position={"relative"} display={"flex"} width={"100%"}>
          <FormControl fullWidth>
            <Input
              sx={{
                color: "#fff",
                borderBottom: "1px solid #ffffff26",
                mt: "10px",
                pb: "10px",
                width: "100%",
                input: {
                  px: 2,
                  pb: 1,
                  "-webkit-text-fill-color": "#fff !important",
                },
              }}
              type="number"
              inputMode="numeric"
              name="quantity"
              autoComplete="quantity"
              value={data?.qty * data?.price}
              disabled
            />
          </FormControl>
        </Box>
      </Grid>
      <Grid
        item
        sm={1.5}
        border={"1px solid #ffffff26"}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Button
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "#ff0000",
            border: "1px solid #ff0000",
            svg: {
              fill: "#fff",
            },
            "&:hover": {
              backgroundColor: "transparent",
              svg: {
                fill: "#ff0000",
              },
            },
          }}
          onClick={() => {
            setProductsData(productsData.filter((it, i) => it.id !== data.id));
          }}
        >
          <DeleteIcon />
        </Button>
      </Grid>
    </>
  );
}
