import { useEffect } from "react";
import { Avatar, Box } from "@mui/material";
import {
  useRecoilState,
  useRecoilValue,
  useResetRecoilState,
  useSetRecoilState,
} from "recoil";
import { DataGridTabel } from "../DataGridTabel/DataGridTabel";
import { ColumnText } from "../DataGridTabel/ColumnText";
import { ColumnButton } from "../DataGridTabel/ColumnButton";
import { HeaderGridTabel } from "../DataGridTabel/HeaderGridTabel";
import HomeWorkIcon from "@mui/icons-material/HomeWork";
import ModalInfo from "./Modal/ModalInfo";
import {
  dataClickItem,
  methodRequestStatus,
  openFilterState,
  updateDataState,
} from "../../recoil/global";
import {
  confirmModalState,
  openModalDelete,
  openModalInfo,
} from "../../recoil/modal";
import ModalDelete from "./Modal/ModalDelete";
import { useState } from "react";
import { dataTabelState } from "../../recoil/Tabel";
import Filters from "./Filters";
import {
  selectedStatusState,
  selectedCategoryState,
  selectedSubCategoryState,
  selectedTagsState,
} from "../../recoil/select";
import { GetProductData } from "../../services/api/product";
import { fontSize, fontWeight, maxWidth, minWidth } from "@mui/system";
import moment, { min } from "moment";
import { useLocation } from "react-router-dom";

export const Tabel = () => {
  const [data, setData] = useRecoilState(dataTabelState);
  const [updateData, setUpdateData] = useRecoilState(updateDataState);

  const [hasOpthions, setHasOptions] = useState(null);
  const [isOpthions, setIsOptions] = useState(null);
  const [disconted, setDisconted] = useState(null);
  const [status, setStatus] = useState(null);

  const selectedStatus = useRecoilValue(selectedStatusState);
  const state = useRecoilValue(openFilterState);
  const category = useRecoilValue(selectedCategoryState);
  const subCategory = useRecoilValue(selectedSubCategoryState);
  const tags = useRecoilValue(selectedTagsState);
  const openFilter = useRecoilValue(openFilterState);

  const resetConfirm = useResetRecoilState(confirmModalState);

  const setDataItem = useSetRecoilState(dataClickItem);
  const setOpenModal = useSetRecoilState(openModalInfo);
  const setOpenDelete = useSetRecoilState(openModalDelete);
  const setMethodRequest = useSetRecoilState(methodRequestStatus);

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const id = searchParams.get("id");

  useEffect(() => {
    setDataItem(null);
  }, [state]);

  useEffect(() => {
    id && handelEdit();
  }, [id]);

  const handelEdit = (data) => {
    setOpenModal(true);
    setDataItem(data);
    setMethodRequest("edit");
  };

  const handelDelete = (data) => {
    setOpenDelete(true);
    setDataItem(data);
    setMethodRequest("delete");
  };

  const columns = [
    {
      field: "name",
      headerName: "Name",
      headerClassName: "grid--header",
      flex: 1,
      minWidth: 400,
      renderCell: (params) => {
        return (
          <Box
            display={"flex"}
            alignItems={"center"}
            sx={{
              overflowX: "auto",
              scrollbarWidth: "none",
              height: "85px",
            }}
            gap={2}
          >
            {params?.row?.images?.length >= 1 && (
              <Avatar
                sx={{
                  borderRadius: 0,
                  height: "auto",
                  width: "70px",
                  objectFit: "contain",
                }}
                sizes="small"
                alt="Image"
                src={params?.row?.images[0]?.image_file}
              />
            )}
            <Box>
              <ColumnText
                text={params.row?.name ? params.row?.name : "N/A"}
                variant="subtitle1"
                fontWeight="bold"
              />
              <ColumnText
                text={
                  params.row?.category_name
                    ? "Category : " + params.row?.category_name
                    : "N/A"
                }
                variant="subtitle1"
                sx={{
                  color: "gray !important",
                  fontSize: "12px !important",
                  fontWeight: "400 !important",
                }}
              />
            </Box>
            {/* <ColumnText text={params.row?.job_title?.name_ar} name_ar="caption" color={"gray"} /> */}
          </Box>
        );
      },
    },

    {
      field: "qty",
      headerName: "Total",
      headerClassName: "grid--header",
      flex: 1,
      maxWidth: 100,
      renderCell: (params) => {
        return (
          <Box
            display={"flex"}
            alignItems={"center"}
            sx={{
              overflowX: "auto",
              scrollbarWidth: "none",
            }}
            gap={2}
          >
            <ColumnText
              text={params.row?.qty ? params.row?.qty : "0"}
              variant="subtitle1"
              fontWeight="bold"
            />
          </Box>
        );
      },
    },
    {
      field: "qty2",
      headerName: "Status",
      headerClassName: "grid--header",
      flex: 1,
      maxWidth: 100,

      renderCell: (params) => {
        return (
          <Box
            display={"flex"}
            alignItems={"center"}
            sx={{
              overflowX: "auto",
              scrollbarWidth: "none",
            }}
            gap={2}
          >
            <ColumnText
              text={
                params.row?.qty >
                params.row?.number_of_keys_to_send_notification
                  ? "Good"
                  : params.row?.number_of_keys_to_send_notification >=
                      params.row?.qty && params.row?.qty !== 0
                  ? "Poor"
                  : "Empty"
              }
              sx={{
                px: "0.65em !important",
                py: "0.35em !important",
                fontSize: "0.75em !important",
                borderRadius: "8px",
                background:
                  params.row?.qty >
                  params.row?.number_of_keys_to_send_notification
                    ? "linear-gradient( 310deg,#17ad37,#98ec2d) !important"
                    : params.row?.number_of_keys_to_send_notification >=
                        params.row?.qty && params.row?.qty !== 0
                    ? "linear-gradient(310deg, #f7971e, #ffd200) !important"
                    : "linear-gradient(310deg, #ee0979, #ff6a00) !important",
              }}
              variant="subtitle1"
              fontWeight="bold"
            />
          </Box>
        );
      },
    },
    {
      field: "price",
      headerName: "Price",
      headerClassName: "grid--header",
      flex: 1,
      minWidth: 250,
      renderCell: (params) => {
        return (
          <Box
            display={"flex"}
            alignItems={"center"}
            sx={{
              overflowX: "auto",
              scrollbarWidth: "none",
              background: "0f1535",
              borderRadius: "5px",
              border: "1px solid #ffffff26",
              px: "5px",
              py: "2px",
              width: "100%",
              display: "flex",
              justifyContent: "center",
            }}
            gap={2}
          >
            <ColumnText
              text={params.row?.price ? params.row?.price + " ID" : "N/A"}
              variant="subtitle1"
              fontWeight="bold"
            />
            {params.row?.old_price && (
              <ColumnText
                text={
                  params.row?.old_price
                    ? "new price " + params.row?.old_price + " ID"
                    : "N/A"
                }
                variant="subtitle1"
                fontWeight="bold"
              />
            )}
          </Box>
        );
      },
    },
    {
      field: "status_display",
      headerName: "Status",
      headerClassName: "grid--header",
      flex: 1,
      renderCell: (params) => {
        return (
          <Box
            display={"flex"}
            alignItems={"center"}
            sx={{
              overflowX: "auto",
              scrollbarWidth: "none",
            }}
            gap={2}
          >
            <ColumnText
              text={
                params.row?.status_display === "active"
                  ? "Published"
                  : params.row?.status_display === "inactive"
                  ? "Draft"
                  : "on Discount"
              }
              sx={{
                color:
                  params.row?.status_display === "active"
                    ? "#02c27a !important"
                    : params.row?.status_display === "inactive"
                    ? "#fc185a !important"
                    : "#ffc107 !important",
              }}
              variant="subtitle1"
              fontWeight="bold"
            />
          </Box>
        );
      },
    },
    {
      field: "modified",
      headerName: "Last Change",
      headerClassName: "grid--header",
      flex: 1,
      maxWidth: 200,
      renderCell: (params) => {
        return (
          <Box
            display={"flex"}
            alignItems={"center"}
            sx={{
              overflowX: "auto",
              scrollbarWidth: "none",
            }}
            gap={2}
          >
            <ColumnText
              text={
                moment(params.row?.modified).format("MMM D YYYY, h:mm a")
                  ? moment(params.row?.modified).format("MMM D YYYY, h:mm a")
                  : "0"
              }
              variant="subtitle1"
              fontWeight="bold"
            />
          </Box>
        );
      },
    },
    {
      field: "created",
      headerName: "Created",
      headerClassName: "grid--header",
      flex: 1,
      maxWidth: 200,
      renderCell: (params) => {
        return (
          <Box
            display={"flex"}
            alignItems={"center"}
            sx={{
              overflowX: "auto",
              scrollbarWidth: "none",
            }}
            gap={2}
          >
            <ColumnText
              text={
                params.row?.created
                  ? moment(params.row?.created).format("MMM D YYYY, h:mm a")
                  : "N/A"
              }
              variant="subtitle1"
              fontWeight="bold"
            />
          </Box>
        );
      },
    },
    {
      field: "operations",
      headerName: "Operations",
      flex: 1,
      headerClassName: "grid--header",
      renderCell: (params) => {
        return (
          <Box
            display={"flex"}
            alignItems={"center"}
            sx={{
              overflowX: "auto",
              scrollbarWidth: "none",
            }}
          >
            <ColumnButton
              onClick={() => handelEdit(params.row)}
              type={"edit"}
            />
            <Box
              sx={{
                width: "1px",
                height: "20px",
                backgroundColor: "#f0f0f0",
                margin: "0 10px",
              }}
            ></Box>
            <ColumnButton
              onClick={() => handelDelete(params.row)}
              type={"delete"}
            />
          </Box>
        );
      },
    },
  ];

  const fetchData = () => {
    setData((old) => ({
      ...old,
      loading: true,
      data: [],
    }));

    GetProductData(
      data.filters.skip,
      data.filters.take,
      data.filters.search,
      category,
      subCategory,
      tags,
      hasOpthions,
      isOpthions,
      disconted,
      status
    )
      .then((res) => {
        console.log("Dtest", res);
        setData((old) => ({
          ...old,
          data: res.results.map((item) => ({
            ...item,
            qty2: item.qty,
          })),
          total: res?.count,
          loading: false,
        }));
      })
      .catch(() => {
        setData({ ...data, loading: false });
      });
  };

  let filters = data.filters.page || data.filters.take || data.filters.skip;

  useEffect(() => {
    fetchData();
    // console.log("data", data.data.length > 0);
  }, [updateData, hasOpthions, isOpthions, disconted]);

  useEffect(() => {
    openFilter.right && fetchData();
  }, [filters, category, subCategory, tags]);

  return (
    <>
      <Box>
        {!id && (
          <HeaderGridTabel
            title="Products"
            icon={
              <HomeWorkIcon
                size={25}
                className="color-primary"
                color={"#08001e"}
              />
            }
            btn={"Add Product"}
            fetchData={fetchData}
            isFilter={true}
            data={data?.data}
            deleteBulk={true}
            bulkDeleteUrl={"/product/delete_bulk/"}
            deleteToastMessage={"Product deleted successfully"}
            deleteBtnText={"Delete Bulk Product"}
            types={[
              {
                name: "all",
                count: 10,
                color: "#0d6efd",
                function: () => {
                  setStatus(null);
                  setUpdateData(Math.random());
                },
              },
              {
                name: "Published",
                count: 10,
                color: "#27ae60",
                function: () => {
                  setStatus("active");
                  setUpdateData(Math.random());
                },
              },
              {
                name: "On Discount",
                count: 10,
                color: "#ffff00",
                function: () => {
                  setStatus("discounted");
                  setUpdateData(Math.random());
                },
              },
              {
                name: "Draft",
                count: 10,
                color: "#ff0000",
                function: () => {
                  setStatus("inactive");
                  setUpdateData(Math.random());
                },
              },
            ]}
          />
        )}
        {!id && (
          <Box mt={2}>
            <DataGridTabel
              columns={columns}
              data={data}
              setData={setData}
              cellHeight={90}
              padding={true}
              checkbox={true}
            />
          </Box>
        )}
        {/* Modal */}
        <ModalInfo />
        <ModalDelete />
        {/* Fillter */}
        <Filters
          disconted={disconted}
          setDisconted={setDisconted}
          hasOpthions={hasOpthions}
          setHasOptions={setHasOptions}
          isOpthions={isOpthions}
          setIsOptions={setIsOptions}
          fetchData={fetchData}
        />
      </Box>
    </>
  );
};

export default Tabel;
