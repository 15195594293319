import React from "react";
import Slider from "../components/Slidbar/Slidbar";
import Tabel from "../components/Notifications/Tabel";
import SlidbarTop from "../components/Slidbar/SlidbarTop";
import { useRecoilValue } from "recoil";
import { openSlidbarState } from "../recoil/global";
import { Grid, Box } from "@mui/material";

export const Notifications = () => {
  const open = useRecoilValue(openSlidbarState);
  return (
    <Grid container>
    <Grid
      item
      xs={open ? 4 : 1}
      md={open ? 2.5 : 0.5}
      lg={open ? 2.2 : 0.5}
      xl={open ? 2 : 0.5}
      sx={{
        overflow: "visible",
      }}
    >
      <Slider />
    </Grid>

    <Grid
      sx={{ mb: 2, mt: 1 }}
      xs={open ? 8 : 11}
      md={open ? 9.5 : 11}
      lg={open ? 9.8 : 11.5}
      xl={open ? 10 : 11.5}
      item
    >
      <SlidbarTop />
      <Box
        sx={{
          height: 64,
        }}
      ></Box>
      <Tabel />
    </Grid>
  </Grid>
  );
};
