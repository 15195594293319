import {
  Box,
  Button,
  Typography,
  useTheme,
  ButtonGroup,
  Avatar,
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { tokens } from "../../theme";
import TrafficIcon from "@mui/icons-material/Traffic";
import LineChart from "./Chart/LineChart";
import BarChart from "./Chart/BarChart";
import StatBox from "./Chart/StatBox";
import ProgressCircle from "./Chart/ProgressCircle";
import { useEffect } from "react";
import { useState } from "react";
import SupervisedUserCircleIcon from "@mui/icons-material/SupervisedUserCircle";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import WorkIcon from "@mui/icons-material/Work";
import {
  GetProductPerMonth,
  GetReportsApi,
  GetWholesaleOrderPerMonth,
} from "../../services/api/home";
import { GetUserData, GetUserStats } from "../../services/api/user";
import { GetOrderDataHome, GetOrderStats } from "../../services/api/order";
import { GetWholeSaleData } from "../../services/api/wholeSale";
import PeopleAltOutlinedIcon from "@mui/icons-material/PeopleAltOutlined";
import ReactApexChart from "react-apexcharts";
import AttachMoneyOutlinedIcon from "@mui/icons-material/AttachMoneyOutlined";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import LinearProgress from "@mui/material/LinearProgress";
import Inventory2OutlinedIcon from "@mui/icons-material/Inventory2Outlined";

import { TransactionTable } from "./Chart/TransactionTable";
import { useRecoilValue } from "recoil";
import { selectedDateState } from "../../recoil/global";
import { use } from "react";
import { GetTopSoldProducts } from "../../services/api/order";

const Dashboard = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [data, setData] = useState(null);
  const [dataLine, setDataLine] = useState([]);
  const [duration, setDuration] = useState("yearly");
  const [barChartData, setBarChartData] = useState([]);
  const [userCount, setUserCount] = useState(0);
  const [allUsers, setAllUsers] = useState([]);
  const [totalOrders, setTotalOrders] = useState(0);
  const [wholeSaleTypes, setWholeSaleTypes] = useState([]);
  const [userCountByHoleSale, setUserCountByHoleSale] = useState([]);
  const [loadingUser, setLoadingUser] = useState(false);
  const [loadingWholeSale, setLoadingWholeSale] = useState(false);
  const [productsPerMonth, setProductsPerMonth] = useState([]);
  const [wholesaleOrderPerMonth, setWholesaleOrderPerMonth] = useState([]);

  const [topSoldProducts, setTopSoldProducts] = useState([]);

  const [userStats, setUserStats] = useState([]);
  const [usersPerMonth, setUsersPerMonth] = useState({});
  const [usersByType, setUsersByType] = useState({});

  const [orderStats, setOrderStats] = useState([]);
  const [ordersPerMonth, setOrdersPerMonth] = useState({});

  const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(false);

  const selectedDate = useRecoilValue(selectedDateState);

  const fetchBarChartData = async () => {
    try {
      const response = await GetReportsApi();
      //   console.log(response);
      setBarChartData(response);
    } catch (error) {
      console.error(error);
    }
  };

  const fetchUsersData = () => {
    setLoadingUser(true);
    GetUserData(0, 10000, "")
      .then((res) => {
        console.log("res?.data?.count", res);
        setAllUsers(res?.results);
        setLoadingUser(false);
      })
      .catch(() => {
        console.log("error");
        setLoadingUser(false);
      });
  };

  const fetcWholeSaleData = () => {
    setLoadingWholeSale(true);
    GetWholeSaleData(0, 10000, "")
      .then((res) => {
        console.log("res?.data?.count", res);
        setWholeSaleTypes(res?.results);
        setLoadingWholeSale(false);
      })
      .catch(() => {
        console.log("error");
        setLoadingWholeSale(false);
      });
  };

  const fetchOrderData = () => {
    GetOrderDataHome(0, 2, "")
      .then((res) => {
        console.log("res?.data?.count", res);
        setTotalOrders(res?.count);
      })
      .catch(() => {
        console.log("error");
      });
  };

  const fetchTopSoldProducts = () => {
    GetTopSoldProducts(selectedDate)
      .then((res) => {
        console.log("fetchTopSoldProducts", res);
        setTopSoldProducts(res.slice(0, 5));
      })
      .catch(() => {
        console.log("error");
      });
  };

  useEffect(() => {
    // get data cities
    fetchBarChartData();
    fetchUsersData();
    fetchOrderData();
    fetcWholeSaleData();
    fetchTopSoldProducts();
  }, [selectedDate]);

  useEffect(() => {
    if (!loadingUser && !loadingWholeSale) {
      // how many users are there in each wholesaler
      const userCountByHoleSale = [];
      wholeSaleTypes.forEach((wholeSale) => {
        const count = allUsers.filter(
          (user) => user.wholesale_type === wholeSale.id
        ).length;
        userCountByHoleSale.push({
          id: wholeSale.id,
          title: wholeSale.title,
          value: count,
        });
      });
      setUserCountByHoleSale(userCountByHoleSale);
    }
  }, [loadingUser, loadingWholeSale]);

  useEffect(() => {
    console.log("userCountByHoleSale", userCountByHoleSale);
  }, [userCountByHoleSale]);

  useEffect(() => {
    const durationLineData = [
      {
        id: "Employers",
        color: tokens("dark").greenAccent[500],
        data: [],
      },
      {
        id: "Seekers",
        color: tokens("dark").blueAccent[300],
        data: [],
      },
      {
        id: "Jobs",
        color: tokens("dark").redAccent[300],
        data: [],
      },
    ];
    setDataLine(durationLineData);
  }, [duration]);

  useEffect(() => {
    setLoading(true);
    setLoading2(true);
    GetUserStats(selectedDate)
      .then((res) => {
        setUserStats(res);
        setUsersPerMonth({
          v: res[2]?.values.map((item) => (item.length > 1 ? item[1] || 0 : 0)),
          l: res[2]?.values.map((item) => item[0]),
        });
        setUsersByType({
          v: res[6]?.values.map((item) => (item.length > 1 ? item[1] || 0 : 0)),
          l: res[6]?.values.map((item) => item[0] / res[0].values || "Retail"),
        });
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });

    GetOrderStats(selectedDate)
      .then((res) => {
        setOrderStats(res);
        setOrdersPerMonth({
          v: res[5]?.values.map((item) => (item.length > 1 ? item[1] || 0 : 0)),
          l: res[5]?.values.map((item) => (item.length > 1 ? item[0] || 0 : 0)),
        });
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading2(false);
      });

    GetProductPerMonth(selectedDate)
      .then((res) => {
        setProductsPerMonth(res.products);
      })
      .catch((err) => {
        console.log(err);
      });
    GetWholesaleOrderPerMonth(selectedDate)
      .then((res) => {
        setWholesaleOrderPerMonth(res);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [selectedDate]);

  const options = {
    series: [
      {
        name: "Total Users",
        data: usersPerMonth?.v,
      },
    ],
    chart: {
      //width:150,
      height: 75,
      type: "area",
      sparkline: {
        enabled: true,
      },
      zoom: {
        enabled: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      width: 3,
      curve: "smooth",
    },
    fill: {
      type: "gradient",
      gradient: {
        shade: "dark",
        gradientToColors: ["#0866ff"],
        shadeIntensity: 1,
        type: "vertical",
        opacityFrom: 0.5,
        opacityTo: 0.0,
        //stops: [0, 100, 100, 100]
      },
    },

    colors: ["#0866ff"],
    tooltip: {
      theme: "dark",

      x: {
        show: !1,
      },
      y: {
        title: {
          formatter: function (e) {
            return "";
          },
        },
      },
      marker: {
        show: !1,
      },
    },
    xaxis: {
      categories: usersPerMonth?.l,
    },
    yaxis: {
      min: 0,
      max: usersPerMonth?.v?.reduce((a, b) => Math.max(a, b), 0) + 5 || 50,
    },
  };
  const options2 = {
    series: [
      {
        name: "Total Sales",
        data: ordersPerMonth?.v,
      },
    ],
    chart: {
      //width:150,
      height: 75,
      type: "area",
      sparkline: {
        enabled: true,
      },
      zoom: {
        enabled: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      width: 3,
      curve: "smooth",
    },
    fill: {
      type: "gradient",
      gradient: {
        shade: "dark",
        gradientToColors: ["#02c27a"],
        shadeIntensity: 1,
        type: "vertical",
        opacityFrom: 0.5,
        opacityTo: 0.0,
        //stops: [0, 100, 100, 100]
      },
    },

    colors: ["#02c27a"],
    tooltip: {
      theme: "dark",

      x: {
        show: !1,
      },
      y: {
        title: {
          formatter: function (e) {
            return "";
          },
        },
      },
      marker: {
        show: !1,
      },
    },
    xaxis: {
      categories: ordersPerMonth?.l,
    },
    yaxis: {
      min: 0,
      max: ordersPerMonth?.v?.reduce((a, b) => Math.max(a, b), 0) + 5 || 50,
    },
  };
  const options3 = {
    series: [
      {
        name: "Total Sales",
        data: wholesaleOrderPerMonth.map((item) => item.total_orders),
      },
    ],
    chart: {
      //width:150,
      height: 75,
      type: "area",
      sparkline: {
        enabled: true,
      },
      zoom: {
        enabled: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      width: 3,
      curve: "smooth",
    },
    fill: {
      type: "gradient",
      gradient: {
        shade: "dark",
        gradientToColors: ["#0dcaf0"],
        shadeIntensity: 1,
        type: "vertical",
        opacityFrom: 0.5,
        opacityTo: 0.0,
        //stops: [0, 100, 100, 100]
      },
    },

    colors: ["#0dcaf0"],
    tooltip: {
      theme: "dark",

      x: {
        show: !1,
      },
      y: {
        title: {
          formatter: function (e) {
            return "";
          },
        },
      },
      marker: {
        show: !1,
      },
    },
    xaxis: {
      categories: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
      ],
    },
    yaxis: {
      min: 0,
      max:
        wholesaleOrderPerMonth.reduce(
          (a, b) => Math.max(a, b.total_orders),
          0
        ) + 5,
    },
  };
  const options4 = {
    series: [
      {
        name: "Products",
        data: productsPerMonth.map((item) => item.total_sold),
      },
    ],
    chart: {
      //width:150,
      height: 60,
      type: "bar",
      sparkline: {
        enabled: !0,
      },
      zoom: {
        enabled: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      width: 1.5,
      curve: "smooth",
      colors: ["#ffc107"],
    },
    fill: {
      type: "gradient",
      gradient: {
        shade: "dark",
        gradientToColors: ["#ffc107"],
        shadeIntensity: 1,
        type: "vertical",
        opacityFrom: 1,
        opacityTo: 1,
        stops: [0, 100, 100, 100],
      },
    },
    colors: ["#fe6225"],
    plotOptions: {
      bar: {
        horizontal: false,
        borderRadius: 3,
        columnWidth: "48%",
      },
    },
    tooltip: {
      theme: "dark",
      fixed: {
        enabled: !1,
      },
      x: {
        show: !1,
      },
      y: {
        title: {
          formatter: function (e) {
            return "";
          },
        },
      },
      marker: {
        show: !1,
      },
    },

    xaxis: {
      categories: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
      ],
    },
    yaxis: {
      min: 0,
      max: productsPerMonth.reduce((a, b) => Math.max(a, b.total_sold), 0) + 5,
    },
  };

  const options5 = {
    series: usersByType?.v || [0, 0, 0],
    chart: {
      height: 290,
      type: "donut",
    },
    legend: {
      position: "bottom",
      show: !1,
    },

    fill: {
      type: "gradient",
      gradient: {
        shade: "dark",
        gradientToColors: ["#ee0979", "#17ad37", "#b535ff", "#ec6ead"],
        shadeIntensity: 1,
        type: "vertical",
        opacityFrom: 1,
        opacityTo: 1,
        //stops: [0, 100, 100, 100]
      },
    },
    colors: ["#ff6a00", "#98ec2d", "#b535ff", "#3494e6"],
    dataLabels: {
      enabled: !1,
    },
    plotOptions: {
      pie: {
        donut: {
          size: "85%",
        },
      },
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            height: 270,
          },
          legend: {
            position: "bottom",
            show: !1,
          },
        },
      },
    ],
  };
  const options6 = {
    series: [
      {
        name: "Orders",
        data: ordersPerMonth?.v,
      },
    ],
    chart: {
      //width:150,
      height: 60,
      type: "bar",
      sparkline: {
        enabled: !0,
      },
      zoom: {
        enabled: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      width: 1.5,
      curve: "smooth",
      colors: ["#ff0081"],
    },
    fill: {
      type: "gradient",
      gradient: {
        shade: "dark",
        gradientToColors: ["#7c27c8"],
        shadeIntensity: 1,
        type: "vertical",
        opacityFrom: 1,
        opacityTo: 1,
        stops: [0, 100, 100, 100],
      },
    },
    colors: ["#ff0081"],
    plotOptions: {
      bar: {
        horizontal: false,
        borderRadius: 3,
        columnWidth: "48%",
      },
    },
    tooltip: {
      theme: "dark",
      fixed: {
        enabled: !1,
      },
      x: {
        show: !1,
      },
      y: {
        title: {
          formatter: function (e) {
            return "";
          },
        },
      },
      marker: {
        show: !1,
      },
    },

    xaxis: {
      categories: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
      ],
    },
    yaxis: {
      min: 0,
      max: ordersPerMonth?.v?.reduce((a, b) => Math.max(a, b), 0) + 5 || 50,
    },
  };

  return (
    !loading &&
    !loading2 && (
      <Box m="20px">
        {/* GRID & CHARTS */}
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 2 }}>
          <Grid xs={12} sm={12} md={6} lg={3} xl={3}>
            <Box
              width="100%"
              backgroundColor="#080d29"
              px={2}
              py={3}
              borderRadius={3}
              sx={{
                svg: {
                  fill: "#0d6efd",
                },
              }}
            >
              <Box
                backgroundColor="#06153e"
                height={"50px"}
                width={"50px"}
                borderRadius={"99999px"}
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
              >
                <PeopleAltOutlinedIcon />
              </Box>
              <Box px={1} mt={2} mb={0}>
                <Typography color={"#fff"} fontSize={"1.5rem"} lineHeight={1.1}>
                  {userStats.length > 0 && userStats[0]?.value}
                </Typography>
                <Typography
                  color={"#d3d7dc"}
                  fontSize={"16px"}
                  fontWeight={400}
                >
                  Total Customer
                </Typography>
              </Box>

              <Box>
                <ReactApexChart
                  options={options}
                  series={options.series}
                  type="area"
                  height={75}
                />
              </Box>
            </Box>
          </Grid>
          <Grid xs={12} sm={12} md={6} lg={3} xl={3}>
            <Box
              width="100%"
              backgroundColor="#080d29"
              px={2}
              py={3}
              borderRadius={3}
              sx={{
                svg: {
                  fill: "#02c27a",
                },
              }}
            >
              <Box
                backgroundColor="#051e30"
                height={"50px"}
                width={"50px"}
                borderRadius={"99999px"}
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
              >
                <AttachMoneyOutlinedIcon />
              </Box>
              <Box px={1} mt={2} mb={0}>
                <Typography color={"#fff"} fontSize={"1.5rem"} lineHeight={1.1}>
                  ${orderStats[14]?.value}
                </Typography>
                <Typography
                  color={"#d3d7dc"}
                  fontSize={"16px"}
                  fontWeight={400}
                >
                  Total Money Earned
                </Typography>
              </Box>

              <Box>
                <ReactApexChart
                  options={options2}
                  series={options2.series}
                  type="area"
                  height={75}
                />
              </Box>
            </Box>
          </Grid>
          <Grid xs={12} sm={12} md={6} lg={3} xl={3}>
            <Box
              width="100%"
              backgroundColor="#080d29"
              px={2}
              py={3}
              borderRadius={3}
              sx={{
                svg: {
                  fill: "#0dcaf0",
                },
              }}
            >
              <Box
                backgroundColor="#061f3c"
                height={"50px"}
                width={"50px"}
                borderRadius={"99999px"}
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
              >
                <ShoppingCartOutlinedIcon />
              </Box>
              <Box px={1} mt={2} mb={0}>
                <Typography color={"#fff"} fontSize={"1.5rem"} lineHeight={1.1}>
                  {wholesaleOrderPerMonth.reduce(
                    (a, b) => a + b.total_orders,
                    0
                  )}
                </Typography>
                <Typography
                  color={"#d3d7dc"}
                  fontSize={"16px"}
                  fontWeight={400}
                >
                  Whole Salor
                </Typography>
              </Box>

              <Box>
                <ReactApexChart
                  options={options3}
                  series={options3.series}
                  type="area"
                  height={75}
                />
              </Box>
            </Box>
          </Grid>
          <Grid xs={12} sm={12} md={6} lg={3} xl={3}>
            <Box
              width="100%"
              backgroundColor="#080d29"
              px={2}
              py={3}
              borderRadius={3}
              sx={{
                svg: {
                  fill: "#ffc107",
                },
              }}
            >
              <Box
                backgroundColor="#201f26"
                height={"50px"}
                width={"50px"}
                borderRadius={"99999px"}
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
              >
                <Inventory2OutlinedIcon />
              </Box>
              <Box px={1} mt={2} mb={0}>
                <Typography color={"#fff"} fontSize={"1.5rem"} lineHeight={1.1}>
                  {productsPerMonth.reduce((a, b) => a + b.total_sold, 0)}
                </Typography>
                <Typography
                  color={"#d3d7dc"}
                  fontSize={"16px"}
                  fontWeight={400}
                >
                  Total Products
                </Typography>
              </Box>

              <Box>
                <ReactApexChart
                  options={options4}
                  series={options4.series}
                  type="bar"
                  height={75}
                />
              </Box>
            </Box>
          </Grid>

          <Grid xs={12} sm={12} md={6} lg={4} xl={4}>
            <Box
              width="100%"
              backgroundColor="#080d29"
              px={2}
              py={2}
              borderRadius={3}
              sx={{
                svg: {
                  fill: "#ffc107",
                },
              }}
            >
              <Box px={1} mb={0}>
                <Typography color={"#fff"} fontSize={"1.5rem"} lineHeight={1.1}>
                  User
                </Typography>
              </Box>

              <Box position={"relative"}>
                <ReactApexChart
                  options={options5}
                  series={options5.series}
                  type="donut"
                  height={266}
                />

                <Box
                  position={"absolute"}
                  width={"100%"}
                  top={0}
                  height={266}
                  display={"flex"}
                  justifyContent={"center"}
                  alignItems={"center"}
                >
                  <Box>
                    <Typography
                      color={"#fff"}
                      fontSize={"2rem"}
                      lineHeight={1.1}
                      fontWeight={500}
                      textAlign={"center"}
                    >
                      {(userStats.length > 0 && userStats[0]?.value) || 0}
                    </Typography>
                    <Typography
                      color={"#e6ecf0"}
                      fontSize={"1rem"}
                      fontWeight={500}
                      textAlign={"center"}
                    >
                      Total users
                    </Typography>
                  </Box>
                </Box>
              </Box>
              <Box px={1}>
                <Box display={"flex"} mt={1} justifyContent={"space-between"}>
                  <Box display={"flex"} alignItems={"center"} gap={1.5}>
                    <Box
                      width={10}
                      height={10}
                      sx={{
                        border: "2px solid #02c27a ",
                        borderRadius: "9999px",
                      }}
                    ></Box>
                    <Typography
                      color={"#d3d7dc"}
                      fontSize={"16px"}
                      fontWeight={400}
                    >
                      Whole Salor{" "}
                      {userStats.length > 5 &&
                        userStats[6]?.values.length > 1 &&
                        userStats[6]?.values[1].length > 0 &&
                        userStats[6]?.values[1][0]}
                    </Typography>
                  </Box>
                  <Box>
                    <Typography
                      color={"#d3d7dc"}
                      fontSize={"16px"}
                      fontWeight={400}
                    >
                      {userStats.length > 5 &&
                        userStats[6]?.values.length > 1 &&
                        userStats[6]?.values[1].length > 1 &&
                        userStats[6]?.values[1][1]}
                    </Typography>
                  </Box>
                </Box>
                <Box display={"flex"} mt={1} justifyContent={"space-between"}>
                  <Box display={"flex"} alignItems={"center"} gap={1.5}>
                    <Box
                      width={10}
                      height={10}
                      sx={{
                        border: "2px solid #b535ff",
                        borderRadius: "9999px",
                      }}
                    ></Box>
                    <Typography
                      color={"#d3d7dc"}
                      fontSize={"16px"}
                      fontWeight={400}
                    >
                      Whole Salor{" "}
                      {userStats.length > 5 &&
                        userStats[6]?.values.length > 2 &&
                        userStats[6]?.values[2].length > 0 &&
                        userStats[6]?.values[2][0]}
                    </Typography>
                  </Box>
                  <Box>
                    <Typography
                      color={"#d3d7dc"}
                      fontSize={"16px"}
                      fontWeight={400}
                    >
                      {userStats.length > 5 &&
                        userStats[6]?.values.length > 2 &&
                        userStats[6]?.values[2].length > 1 &&
                        userStats[6]?.values[2][1]}
                    </Typography>
                  </Box>
                </Box>
                <Box display={"flex"} mt={1} justifyContent={"space-between"}>
                  <Box display={"flex"} alignItems={"center"} gap={1.5}>
                    <Box
                      width={10}
                      height={10}
                      sx={{
                        border: "2px solid #4292e2",
                        borderRadius: "9999px",
                      }}
                    ></Box>
                    <Typography
                      color={"#d3d7dc"}
                      fontSize={"16px"}
                      fontWeight={400}
                    >
                      Whole Salor{" "}
                      {userStats.length > 5 &&
                        userStats[6]?.values.length > 3 &&
                        userStats[6]?.values[3].length > 0 &&
                        userStats[6]?.values[3][0]}
                    </Typography>
                  </Box>
                  <Box>
                    <Typography
                      color={"#d3d7dc"}
                      fontSize={"16px"}
                      fontWeight={400}
                    >
                      {userStats.length > 5 &&
                        userStats[6]?.values.length > 3 &&
                        userStats[6]?.values[3].length > 1 &&
                        userStats[6]?.values[3][1]}
                    </Typography>
                  </Box>
                </Box>
                <Box display={"flex"} mt={1} justifyContent={"space-between"}>
                  <Box display={"flex"} alignItems={"center"} gap={1.5}>
                    <Box
                      width={10}
                      height={10}
                      sx={{
                        border: "2px solid #e31755",
                        borderRadius: "9999px",
                      }}
                    ></Box>
                    <Typography
                      color={"#d3d7dc"}
                      fontSize={"16px"}
                      fontWeight={400}
                    >
                      Retails
                    </Typography>
                  </Box>
                  <Box>
                    <Typography
                      color={"#d3d7dc"}
                      fontSize={"16px"}
                      fontWeight={400}
                    >
                      {userStats.length > 5 &&
                        userStats[6]?.values.length > 0 &&
                        userStats[6]?.values[0].length > 1 &&
                        userStats[6]?.values[0][1]}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Grid>
          <Grid xs={12} sm={12} md={6} lg={4} xl={4}>
            <Box
              width="100%"
              backgroundColor="#080d29"
              px={2}
              py={2}
              borderRadius={3}
              sx={{
                svg: {
                  fill: "#ffc107",
                },
              }}
            >
              <Box px={1} mb={0}>
                <Typography color={"#fff"} fontSize={"1.5rem"} lineHeight={1.1}>
                  5 Top Products
                </Typography>
              </Box>
              <Box>
                {topSoldProducts.map((item, index) => (
                  <Grid mt={2} pt={"2px"} pb={"1.5px"} container>
                    <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
                      <Avatar
                        sx={{
                          maxHeight: "100%",
                          maxWidth: "100%",
                          height: 60,
                          width: 60,
                        }}
                        src={item.image}
                        alt="https://www.w3schools.com/howto/img_avatar.png"
                      />
                    </Grid>
                    <Grid item xs={8} sm={8} md={8} lg={8} xl={8.5} px={"10px"}>
                      <Box display={"flex"} alignItems={"center"} gap={1.5}>
                        <Box width={"95%"}>
                          <Typography
                            color={"#fff"}
                            fontSize={"18px"}
                            fontWeight={500}
                            textOverflow={"ellipsis"}
                            overflow={"hidden"}
                            sx={{
                              textWrap: "nowrap",
                            }}
                          >
                            {item.product}
                          </Typography>
                        </Box>
                      </Box>
                      <Box display={"flex"} alignItems={"center"} gap={1.5}>
                        <Typography
                          color={"#fff"}
                          fontSize={"18px"}
                          fontWeight={400}
                        >
                          Sale: {item.total_sold}
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid
                      item
                      xs={2}
                      sm={2}
                      md={2}
                      lg={2}
                      xl={1.5}
                      display={"flex"}
                      alignItems={"center"}
                      justifyContent={"end"}
                    >
                      <Typography
                        color={"#fff"}
                        fontSize={"18px"}
                        fontWeight={400}
                      >
                        {item.total_revenue}$
                      </Typography>
                    </Grid>
                  </Grid>
                ))}
              </Box>
            </Box>
          </Grid>
          <Grid xs={12} sm={12} md={6} lg={4} xl={4}>
            <Box
              width="100%"
              backgroundColor="#080d29"
              px={2}
              py={2}
              borderRadius={3}
              sx={{
                svg: {
                  fill: "#ffc107",
                },
              }}
            >
              <Box px={1} mb={0}>
                <Typography color={"#fff"} fontSize={"1.5rem"} lineHeight={1.1}>
                  5 Top Customer
                </Typography>
              </Box>
              <Box>
                {orderStats &&
                  orderStats.length > 0 &&
                  orderStats[7].values.slice(0, 5).map((item, index) => (
                    <Grid mt={2} pt={"2px"} pb={"1.5px"} container>
                      <Grid item xs={2} sm={2} md={2} lg={2} xl={2} >
                        <Avatar
                          sx={{
                            height: 60,
                            width: 60,
                            maxWidth: "100%",
                            maxHeight: "100%",
                          }}
                          alt={item.length > 0 && item[0]}
                          src="https://www.w3schools.com/hdsfefowto/img_avatar.png"
                        />
                      </Grid>
                      <Grid item xs={8} sm={8} md={8} lg={7} xl={8}>
                        <Box display={"flex"} alignItems={"center"} gap={1.5} height={"100%"}>
                          <Box width={"95%"} p={"10px"}>
                            <Typography
                              color={"#fff"}
                              fontSize={"18px"}
                              fontWeight={500}
                              textOverflow={"ellipsis"}
                              overflow={"hidden"}
                              width={"100%"}
                              sx={{
                                textWrap: "nowrap",
                              }}
                            >
                              {item.length > 0 && item[0]}
                            </Typography>
                          </Box>
                        </Box>
                      </Grid>
                      <Grid
                        item
                        xs={2}
                        sm={2}
                        md={2}
                        lg={3}
                        xl={2}
                        display={"flex"}
                        alignItems={"center"}
                        justifyContent={"end"}
                      >
                        <Typography
                          color={"#fff"}
                          fontSize={"18px"}
                          fontWeight={400}
                          sx={{
                            textWrap: "nowrap",
                          }}
                        >
                          Sale: {item.length > 1 && item[1]}
                        </Typography>
                      </Grid>
                    </Grid>
                    // <Grid mt={2} pt={"2px"} pb={"1.5px"} width={"100%"} container>
                    //   <Grid

                    //     xs={2}
                    //     lg={2}
                    //     xl={2}
                    //     item
                    //   >
                    //     <Avatar
                    //       sx={{
                    //         height: 60,
                    //         width: 60,
                    //         maxWidth: "100%",
                    //         maxHeight: "100%",
                    //       }}
                    //       alt={item.length > 0 && item[0]}
                    //       src="https://www.w3schools.com/hdsfefowto/img_avatar.png"
                    //     />
                    //   </Grid>
                    //   <Grid xs={10} lg={10} xl={10} item>
                    //     <Typography
                    //       color={"#fff"}
                    //       fontSize={"18px"}
                    //       fontWeight={500}
                    //       textOverflow={"ellipsis"}
                    //       overflow={"hidden"}
                    //       maxWidth={"100%"}
                    //       sx={{
                    //         textWrap: "nowrap",
                    //       }}
                    //     >
                    //       {item.length > 0 && item[0]}
                    //     </Typography>
                    //   </Grid>
                    //   <Grid
                    //     alignItems={"center"}
                    //     display={"flex"}
                    //     xs={2}
                    //     lg={2}
                    //     xl={1.5}
                    //     item
                    //   >
                    //     <Typography
                    //       color={"#fff"}
                    //       fontSize={"18px"}
                    //       fontWeight={400}
                    //       textOverflow={"ellipsis"}
                    //       overflow={"hidden"}
                    //       sx={{
                    //         textWrap: "nowrap",
                    //       }}
                    //     >
                    //       Sale: {item.length > 1 && item[1]}
                    //     </Typography>
                    //   </Grid>
                    // </Grid>
                  ))}
              </Box>
            </Box>
          </Grid>
          <Grid xs={12} sm={12} md={6} lg={8.5} xl={8.5}>
            <TransactionTable />
          </Grid>
          <Grid xs={12} sm={12} md={6} lg={3.5} xl={3.5}>
            <Box
              width="100%"
              backgroundColor="#080d29"
              px={2}
              py={3}
              borderRadius={3}
              sx={{
                svg: {
                  fill: "#ffc107",
                },
              }}
            >
              <Box px={1} mb={0}>
                <Typography
                  color={"#d3d7dc"}
                  fontSize={"16px"}
                  fontWeight={400}
                  lineHeight={1.1}
                >
                  Total Orders
                </Typography>
                <Box display={"flex"} justifyContent={"space-between"}>
                  <Typography
                    color={"#fff"}
                    fontSize={"1.5rem"}
                    lineHeight={1.1}
                    my={1}
                  >
                    {totalOrders}
                  </Typography>
                  <Box
                    sx={{
                      background:
                        "linear-gradient(310deg, #ee0979, #ff6a00) !important",
                      svg: {
                        fill: "#fff",
                      },
                      height: "40px",
                      width: "40px",
                      borderRadius: "99999px",
                    }}
                    display={"flex"}
                    justifyContent={"center"}
                    alignItems={"center"}
                  >
                    <ShoppingCartOutlinedIcon />
                  </Box>
                </Box>
                <Box mt={2}>
                  <LinearProgress
                    variant="determinate"
                    value={totalOrders / 10}
                    sx={{
                      borderRadius: "9999px",
                      background: "#252942",
                      span: {
                        background:
                          "linear-gradient(310deg, #ee0979, #ff6a00) !important",
                      },
                    }}
                  />
                </Box>
              </Box>
            </Box>

            <Box
              width="100%"
              backgroundColor="#080d29"
              px={2}
              py={3}
              borderRadius={3}
              sx={{
                mt: "20px",
                svg: {
                  fill: "#ffc107",
                },
              }}
            >
              <Box px={1} mb={1}>
                <Typography color={"#fff"} fontSize={"1.5rem"} lineHeight={1.1}>
                  ${orderStats[14]?.value}
                </Typography>
                <Typography
                  color={"#d3d7dc"}
                  fontSize={"16px"}
                  fontWeight={400}
                >
                  Total Revenue
                </Typography>
              </Box>

              <Box>
                <ReactApexChart
                  options={options6}
                  series={options6.series}
                  type="bar"
                  height={180}
                />
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    )
  );
};

export default Dashboard;
