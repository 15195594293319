import React from "react";

import { Avatar, Box, Button, Grid, Typography } from "@mui/material";
import {
  useRecoilState,
  useRecoilValue,
  useResetRecoilState,
  useSetRecoilState,
} from "recoil";
import { DataGridTabel } from "../DataGridTabel/DataGridTabel";
import { ColumnText } from "../DataGridTabel/ColumnText";
import { ColumnButton } from "../DataGridTabel/ColumnButton";
import { HeaderGridTabel } from "../DataGridTabel/HeaderGridTabel";
import HomeWorkIcon from "@mui/icons-material/HomeWork";
import {
  dataClickItem,
  methodRequestStatus,
  openFilterState,
  updateDataState,
} from "../../recoil/global";
import {
  confirmModalState,
  openModalDelete,
  openModalInfo,
  openModalShow,
} from "../../recoil/modal";
import ModalDelete from "./Modal/ModalDelete";
import { useState } from "react";
import { dataTabelState } from "../../recoil/Tabel";
import {
  selectedCreatedFromDateState,
  selectedCreatedToDateState,
  selectedIsViewState,
  selectedPaymentMethodState,
  selectedPaymentStatus,
  selectedStatusState,
} from "../../recoil/select";
import { GetOrderByIdData } from "../../services/api/order";
import { useLocation } from "react-router-dom";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import EmailIcon from "@mui/icons-material/Email";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";

import moment from "moment";

export default function OrderInfo(props) {
  const setDataItem = useSetRecoilState(dataClickItem);
  const setOpenModal = useSetRecoilState(openModalInfo);
  const setOpenShowModal = useSetRecoilState(openModalShow);
  const setOpenDelete = useSetRecoilState(openModalDelete);
  const setMethodRequest = useSetRecoilState(methodRequestStatus);

  const handelShow = (data) => {
    setOpenShowModal(true);
    setDataItem(data);
    setMethodRequest("show");
  };
  const handelPaid = (data) => {
    setOpenModal(true);
    setDataItem(data);
    setMethodRequest("paid");
  };
  const handelApproved = (data) => {
    setOpenModal(true);
    setDataItem(data);
    setMethodRequest("approved");
  };
  const handelRejected = (data) => {
    setOpenModal(true);
    setDataItem(data);
    setMethodRequest("rejected");
  };
  const handelReturn = (data) => {
    setOpenModal(true);
    setDataItem(data);
    setMethodRequest("return");
  };
  const handelDelete = (data) => {
    setOpenDelete(true);
    setDataItem(data);
    setMethodRequest("delete");
  };

  return (
    <Box
      sx={{
        px: 4,
        ".user-hover": {
          color: "#0d6efd",
          cursor: "pointer",
          "&:hover": {
            color: "#0a58ca",
          },
        },
      }}
    >
      <Grid
        container
        bgcolor={"#0a0e2b"}
        px={2}
        py={3}
        borderRadius={"0.375rem"}
        alignContent={"center"}
      >
        <Grid sm={2} my={"auto"}>
          <Typography
            variant="h4"
            color={"#fff"}
            fontSize={"1.5rem"}
            fontWeight={"700"}
          >
            Order #
            {props.data.order_number && props.data.order_number.split("-")[1]}
          </Typography>
          <Box display={"flex"} mt={1}>
            <Typography
              variant="p"
              color={"#d3d7dc"}
              fontWeight={"400"}
              fontSize={"14px"}
            >
              Customer ID :
            </Typography>
            <Typography
              variant="p"
              color={"#d3d7dc"}
              fontWeight={"400"}
              fontSize={"16px"}
              px={0.5}
              className="user-hover"
            >
              {" "}
              {props?.data?.created_by?.pk}
            </Typography>
          </Box>
        </Grid>
        <Grid sm={3}>
          <Typography
            variant="h5"
            color={"#d3d7dc"}
            fontSize={"1.25rem"}
            fontWeight={"700"}
          >
            Order History
          </Typography>
          <Box display={"flex"} mt={1}>
            <Typography
              variant="p"
              color={"#d3d7dc"}
              fontWeight={"400"}
              fontSize={"14px"}
            >
              Create Order :
            </Typography>
            <Typography
              variant="p"
              color={"#d3d7dc"}
              fontWeight={"400"}
              fontSize={"14px"}
              px={0.5}
            >
              {" "}
              {moment(props?.data?.created_at).format("DD-MM-YYYY")}
            </Typography>
          </Box>
          <Box display={"flex"}>
            <Typography
              variant="p"
              color={"#d3d7dc"}
              fontWeight={"400"}
              fontSize={"14px"}
            >
              Modified Order :
            </Typography>
            <Typography
              variant="p"
              color={"#d3d7dc"}
              fontWeight={"400"}
              fontSize={"14px"}
              px={0.5}
            >
              {" "}
              {moment(props?.data?.modified).format("DD-MM-YYYY")}
            </Typography>
          </Box>
        </Grid>
        <Grid sm={3}>
          <Typography
            variant="h5"
            color={"#d3d7dc"}
            fontSize={"1.25rem"}
            fontWeight={"700"}
          >
            Approved By
          </Typography>
          {props?.data?.approved_by?.username && (
            <Box display={"flex"} mt={1}>
              <Typography
                variant="p"
                color={"#d3d7dc"}
                fontWeight={"400"}
                fontSize={"14px"}
              >
                {props?.data?.approved_by?.username}
              </Typography>
            </Box>
          )}
          {props?.data?.approved_by?.username && (
            <Box display={"flex"}>
              <Typography
                variant="p"
                color={"#d3d7dc"}
                fontWeight={"400"}
                fontSize={"14px"}
              >
                Approved Date :
              </Typography>
              <Typography
                variant="p"
                color={"#d3d7dc"}
                fontWeight={"400"}
                fontSize={"14px"}
                px={0.5}
              >
                {" "}
                {moment(props?.data?.approved_at).format("DD-MM-YYYY")}
              </Typography>
            </Box>
          )}
        </Grid>
        <Grid sm={4}>
          <Box display={"flex"} alignItems={"center"}>
            {props?.data.payment_method === "cash" &&
            props?.data.status !== "canceled" &&
            props?.data.payment_status !== "paid" ? (
              <Button
                onClick={() => handelPaid(props?.data)}
                type={"approve"}
                variant="contained"
                sx={{
                  backgroundColor: "transparent",
                  color: "#02c27a",
                  border: "1px solid #02c27a",
                  borderRadius: "5px 0px 0px 5px",
                  transition: "all 0.3s",
                  fontWeight: "bold",
                  "&:hover": {
                    backgroundColor: "#02c27a !important",
                    color: "#fff !important",
                  },
                }}
                hover="false"
              >
                Paid
              </Button>
            ) : (
              ""
            )}

            {props?.data.status !== "canceled" &&
            props?.data.payment_status === "paid" &&
            props?.data.status !== "returned" &&
            props?.data.status !== "rejected" ? (
              <Button
                onClick={() =>
                  props?.data.status === "approved"
                    ? null
                    : handelApproved(props?.data)
                }
                type={"approve"}
                variant="contained"
                sx={{
                  backgroundColor:
                    props?.data.status === "approved"
                      ? "#02c27a"
                      : "transparent",
                  color: props?.data.status === "approved" ? "#fff" : "#02c27a",
                  border: "1px solid #02c27a",
                  borderRadius: "5px 0px 0px 5px",
                  transition: "all 0.3s",
                  fontWeight: "bold",
                  "&:hover": {
                    backgroundColor: "#02c27a !important",
                    color: "#fff !important",
                  },
                }}
                hover="false"
              >
                Approve Order
              </Button>
            ) : (
              ""
            )}
            <Button
              onClick={() => handelRejected(props?.data)}
              type={"reject"}
              variant="contained"
              sx={{
                backgroundColor: "transparent",
                color: "#0d6efd",
                border: "1px solid #0d6efd",
                transition: "all 0.3s",
                borderRadius: "0px 0px 0px 0px",
                fontWeight: "bold",
                "&:hover": {
                  backgroundColor: "#0d6efd !important",
                  color: "#fff !important",
                },
              }}
            >
              Print
            </Button>
            {props?.data.status !== "canceled" &&
            props?.data.status !== "approved" &&
            props?.data.status !== "rejected" &&
            props?.data.status !== "returned" &&
            props?.data.payment_status === "paid" ? (
              <Button
                onClick={() => handelRejected(props?.data)}
                type={"reject"}
                variant="contained"
                sx={{
                  backgroundColor: "transparent",
                  color: "#f01414",
                  border: "1px solid #f01414",
                  transition: "all 0.3s",
                  borderRadius: "0px 0px 0px 0px",
                  fontWeight: "bold",
                  "&:hover": {
                    backgroundColor: "#f01414 !important",
                    color: "#fff !important",
                  },
                }}
              >
                Reject
              </Button>
            ) : (
              ""
            )}
            {props?.data.status === "approved" &&
            props?.data.payment_status === "paid" ? (
              <Button
                onClick={() => handelReturn(props?.data)}
                type={"return"}
                variant="contained"
                sx={{
                  backgroundColor: "transparent",
                  color: "#fc185a",
                  border: "1px solid #fc185a",
                  borderRadius: "0px ",
                  transition: "all 0.3s",
                  fontWeight: "bold",
                  "&:hover": {
                    backgroundColor: "#fc185a !important",
                    color: "#fff !important",
                  },
                }}
              >
                Return
              </Button>
            ) : (
              ""
            )}
            <Button
              onClick={() => handelDelete(props?.data)}
              type={"delete"}
              variant="contained"
              sx={{
                backgroundColor: "transparent",
                color: "#fc185a",
                border: "1px solid #fc185a",
                borderRadius: "0px 5px 5px 0px",
                transition: "all 0.3s",
                fontWeight: "bold",
                "&:hover": {
                  backgroundColor: "#fc185a !important",
                  color: "#fff !important",
                },
              }}
            >
              Delete
            </Button>
          </Box>
        </Grid>
      </Grid>
      <Grid container mt={2}>
        <Grid
          sm={7.5}
          height={600}
          px={2}
          py={3}
          borderRadius={"0.375rem"}
          bgcolor={"#090e2a"}
        >
          <Box display={"flex"}>
            <Typography color={"#fff"} fontSize={"1.25rem"} fontWeight={"700"}>
              Cart
            </Typography>
            <Typography
              color={"#fff"}
              fontSize={"1.25rem"}
              fontWeight={"300"}
              mx={1}
            >
              {props?.data?.order_lines?.length}
            </Typography>
          </Box>
          <Box>
            <Grid
              container
              bgcolor={"#f8f9fa"}
              py={1}
              px={2}
              mt={2}
              borderBottom={"1px solid #fff"}
            >
              <Grid item sm={5}>
                <Typography
                  color={"#000"}
                  fontSize={"0.8rem"}
                  fontWeight={"700"}
                >
                  Product
                </Typography>
              </Grid>
              <Grid item sm={5}>
                <Typography
                  color={"#000"}
                  fontSize={"0.8rem"}
                  fontWeight={"700"}
                >
                  Key / Quantity
                </Typography>
              </Grid>
              <Grid item sm={2}>
                <Typography
                  color={"#000"}
                  fontSize={"0.8rem"}
                  fontWeight={"700"}
                >
                  Price
                </Typography>
              </Grid>
            </Grid>
          </Box>
          <Box
            maxHeight={"480px"}
            sx={{
              overflowY: "auto",
            }}
          >
            {props?.data?.order_lines?.map((item, index) => {
              return item?.is_key_product &&
                item?.order_line_keys &&
                item?.order_line_keys.lenght > 0 ? (
                item.order_line_keys.map((key, index) => (
                  <Grid
                    container
                    bgcolor={"#080d29"}
                    py={1}
                    px={2}
                    borderBottom={"1px solid #2f344b"}
                  >
                    <Grid item sm={5} display={"flex"}>
                      <Avatar src={item.product_image} />
                      <Box
                        mx={1}
                        sx={{
                          overflowX: "auto",
                          width: "100%",
                        }}
                      >
                        <Typography
                          color={"#fff"}
                          fontSize={"0.97rem"}
                          fontWeight={"700"}
                          noWrap
                          pr={1}
                        >
                          {item.product_name}
                        </Typography>
                        <Typography
                          color={"#878d96"}
                          fontSize={"0.8rem"}
                          fontWeight={"400"}
                        >
                          {item.product_name}
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item sm={5} my={"auto"}>
                      <Typography
                        color={"#fff"}
                        fontSize={"0.8rem"}
                        fontWeight={"400"}
                      >
                        {key.key_serial}
                      </Typography>
                    </Grid>
                    <Grid item sm={2} my={"auto"}>
                      <Typography
                        color={"#fff"}
                        fontSize={"0.8rem"}
                        fontWeight={"400"}
                      >
                        {item.unit_price || "0"} IQD
                      </Typography>
                    </Grid>
                  </Grid>
                ))
              ) : (
                <Grid
                  container
                  bgcolor={"#080d29"}
                  py={1}
                  px={2}
                  borderBottom={"1px solid #2f344b"}
                >
                  <Grid item sm={5} display={"flex"}>
                    <Avatar src={item.product_image} />
                    <Box mx={1}>
                      <Typography
                        color={"#fff"}
                        fontSize={"0.97rem"}
                        fontWeight={"700"}
                      >
                        {item.product_name}
                      </Typography>
                      <Typography
                        color={"#878d96"}
                        fontSize={"0.8rem"}
                        fontWeight={"400"}
                      >
                        {item.product_name}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item sm={5} my={"auto"}>
                    <Typography
                      color={"#fff"}
                      fontSize={"0.8rem"}
                      fontWeight={"700"}
                    >
                      {item.quantity}
                    </Typography>
                  </Grid>
                  <Grid item sm={2} my={"auto"}>
                    <Typography
                      color={"#fff"}
                      fontSize={"0.8rem"}
                      fontWeight={"700"}
                    >
                      {item.sub_total}
                    </Typography>
                  </Grid>
                </Grid>
              );
            })}
          </Box>
        </Grid>
        <Grid sm={4.5} px={2}>
          <Grid sm={12} mb={4}>
            <Box
              sx={{
                background: "#0b102b",
                borderRadius: "0.375rem",
              }}
              p={2}
            >
              <Typography color={"#fff"} fontSize={"1.5rem"} fontWeight={700}>
                Summary
              </Typography>
              <Box display={"flex"} justifyContent={"space-between"} mt={2}>
                <Typography
                  color={"#d3d7dc"}
                  fontSize={"16px"}
                  letterSpacing={"0.5px"}
                >
                  Items subtotal :
                </Typography>
                <Typography
                  color={"#d3d7dc"}
                  fontSize={"16px"}
                  letterSpacing={"0.5px"}
                >
                  {props?.data?.total_price_usd}$
                </Typography>
              </Box>

              <Box display={"flex"} justifyContent={"space-between"} mt={1}>
                <Typography
                  color={"#d3d7dc"}
                  fontSize={"16px"}
                  letterSpacing={"0.5px"}
                >
                  Discount :
                </Typography>
                <Typography
                  color={"#d3d7dc"}
                  fontSize={"16px"}
                  letterSpacing={"0.5px"}
                >
                  {0}$
                </Typography>
              </Box>
              <Box display={"flex"} justifyContent={"space-between"} mt={1}>
                <Typography
                  color={"#d3d7dc"}
                  fontSize={"16px"}
                  letterSpacing={"0.5px"}
                >
                  Paid from wallet :
                </Typography>
                <Typography
                  color={"#d3d7dc"}
                  fontSize={"16px"}
                  letterSpacing={"0.5px"}
                >
                  {props?.data?.paid_amount_from_wallet}$
                </Typography>
              </Box>
              <Box display={"flex"} justifyContent={"space-between"} mt={1}>
                <Typography
                  color={"#d3d7dc"}
                  fontSize={"16px"}
                  letterSpacing={"0.5px"}
                >
                  Sub Total :
                </Typography>
                <Typography
                  color={"#d3d7dc"}
                  fontSize={"16px"}
                  letterSpacing={"0.5px"}
                >
                  {props?.data?.total_price_with_shipping_usd -
                    props?.data?.shipping_cost}
                  $
                </Typography>
              </Box>
              <Box display={"flex"} justifyContent={"space-between"} mt={1}>
                <Typography
                  color={"#d3d7dc"}
                  fontSize={"16px"}
                  letterSpacing={"0.5px"}
                >
                  Shipping :
                </Typography>
                <Typography
                  color={"#d3d7dc"}
                  fontSize={"16px"}
                  letterSpacing={"0.5px"}
                >
                  {props?.data?.shipping_cost}$
                </Typography>
              </Box>
              <Box
                display={"flex"}
                justifyContent={"space-between"}
                borderTop={"1px solid #ffffff26"}
                pt={2}
                mt={2}
              >
                <Typography color={"#fff"} fontSize={"1.25rem"}>
                  Total :
                </Typography>
                <Typography color={"#fff"} fontSize={"1.25rem"}>
                  {props?.data?.total_price_with_shipping_usd}$
                </Typography>
              </Box>
            </Box>
          </Grid>
          <Grid sm={12}>
            <Box
              sx={{
                background: "#0b102b",
                borderRadius: "0.375rem",
              }}
              px={2}
              py={"11px"}
            >
              <Typography color={"#fff"} fontSize={"1.5rem"} fontWeight={700}>
                Order Status
              </Typography>
              <Box
                py={3}
                sx={{
                  borderBottom: "1px solid #ffffff26",
                }}
              >
                <Typography color={"#d3d7dc"} display={"flex"}>
                  Payment status:{" "}
                  <Typography
                    color={
                      props?.data.payment_status === "paid"
                        ? "#02c27a"
                        : props?.data.payment_status === "pending"
                        ? "#f01414"
                        : "#fc185a"
                    }
                    mx={"3px"}
                    textTransform={"capitalize"}
                  >
                    {props?.data?.payment_status}
                  </Typography>
                  via{" "}
                  {props?.data?.payment_method &&
                    props?.data?.payment_method
                      .replace(/_/g, " ")
                      .replace(
                        /\w\S*/g,
                        (txt) =>
                          txt.charAt(0).toUpperCase() +
                          txt.slice(1).toLowerCase()
                      )}
                </Typography>
              </Box>
              <Box
                py={3}
                sx={{
                  borderBottom: "1px solid #ffffff26",
                }}
              >
                <Typography color={"#d3d7dc"} display={"flex"}>
                  Walt cash back paid:{" "}
                  <Typography
                    color={"#0dcaf0"}
                    mx={"3px"}
                    textTransform={"capitalize"}
                  >
                    {props?.data?.paid_amount_from_wallet}
                  </Typography>
                </Typography>
                <Typography color={"#d3d7dc"} display={"flex"}>
                  Non Walt paiment:{" "}
                  <Typography
                    color={"#0dcaf0"}
                    mx={"3px"}
                    textTransform={"capitalize"}
                  >
                    {props?.data?.total_price_with_shipping_usd -
                      props?.data?.paid_amount_from_wallet}
                  </Typography>
                </Typography>
              </Box>
              <Box my={1}>
                <Typography color={"#d3d7dc"} display={"flex"}>
                  Status:
                  <Typography
                    color={
                      props?.data.status === "approved"
                        ? "#02c27a"
                        : props?.data.status === "pending"
                        ? "#f01414"
                        : "#fc185a"
                    }
                    mx={"3px"}
                    textTransform={"capitalize"}
                  >
                    {props?.data?.status}
                  </Typography>
                </Typography>
              </Box>
            </Box>
          </Grid>
        </Grid>
        <Grid sm={12}>
          <Typography my={"1.5rem"} color={"#fff"} fontSize={"1.25rem"}>
            Billing Details
          </Typography>
          <Grid
            container
            sx={{
              background: "#0b102b",
              borderRadius: "0.375rem",
            }}
            p={2}
          >
            <Grid item sm={3} p={1}>
              <Box
                display={"flex"}
                p={2}
                sx={{
                  borderRadius: "0.375rem",
                  border: "1px solid #ffffff26",
                  height: "100%",
                  svg: {
                    fill: "#fff",
                    width: "32px",
                    height: "32px",
                    my: "auto",
                  },
                }}
              >
                <AccountCircleOutlinedIcon color="#fff" />
                <Box px={2} my={"auto"}>
                  <Typography
                    color={"#fff"}
                    fontSize={"1.25rem"}
                    fontWeight={700}
                  >
                    Customer Name
                  </Typography>
                  <Typography color={"#0d6efd"} fontSize={"14px"}>
                    {props?.data?.created_by?.username}
                  </Typography>
                </Box>
              </Box>
            </Grid>
            <Grid item sm={3} p={1}>
              <Box
                display={"flex"}
                p={2}
                sx={{
                  borderRadius: "0.375rem",
                  border: "1px solid #ffffff26",
                  height: "100%",
                  svg: {
                    fill: "#fff",
                    width: "32px",
                    height: "32px",
                    my: "auto",
                  },
                }}
              >
                <EmailIcon color="#fff" />
                <Box px={2} my={"auto"}>
                  <Typography
                    color={"#fff"}
                    fontSize={"1.25rem"}
                    fontWeight={700}
                  >
                    Email
                  </Typography>
                  <Typography color={"#0d6efd"} fontSize={"14px"}>
                    {props?.data?.created_by?.email}
                  </Typography>
                </Box>
              </Box>
            </Grid>
            <Grid item sm={3} p={1}>
              <Box
                display={"flex"}
                p={2}
                sx={{
                  borderRadius: "0.375rem",
                  border: "1px solid #ffffff26",
                  height: "100%",

                  svg: {
                    fill: "#fff",
                    width: "32px",
                    height: "32px",
                    my: "auto",
                  },
                }}
              >
                <LocalPhoneIcon color="#fff" />
                <Box px={2} my={"auto"}>
                  <Typography
                    color={"#fff"}
                    fontSize={"1.25rem"}
                    fontWeight={700}
                  >
                    Phone
                  </Typography>
                  <Typography color={"#0d6efd"} fontSize={"14px"}>
                    {props?.data?.created_by?.phone || "N/A"}
                  </Typography>
                </Box>
              </Box>
            </Grid>
            <Grid item sm={3} p={1}>
              <Box
                display={"flex"}
                p={2}
                sx={{
                  borderRadius: "0.375rem",
                  border: "1px solid #ffffff26",
                  height: "100%",
                  svg: {
                    fill: "#fff",
                    width: "32px",
                    height: "32px",
                    my: "auto",
                  },
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  class="bi bi-cash-coin"
                  viewBox="0 0 16 16"
                >
                  <path
                    fill-rule="evenodd"
                    d="M11 15a4 4 0 1 0 0-8 4 4 0 0 0 0 8m5-4a5 5 0 1 1-10 0 5 5 0 0 1 10 0"
                  />
                  <path d="M9.438 11.944c.047.596.518 1.06 1.363 1.116v.44h.375v-.443c.875-.061 1.386-.529 1.386-1.207 0-.618-.39-.936-1.09-1.1l-.296-.07v-1.2c.376.043.614.248.671.532h.658c-.047-.575-.54-1.024-1.329-1.073V8.5h-.375v.45c-.747.073-1.255.522-1.255 1.158 0 .562.378.92 1.007 1.066l.248.061v1.272c-.384-.058-.639-.27-.696-.563h-.668zm1.36-1.354c-.369-.085-.569-.26-.569-.522 0-.294.216-.514.572-.578v1.1zm.432.746c.449.104.655.272.655.569 0 .339-.257.571-.709.614v-1.195z" />
                  <path d="M1 0a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h4.083q.088-.517.258-1H3a2 2 0 0 0-2-2V3a2 2 0 0 0 2-2h10a2 2 0 0 0 2 2v3.528c.38.34.717.728 1 1.154V1a1 1 0 0 0-1-1z" />
                  <path d="M9.998 5.083 10 5a2 2 0 1 0-3.132 1.65 6 6 0 0 1 3.13-1.567" />
                </svg>
                <Box px={2}>
                  <Typography
                    color={"#fff"}
                    fontSize={"1.25rem"}
                    fontWeight={700}
                  >
                    Paid By
                  </Typography>
                  <Typography color={"#0d6efd"} fontSize={"14px"}>
                    {props?.data?.paid_by?.username}
                  </Typography>
                  <Box display={"flex"}>
                    <Typography color={"#fff"} fontSize={"14px"}>
                      Payment date :
                    </Typography>
                    <Typography mx={"3px"} color={"#fff"} fontSize={"14px"}>
                      {props?.data?.paid_at &&
                        moment(props?.data?.paid_at).format("DD-MM-YYYY")}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
}
