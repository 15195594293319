import React, { useEffect, useState } from "react";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { Box, Button, Grid, TextField, Typography } from "@mui/material";
import { toast } from "react-toastify";
import { openModalInfo } from "../../../recoil/modal";
import {
  dataClickItem,
  methodRequestStatus,
  updateDataState,
} from "../../../recoil/global";
import {
  dataSelectCityState,
  dataSelectProvinceState,
  selectedJobFieldState,
} from "../../../recoil/select";
import GeneralModal from "../../GeneralModal/GeneralModal";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import "leaflet/dist/leaflet.css";
import { posisionMapState } from "../../../recoil/location";
import { PostWholeSale, PutWholeSale } from "../../../services/api/wholeSale";
import JsonViewer from "../../Others/JsonViewer";
import { display } from "@mui/system";

export default function ModalInfo() {
  const [open, setOpen] = useRecoilState(openModalInfo);
  const [showPassword, setShowPassword] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [sendData, setSendData] = useState(false);

  const dataItem = useRecoilValue(dataClickItem);
  const methodRequest = useRecoilValue(methodRequestStatus);

  const setUpdateData = useSetRecoilState(updateDataState);

  // close modal
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <GeneralModal
      maxWidth="md"
      title={dataItem?.description}
      open={open}
      style={{
        h2: {
          display: "flex",
          justifyContent: "space-between",
        },
      }}
    >
      <Box sx={{ mx: 2, my: 2 }}>
        <Typography sx={{ mx: 2 }} variant="h5" fontSize={18} fontWeight={500}>
          Notification Information
        </Typography>
        <JsonViewer data={dataItem} />
      </Box>
    </GeneralModal>
  );
}
