import React, { useEffect, useState } from "react";
import { Box, Grid, Typography } from "@mui/material";
import master from "../../../assets/master.jpg";
import zainCash from "../../../assets/zain.jpeg";
import asia from "../../../assets/FIB.png";
import fastPay from "../../../assets/fastpay.png";
import cash from "../../../assets/money.avif";
import { GetTransactionsStats } from "../../../services/api/transactions";
import { useRecoilValue } from "recoil";
import { selectedDateState } from "../../../recoil/global";
import { GetOrderStats } from "../../../services/api/order";
import moment from "moment";

export const TransactionTable = () => {
  const selectedDate = useRecoilValue(selectedDateState);
  const [order, setOrder] = useState([]);

  useEffect(() => {
    GetOrderStats(selectedDate)
      .then((res) => {
        setOrder(res);
        console.log("Trans", res);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [selectedDate]);
  return (
    order.length > 0 && (
      <Box
        width="100%"
        backgroundColor="#080d29"
        px={2}
        py={3}
        borderRadius={3}
        sx={{
          svg: {
            fill: "#ffc107",
          },
        }}
      >
        <Box px={1} mb={0}>
          <Typography color={"#fff"} fontSize={"1.5rem"} lineHeight={1.1}>
            Transactions
          </Typography>
        </Box>
        <Grid px={1} mt={1.5} container>
          <Grid container sm={12}>
            <Grid sm={3}>
              <Typography px={1} fontSize={"16px"} color={"#d3d7dc"}>
                Date
              </Typography>
            </Grid>
            <Grid sm={6}>
              <Typography px={1} fontSize={"16px"} color={"#d3d7dc"}>
                Name
              </Typography>
            </Grid>
            <Grid sm={3}>
              <Typography px={1} fontSize={"16px"} color={"#d3d7dc"}>
                Amount
              </Typography>
            </Grid>
          </Grid>
          <Grid
            sm={12}
            sx={{
              backgroundColor: "#161b36",
              borderTop: "1px solid #2d3a5e",
              py: 1,
            }}
            container
          >
            <Grid sm={3}>
              <Typography px={1} fontSize={"16px"} color={"#d3d7dc"}>
                12:00 Am
              </Typography>
              <Typography px={1} fontSize={"16px"} color={"#d3d7dc"}>
                {moment(selectedDate).format("DD MMM,YYYY")}
              </Typography>
            </Grid>
            <Grid
              sm={6}
              display={"flex"}
              justifyContent={"start"}
              alignItems={"center"}
            >
              <img src={master} alt="master" width="40" height="40" />
              <Typography px={1} fontSize={"16px"} color={"#d3d7dc"}>
                Master Rafidain
              </Typography>
            </Grid>
            <Grid
              sm={3}
              display={"flex"}
              justifyContent={"start"}
              alignItems={"center"}
            >
              <Typography px={1} fontSize={"18px"} color={"#fff"}>
                $
                {order.length > 20 &&
                  order[20].values.length > 2 &&
                  order[20].values[2].length > 1 &&
                  order[20].values[2][1]}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            sm={12}
            sx={{
              backgroundColor: "#080d29",
              borderTop: "1px solid #2d3a5e",
              py: 1,
            }}
            container
          >
            <Grid sm={3}>
              <Typography px={1} fontSize={"16px"} color={"#d3d7dc"}>
                {moment(selectedDate).format("DD MMM,YYYY")}
              </Typography>
              <Typography px={1} fontSize={"16px"} color={"#d3d7dc"}>
                12:00 AM
              </Typography>
            </Grid>
            <Grid
              sm={6}
              display={"flex"}
              justifyContent={"start"}
              alignItems={"center"}
            >
              <img src={zainCash} alt="master" width="40" height="40" />
              <Typography px={1} fontSize={"16px"} color={"#d3d7dc"}>
                Zain cash
              </Typography>
            </Grid>
            <Grid
              sm={3}
              display={"flex"}
              justifyContent={"start"}
              alignItems={"center"}
            >
              <Typography px={1} fontSize={"18px"} color={"#fff"}>
                $
                {order.length > 20 &&
                  order[20].values.length > 0 &&
                  order[20].values[0].length > 1 &&
                  order[20].values[0][1]}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            sm={12}
            sx={{
              backgroundColor: "#161b36",
              borderTop: "1px solid #2d3a5e",
              py: 1,
            }}
            container
          >
            <Grid sm={3}>
              <Typography px={1} fontSize={"16px"} color={"#d3d7dc"}>
                {moment(selectedDate).format("DD MMM,YYYY")}
              </Typography>
              <Typography px={1} fontSize={"16px"} color={"#d3d7dc"}>
                12:00 AM
              </Typography>
            </Grid>
            <Grid
              sm={6}
              display={"flex"}
              justifyContent={"start"}
              alignItems={"center"}
            >
              <img src={asia} alt="master" width="40" height="40" />
              <Typography px={1} fontSize={"16px"} color={"#d3d7dc"}>
                FIB
              </Typography>
            </Grid>
            <Grid
              sm={3}
              display={"flex"}
              justifyContent={"start"}
              alignItems={"center"}
            >
              <Typography px={1} fontSize={"18px"} color={"#fff"}>
                $
                {order.length > 20 &&
                  order[20].values.length > 4 &&
                  order[20].values[4].length > 1 &&
                  order[20].values[4][1]}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            sm={12}
            sx={{
              backgroundColor: "#080d29",
              borderTop: "1px solid #2d3a5e",
              py: 1,
            }}
            container
          >
            <Grid sm={3}>
              <Typography px={1} fontSize={"16px"} color={"#d3d7dc"}>
                {moment(selectedDate).format("DD MMM,YYYY")}
              </Typography>
              <Typography px={1} fontSize={"16px"} color={"#d3d7dc"}>
                12:00 AM
              </Typography>
            </Grid>
            <Grid
              sm={6}
              display={"flex"}
              justifyContent={"start"}
              alignItems={"center"}
            >
              <img src={fastPay} alt="master" width="40" height="40" />
              <Typography px={1} fontSize={"16px"} color={"#d3d7dc"}>
                Fastpay
              </Typography>
            </Grid>
            <Grid
              sm={3}
              display={"flex"}
              justifyContent={"start"}
              alignItems={"center"}
            >
              <Typography px={1} fontSize={"18px"} color={"#fff"}>
                $
                {order.length > 20 &&
                  order[20].values.length > 3 &&
                  order[20].values[3].length > 1 &&
                  order[20].values[3][1]}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            sm={12}
            sx={{
              backgroundColor: "#161b36",
              borderTop: "1px solid #2d3a5e",
              py: 1,
            }}
            container
          >
            <Grid sm={3}>
              <Typography px={1} fontSize={"16px"} color={"#d3d7dc"}>
                {moment(selectedDate).format("DD MMM,YYYY")}
              </Typography>
              <Typography px={1} fontSize={"16px"} color={"#d3d7dc"}>
                12:00 AM
              </Typography>
            </Grid>
            <Grid
              sm={6}
              display={"flex"}
              justifyContent={"start"}
              alignItems={"center"}
            >
              <img src={cash} alt="master" width="40" height="40" />
              <Typography px={1} fontSize={"16px"} color={"#d3d7dc"}>
                Cash on delivary
              </Typography>
            </Grid>
            <Grid
              sm={3}
              display={"flex"}
              justifyContent={"start"}
              alignItems={"center"}
            >
              <Typography px={1} fontSize={"18px"} color={"#fff"}>
                $
                {order.length > 20 &&
                  order[20].values.length > 1 &&
                  order[20].values[1].length > 1 &&
                  order[20].values[1][1]}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    )
  );
};
